import { cloneDeep } from 'lodash-es';
export class AppLoaderProgressHandler {
  context = '';
  total = 0;
  totalItems = [];
  processedItems = new Set();
  downloadedItems = new Set();
  failedItems = new Set();
  remainingItems = new Set();
  processed = 0;
  remaining = 0;
  progress = 0;
  currentItem;
  constructor() {}
  init(items) {
    this.total = items.length;
    this.totalItems = [...items];
    this.remaining = items.length;
    this.remainingItems = new Set(items);
    return this.getProgress();
  }
  update(item, success, context, httpStatus) {
    const newItem = cloneDeep(item);
    newItem.success = success;
    if (httpStatus) {
      newItem.status = httpStatus;
    }
    if (success) {
      this.downloadedItems.add(newItem);
    } else {
      this.failedItems.add(newItem);
    }
    this.currentItem = newItem;
    this.context = context;
    this.processed++;
    this.remaining--;
    this.progress = Math.round(this.processed * 100 / this.total);
    this.currentItem = newItem;
    this.processedItems.add(newItem);
    this.remainingItems.delete(item);
    return this.getProgress();
  }
  getProgress() {
    const progress = {
      total: this.total,
      processed: this.processed,
      remaining: this.remaining,
      downloadedItems: Array.from(this.downloadedItems.values()),
      failedItems: Array.from(this.failedItems.values()),
      progress: this.progress,
      context: this.context,
      currentItem: this.currentItem,
      totalItems: this.totalItems,
      processedItems: Array.from(this.processedItems.values()),
      remainingItems: Array.from(this.remainingItems.values())
    };
    return progress;
  }
}
