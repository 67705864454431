import { get, isEqual, find, size, startsWith, isEqualWith, differenceBy, every, some, cloneDeep, endsWith, isMatch } from 'lodash-es';
import { DcuplQueryBuilder } from './query-builder';
export class QueryManager {
  scriptController;
  registeredCustomOperator = new Map();
  queryBuilder = new DcuplQueryBuilder();
  constructor(scriptController) {
    this.scriptController = scriptController;
  }
  registerCustomOperator(operator, fn) {
    this.registeredCustomOperator.set(operator, fn);
  }
  transformValue(value, transformers) {
    if (Array.isArray(value)) {
      return value.map(val => this.transformValue(val, transformers));
    }
    if (typeof value !== 'string') {
      return value;
    }
    for (const transformer of transformers) {
      if (transformer === 'lowercase') {
        value = value.toLowerCase();
      } else if (transformer === 'trim') {
        value = value.trim();
      } else if (transformer === 'removeWhitespace') {
        value = value.replace(/\s/g, '');
      }
    }
    return value;
  }
  evaluateQuery(query, dataEntry) {
    let dataEntryValue;
    let queryValue = query.value;
    const arrayValueHandling = query.options?.arrayValueHandling || 'some';
    if (Array.isArray(query.options?.transform) && query.options.transform.length > 0) {
      queryValue = this.transformValue(queryValue, query.options.transform);
    }
    if (query.attribute.includes('.') || query.attribute.includes('[')) {
      dataEntryValue = get(dataEntry, query.attribute);
    } else {
      dataEntryValue = dataEntry[query.attribute];
    }
    if (Array.isArray(query.options?.transform) && query.options.transform.length > 0) {
      dataEntryValue = this.transformValue(dataEntryValue, query.options.transform);
    }
    if (typeof dataEntryValue === 'undefined' || dataEntryValue === null) {
      const allowedUndefinedAndNullOperators = ['typeof', 'isTruthy', 'eq'];
      if (!allowedUndefinedAndNullOperators.includes(query.operator)) {
        return false;
      }
    }
    switch (query.operator) {
      case 'eq':
        if (!query.options?.invert) {
          return isEqual(dataEntryValue, queryValue);
        } else {
          return !isEqual(dataEntryValue, queryValue);
        }
      case 'find':
        {
          if (!query.options?.invert) {
            return this.queryFind(dataEntryValue, queryValue, arrayValueHandling);
          } else {
            return !this.queryFind(dataEntryValue, queryValue, arrayValueHandling);
          }
        }
      case 'lt':
        if (Array.isArray(dataEntryValue)) {
          if (arrayValueHandling === 'some') {
            return dataEntryValue.some(value => value < queryValue);
          } else {
            return dataEntryValue.every(value => value < queryValue);
          }
        } else {
          return dataEntryValue < queryValue;
        }
      case 'gt':
        if (Array.isArray(dataEntryValue)) {
          if (arrayValueHandling === 'some') {
            return dataEntryValue.some(value => value > queryValue);
          } else {
            return dataEntryValue.every(value => value > queryValue);
          }
        } else {
          return dataEntryValue > queryValue;
        }
      case 'lte':
        if (Array.isArray(dataEntryValue)) {
          if (arrayValueHandling === 'some') {
            return dataEntryValue.some(value => value <= queryValue);
          } else {
            return dataEntryValue.every(value => value <= queryValue);
          }
        } else {
          return dataEntryValue <= queryValue;
        }
      case 'gte':
        if (Array.isArray(dataEntryValue)) {
          if (arrayValueHandling === 'some') {
            return dataEntryValue.some(value => value >= queryValue);
          } else {
            return dataEntryValue.every(value => value >= queryValue);
          }
        } else {
          return dataEntryValue >= queryValue;
        }
      case 'typeof':
        if (!query.options?.invert) {
          if (Array.isArray(dataEntryValue)) {
            if (arrayValueHandling === 'some') {
              return dataEntryValue.some(value => typeof value === queryValue);
            } else {
              return dataEntryValue.every(value => typeof value === queryValue);
            }
          } else {
            return typeof dataEntryValue === queryValue;
          }
        } else {
          return typeof dataEntryValue !== queryValue;
        }
      case 'isTruthy':
        if (queryValue === true) {
          if (Array.isArray(dataEntryValue)) {
            if (arrayValueHandling === 'some') {
              return dataEntryValue.some(value => typeof !!value);
            } else {
              return dataEntryValue.every(value => typeof !!value);
            }
          } else {
            return !!dataEntryValue;
          }
        } else {
          return !dataEntryValue;
        }
      case 'size':
        if (typeof dataEntryValue === 'string') {
          return dataEntryValue.length === queryValue;
        } else {
          return size(dataEntryValue) === queryValue;
        }
      default:
        if (this.scriptController.hasScript(query.operator)) {
          return this.scriptController.executeScript(query.operator, 'operator', {
            query,
            item: dataEntry
          });
        }
        /**
         * @deprecated
         * Use custom script instead
         */
        if (this.registeredCustomOperator.has(query.operator)) {
          const fn = this.registeredCustomOperator.get(query.operator);
          if (typeof fn === 'function') {
            return fn(query, dataEntry);
          }
        }
    }
    return false;
  }
  evaluateEqOperator(query, initialDataset, indexMap) {
    const filteredData = new Map();
    const indices = indexMap.get(query.attribute);
    if (indices) {
      let value;
      if (typeof query.value === 'string') {
        value = query.value;
      } else if (query.value?.key && Object.keys(query.value).length === 1) {
        value = query.value.key;
      }
      if (typeof value !== 'undefined') {
        const indexValues = indices.get(value);
        if (indexValues) {
          indexValues.forEach(key => {
            if (initialDataset.has(key)) {
              filteredData.set(key, initialDataset.get(key));
            }
          });
          if (query.options?.invert) {
            const diff = differenceBy(Array.from(initialDataset.values()), Array.from(filteredData.values()), 'key');
            const diffMap = new Map();
            diff.forEach(item => {
              diffMap.set(item.key, item);
            });
            return diffMap;
          } else {
            return filteredData;
          }
        }
      }
    }
    // fallback if indices do not work properly...
    for (const [key, value] of initialDataset) {
      if (this.evaluateQuery(query, value)) {
        filteredData.set(key, value);
      }
    }
    return filteredData;
  }
  equalRegexFn(value, queryValue) {
    if (typeof queryValue === 'string' && startsWith(queryValue, '/') && endsWith(queryValue, '/')) {
      try {
        const regex = new RegExp(queryValue.slice(1, -1));
        return regex.test(value);
      } catch (err) {
        return false;
      }
    } else if (Array.isArray(value)) {
      return !!value.find(val => isEqualWith(val, queryValue, this.equalRegexFn));
    } else if (typeof queryValue === 'object') {
      return isMatch(value, queryValue);
    } else {
      return value === queryValue;
    }
  }
  queryFind(entryValue, queryValue, arrayValueHandling) {
    if (Array.isArray(entryValue)) {
      if (typeof queryValue === 'string' || typeof queryValue === 'number' || typeof queryValue === 'boolean') {
        if (arrayValueHandling === 'some') {
          return entryValue.some(value => isEqualWith(value, queryValue, this.equalRegexFn));
        } else {
          return entryValue.every(value => isEqualWith(value, queryValue, this.equalRegexFn));
        }
      } else {
        return !!find(entryValue, queryValue);
      }
    } else {
      return isEqualWith(entryValue, queryValue, this.equalRegexFn);
    }
  }
  validateQuery(query) {
    if (this.queryBuilder.isQuery(query)) {
      if (!query.attribute || !query.operator) {
        throw new Error('invalid query - missing attribute or operator');
      }
      if (query.operator === 'find' && Array.isArray(query.value)) {
        throw new Error('invalid query - find operator cannot have array value');
      }
      return true;
    } else if (this.queryBuilder.isQueryGroup(query)) {
      return every(query.queries, q => this.validateQuery(q));
    } else {
      throw new Error('invalid query - missing query or attribute');
    }
  }
  getEvaluatedQueryDataset(query, initialDataset, indexMap) {
    const filteredData = new Map();
    if (query.attribute.includes('[*]')) {
      const attribute = query.attribute.replace('[*]', '');
      for (const [key, value] of initialDataset) {
        const arrayValue = value[attribute];
        if (Array.isArray(arrayValue)) {
          const results = arrayValue.map((arrayItem, index) => {
            arrayItem;
            const queryCopy = cloneDeep(query);
            queryCopy.attribute = queryCopy.attribute.replace('[*]', `[${index}]`);
            return this.evaluateQuery(queryCopy, value);
          });
          if (some(results)) {
            filteredData.set(key, value);
          }
        }
      }
    } else if (query.operator === 'eq') {
      return this.evaluateEqOperator(query, initialDataset, indexMap);
    } else {
      for (const [key, value] of initialDataset) {
        if (this.evaluateQuery(query, value)) {
          filteredData.set(key, value);
        }
      }
    }
    return filteredData;
  }
  evaluateQueryGroup(queryGroup, initialDataset, earlyReturn, indexMap) {
    let filteredData = new Map();
    let currentDataset = initialDataset;
    earlyReturn;
    if (!queryGroup.queries || queryGroup.queries?.length === 0) {
      return currentDataset;
    }
    for (const query of queryGroup.queries) {
      let result = new Map();
      if (this.queryBuilder.isQuery(query)) {
        result = this.getEvaluatedQueryDataset(query, currentDataset, indexMap);
      } else {
        result = this.evaluateQueryGroup(query, currentDataset, earlyReturn, indexMap);
      }
      if (filteredData.size === 0) {
        filteredData = result;
      }
      if (queryGroup.groupType === 'or') {
        result.forEach(item => {
          filteredData.set(item.key, item);
        });
      } else {
        const diff = differenceBy(Array.from(filteredData.values()), Array.from(result.values()), 'key');
        for (const item of diff) {
          filteredData.delete(item.key);
        }
      }
      if (queryGroup.groupType === 'and') {
        currentDataset = filteredData;
      } else {
        currentDataset = initialDataset;
      }
    }
    return filteredData;
  }
  queryData(initialData, rootQuery, earlyReturn, indexMap) {
    // const currentDataset = new Map(initialData);
    const currentDataset = initialData;
    if (rootQuery.queries.length === 0) {
      return initialData;
    }
    if (!rootQuery.groupKey) {
      rootQuery.groupKey = 'root';
    }
    if (!rootQuery.groupType) {
      rootQuery.groupType = 'and';
    }
    if (!rootQuery.queries) {
      rootQuery.queries = [];
    }
    this.validateQuery(rootQuery);
    return this.evaluateQueryGroup(rootQuery, currentDataset, earlyReturn, indexMap);
  }
}
