import { isValid, parse } from 'date-fns';
import { IndicesController, getPropertiesForData, generateKey, isInt, isFloat, evaluateTemplate } from '@dcupl/common';
import { isValidReference, isValidProperty } from './model.helper';
import { isBoolean, isObjectLike, isString, merge } from 'lodash-es';
export const EntryMetadataValues = {
  REF_VALUE: '_dcupl_ref_'
};
const defaultAttributeQualityConfig = {
  required: true,
  forceStrictDataType: false,
  nullable: false,
  validatorHandling: 'loose'
};
const defaultQualityConfig = {
  enabled: true,
  attributes: defaultAttributeQualityConfig
};
export class DcuplModel {
  modelParser;
  properties = new Map();
  references = new Map();
  attributeQualityConfigs = new Map();
  key;
  initialDefinition;
  data = new Map();
  supportsAutoCreation = false;
  autoGenerateKey = false;
  autoGenerateProperties = false;
  keyProperty = 'key';
  valueMappings = [];
  meta;
  qualityConfig = defaultQualityConfig;
  indicesController = new IndicesController();
  cdRef;
  constructor(modelParser) {
    this.modelParser = modelParser;
    this.cdRef = modelParser.cdRef;
  }
  init(modelDefinition) {
    this.key = modelDefinition.key;
    this.initialDefinition = modelDefinition;
    if (modelDefinition.quality) {
      this.qualityConfig = merge({}, defaultQualityConfig, modelDefinition.quality);
    }
  }
  applyPropertiesAndReferences(modelDefinition) {
    modelDefinition.properties?.map(prop => {
      const error = isValidProperty(prop);
      if (!error) {
        this.setAttributeQualityConfig(prop);
        this.properties.set(prop.key, prop);
      } else {
        error.model = this.key;
        this.setError(error);
      }
    });
    modelDefinition.references?.map(reference => {
      const error = isValidReference(reference);
      if (!error) {
        reference.validity = reference.validity || 'loose';
        this.setAttributeQualityConfig(reference);
        this.references.set(reference.key, reference);
      } else {
        error.model = this.key;
        this.setError(error);
      }
    });
    // set model defaults
    this.supportsAutoCreation = !!modelDefinition.supportsAutoCreation;
    this.keyProperty = modelDefinition.keyProperty || 'key';
    this.autoGenerateKey = !!modelDefinition.autoGenerateKey;
    this.autoGenerateProperties = !!modelDefinition.autoGenerateProperties;
    this.valueMappings = modelDefinition.valueMappings || [];
    this.meta = modelDefinition.meta;
  }
  setAttributeQualityConfig(attribute) {
    const typeQualityConfig = this.qualityConfig.attributes;
    const qualityConfig = merge({}, defaultQualityConfig, typeQualityConfig, attribute.quality);
    this.attributeQualityConfigs.set(attribute.key, qualityConfig);
  }
  getModelDefinition() {
    if (this.autoGenerateProperties) {
      const modelDef = {
        key: this.key,
        keyProperty: this.keyProperty,
        references: Array.from(this.references.values()),
        properties: Array.from(this.properties.values()),
        autoGenerateKey: this.autoGenerateKey,
        supportsAutoCreation: this.supportsAutoCreation,
        valueMappings: this.valueMappings,
        meta: this.meta,
        autoGenerateProperties: this.autoGenerateProperties,
        quality: this.qualityConfig
      };
      return modelDef;
    } else {
      return this.initialDefinition;
    }
  }
  generatePropertiesFromContainer(container) {
    const properties = getPropertiesForData(container.data, false);
    properties.map(property => {
      if (!this.properties.has(property.key)) {
        if (property.key === 'key' && (container.keyProperty === 'key' || !container.keyProperty)) {
          return;
        }
        property.filter = true;
        this.properties.set(property.key, property);
      }
    });
  }
  getReferenceKeys(referenceValue) {
    return referenceValue.map(value => {
      if (typeof value === 'string') {
        return String(value.trim());
      } else if (typeof value === 'number') {
        return String(value);
      } else if (value === null) {
        return 'null';
      } else if (typeof value === 'object' && !!value && value.key) {
        return value.key;
      }
      return undefined;
    });
  }
  setError(error) {
    if (this.modelParser.qualityController.enabled) {
      if (this.qualityConfig.enabled === true) {
        error.model = this.key;
        this.modelParser.qualityController.setError(error);
      }
    }
  }
  processBasicProperty(property, rawDataEntry, dataEntry) {
    const rawValue = rawDataEntry[property.key];
    const qualityConfig = this.attributeQualityConfigs.get(property.key);
    let value;
    if (typeof rawValue === 'undefined') {
      // todo - check if property can be undefined
      if (qualityConfig?.required) {
        this.setError({
          title: 'Undefined Value',
          type: 'model',
          errorGroup: 'PropertyDataError',
          errorType: 'UndefinedValue',
          description: `The property "${property.key}" in the "${this.key}" model is required but is undefined`,
          itemKey: rawDataEntry.key,
          attribute: property.key,
          model: this.key
        });
      }
      return undefined;
    } else {
      if (rawValue === null) {
        if (!qualityConfig?.nullable) {
          this.setError({
            title: 'Null Value',
            description: `The value of the property "${property.key}" in the "${this.key}" model should not be null`,
            type: 'model',
            errorGroup: 'PropertyDataError',
            errorType: 'NullValue',
            itemKey: rawDataEntry.key,
            attribute: property.key,
            model: this.key
          });
        }
        value = rawValue;
      } else if (property.type === 'string') {
        value = this.processStringProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'int') {
        value = this.processIntProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'float') {
        value = this.processFloatProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'date') {
        value = this.processDateProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'json') {
        value = this.processJSONProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'boolean') {
        value = this.processBooleanProperty(property, rawValue, rawDataEntry);
        if (typeof value === 'undefined') {
          return;
        }
      } else if (property.type === 'any') {
        value = rawValue;
      } else if (property.type === 'Array<string>') {
        if (Array.isArray(rawValue)) {
          value = [];
          rawValue.forEach(val => {
            const stringValue = this.processStringProperty(property, val, rawDataEntry);
            if (typeof stringValue !== 'undefined') {
              value.push(stringValue);
            }
          });
        }
      } else if (property.type === 'Array<int>') {
        if (Array.isArray(rawValue)) {
          value = [];
          rawValue.forEach(val => {
            const intValue = this.processIntProperty(property, val, rawDataEntry);
            if (typeof intValue !== 'undefined') {
              value.push(intValue);
            }
          });
        }
      } else if (property.type === 'Array<float>') {
        if (Array.isArray(rawValue)) {
          value = [];
          rawValue.forEach(val => {
            const floatValue = this.processFloatProperty(property, val, rawDataEntry);
            if (typeof floatValue !== 'undefined') {
              value.push(floatValue);
            }
          });
        }
      } else if (property.type === 'Array<date>') {
        if (Array.isArray(rawValue)) {
          value = [];
          rawValue.forEach(val => {
            const dateValue = this.processDateProperty(property, val, rawDataEntry);
            if (typeof dateValue !== 'undefined') {
              value.push(dateValue);
            }
          });
        }
      }
      dataEntry[property.key] = value;
    }
    if (property.index) {
      this.indicesController.fillInverseIndex(property.key, dataEntry.key, [value]);
      this.indicesController.fillDataMap(property.key, [value]);
    }
  }
  handleValidators(value, property, rawDataEntry) {
    let isValid = true;
    try {
      if (property.quality?.validators) {
        const validationErrors = this.modelParser.qualityController.validateAttribute(value, property.quality.validators, property.key);
        for (const validationError of validationErrors) {
          isValid = false;
          validationError.attribute = property.key;
          validationError.itemKey = rawDataEntry.key;
          validationError.model = this.key;
          this.setError(validationError);
        }
      }
    } catch (err) {
      console.log('Validator Definition Error', err);
    }
    return isValid;
  }
  getWrongDataTypeError(property, rawValue, rawDataEntry) {
    const error = {
      title: 'Wrong Data Type',
      type: 'model',
      errorGroup: 'PropertyDataError',
      errorType: 'WrongDataType',
      itemKey: rawDataEntry.key,
      attribute: property.key,
      model: this.key,
      description: `Expect ${property.type} but got ${typeof rawValue} with value ${rawValue}`,
      meta: {
        expected: property.type,
        rawValue: rawValue,
        typeof: typeof rawValue
      }
    };
    return error;
  }
  processStringProperty(property, rawValue, rawDataEntry) {
    const qualityConfig = this.attributeQualityConfigs.get(property.key);
    let relevantValue = rawValue;
    if (qualityConfig?.forceStrictDataType) {
      if (!isString(rawValue)) {
        this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
        return;
      }
    } else {
      relevantValue = String(rawValue);
      if (!isString(relevantValue) && relevantValue != rawValue) {
        this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
        return;
      }
    }
    if (!this.handleValidators(relevantValue, property, rawDataEntry)) {
      if (qualityConfig?.validatorHandling !== 'loose') {
        return;
      }
    }
    return relevantValue;
  }
  processIntProperty(property, rawValue, rawDataEntry) {
    const qualityConfig = this.attributeQualityConfigs.get(property.key);
    if (qualityConfig?.forceStrictDataType) {
      if (!isInt(rawValue)) {
        this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
        return;
      }
      return rawValue;
    }
    const value = parseInt(rawValue, 10);
    if (isNaN(value)) {
      this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
      return;
    }
    if (!this.handleValidators(value, property, rawDataEntry)) {
      if (qualityConfig?.validatorHandling !== 'loose') {
        return;
      }
    }
    return value;
  }
  processFloatProperty(property, rawValue, rawDataEntry) {
    const qualityConfig = this.attributeQualityConfigs.get(property.key);
    if (qualityConfig?.forceStrictDataType) {
      if (!isFloat(rawValue)) {
        this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
        return;
      }
      return rawValue;
    }
    let value;
    const fractionDigits = property.fractionDigits;
    if (typeof fractionDigits === 'undefined') {
      value = parseFloat(rawValue);
    } else {
      value = parseFloat(parseFloat(rawValue).toFixed(fractionDigits));
    }
    if (isNaN(value)) {
      this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
      return;
    }
    if (!this.handleValidators(value, property, rawDataEntry)) {
      if (qualityConfig?.validatorHandling !== 'loose') {
        return;
      }
    }
    return value;
  }
  processJSONProperty(property, rawValue, rawDataEntry) {
    const qualityConfig = this.attributeQualityConfigs.get(property.key);
    if (qualityConfig?.forceStrictDataType) {
      if (!isObjectLike(rawValue)) {
        this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
        return;
      }
      return rawValue;
    }
    if (isObjectLike(rawValue)) {
      return rawValue;
    }
    if (!isObjectLike(rawValue) && typeof rawValue === 'string') {
      try {
        return JSON.parse(rawValue);
      } catch (err) {
        this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
        return;
      }
    }
    return;
  }
  processBooleanProperty(property, rawValue, rawDataEntry) {
    const qualityConfig = this.attributeQualityConfigs.get(property.key);
    if (qualityConfig?.forceStrictDataType) {
      if (!isBoolean(rawValue)) {
        this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
        return;
      }
      return rawValue;
    }
    return !!rawValue;
  }
  processDateProperty(property, rawValue, rawDataEntry) {
    // forceStrictDataType is not supported / makes no sense for date
    if (rawValue === '') {
      this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
      return;
    }
    const inputFormat = property.inputFormat;
    let date;
    try {
      if (inputFormat && typeof inputFormat === 'string') {
        let relevantValue = rawValue;
        if (typeof relevantValue !== 'string') {
          relevantValue = String(relevantValue);
        }
        date = parse(relevantValue, inputFormat, new Date());
      } else {
        date = new Date(rawValue);
      }
    } catch (err) {
      console.log(err);
      console.log(rawValue, inputFormat);
      this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
      return;
    }
    if (!isValid(date)) {
      this.setError(this.getWrongDataTypeError(property, rawValue, rawDataEntry));
      return;
    }
    if (property.UTC) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
    }
    return date;
  }
  processSingleValuedReference(reference, referenceValue, dataEntry) {
    const remoteModel = this.modelParser.models.get(reference.model);
    if (!remoteModel) {
      return;
    }
    const remoteDataEntry = remoteModel.getEntry(referenceValue);
    // fill undefined indexMap for for single and multi valued
    if (!remoteDataEntry) {
      if (remoteModel.supportsAutoCreation) {
        const remoteValue = this.handleAutoCreationAndReturnKey(remoteModel, referenceValue);
        this.indicesController.fillInverseIndex(reference.key, dataEntry.key, [referenceValue]);
        dataEntry[reference.key] = remoteValue;
        this.setRefValue(remoteValue, 'auto_hit');
        return;
      } else {
        this.indicesController.fillInverseIndex(reference.key, dataEntry.key, [referenceValue]);
        this.setError({
          title: 'Remote Reference Key Not Found',
          type: 'model',
          errorGroup: 'ReferenceDataError',
          errorType: 'RemoteReferenceKeyNotFound',
          itemKey: dataEntry.key,
          attribute: reference.key,
          description: `The reference "${reference.key}" in the "${this.key}" model can not find the value "${referenceValue}" in the remote model "${reference.model}"`,
          meta: {
            remoteModel: reference.model,
            remoteKey: referenceValue
          }
        });
        if (reference.validity === 'strict') {
          return;
        }
      }
    }
    this.indicesController.fillInverseIndex(reference.key, dataEntry.key, [referenceValue]);
    const item = {
      key: String(referenceValue)
    };
    dataEntry[reference.key] = item;
    if (!remoteDataEntry && !remoteModel.supportsAutoCreation && reference.validity !== 'strict') {
      this.setRefValue(item, 'miss');
    } else {
      this.setRefValue(item, 'hit');
    }
  }
  processMultiValuedReference(reference, referenceValue, dataEntry) {
    const remoteModel = this.modelParser.models.get(reference.model);
    if (!remoteModel) {
      return;
    }
    const referenceKeys = this.getReferenceKeys(referenceValue);
    const validReferenceKeys = [];
    referenceKeys.forEach((referenceKey, index) => {
      const remoteDataEntry = remoteModel.getEntry(referenceKey);
      if (!remoteDataEntry) {
        if (remoteModel.supportsAutoCreation) {
          const remoteKey = this.handleAutoCreationAndReturnKey(remoteModel, referenceValue[index]);
          this.setRefValue(remoteKey, 'auto_hit');
          validReferenceKeys.push(remoteKey);
          return;
        } else {
          this.setError({
            title: 'Remote Reference Key Not Found',
            type: 'model',
            errorGroup: 'ReferenceDataError',
            errorType: 'RemoteReferenceKeyNotFound',
            itemKey: dataEntry.key,
            attribute: reference.key,
            description: `The reference "${reference.key}" in the "${this.key}" model can not find the value "${referenceKey}" in the remote model "${reference.model}"`,
            meta: {
              remoteModel: reference.model,
              remoteKey: referenceKey
            }
          });
          if (reference.validity === 'strict') {
            return;
          }
        }
      }
      const item = {
        key: referenceKey
      };
      if (!remoteDataEntry && !remoteModel.supportsAutoCreation && reference.validity !== 'strict') {
        this.setRefValue(item, 'miss');
      } else {
        this.setRefValue(item, 'hit');
      }
      validReferenceKeys.push(item);
    });
    this.indicesController.fillInverseIndex(reference.key, dataEntry.key, validReferenceKeys.map(val => val.key));
    dataEntry[reference.key] = validReferenceKeys;
  }
  getViewKeys() {
    return this.modelParser['_getViewKeys']({
      modelKey: this.key
    });
  }
  addBasicPropertiesAndReferences(container) {
    const properties = this.getBasicProperties();
    const references = this.getBasicReferences();
    for (let rawDataEntry of container.data) {
      let dataEntry = this.data.get(String(rawDataEntry.key));
      if (!dataEntry) {
        dataEntry = {
          key: String(rawDataEntry.key)
        };
      } else {
        if (container.type === 'set') {
          this.setError({
            title: 'Non Unique Key',
            type: 'model',
            errorGroup: 'DataContainerError',
            errorType: 'NonUniqueKey',
            itemKey: rawDataEntry.key,
            model: container.model
          });
        }
      }
      // handle Properties
      for (const property of properties) {
        if (property.origin) {
          rawDataEntry[property.key] = rawDataEntry[property.origin];
        }
        const valueMapping = this.valueMappings.find(map => map.attributes.includes(property.key));
        if (this.isValidValueMapping(valueMapping)) {
          rawDataEntry = this.mapRawValueUsingValueMapping(rawDataEntry, property.key, valueMapping);
        }
        this.processBasicProperty(property, rawDataEntry, dataEntry);
      }
      // handle References
      for (const reference of references) {
        const qualityConfig = this.attributeQualityConfigs.get(reference.key);
        if (reference.origin) {
          rawDataEntry[reference.key] = rawDataEntry[reference.origin];
        }
        const valueMapping = this.valueMappings.find(map => map.attributes.includes(reference.key));
        if (this.isValidValueMapping(valueMapping)) {
          rawDataEntry = this.mapRawValueUsingValueMapping(rawDataEntry, reference.key, valueMapping);
        }
        let referenceValue = rawDataEntry[reference.key];
        if (typeof referenceValue === 'number') {
          referenceValue = String(referenceValue);
        }
        if (!referenceValue) {
          this.indicesController.fillInverseIndex(reference.key, dataEntry.key, [undefined]);
          if (qualityConfig?.required) {
            this.setError({
              title: 'Undefined Value',
              type: 'model',
              errorGroup: 'ReferenceDataError',
              errorType: 'UndefinedValue',
              itemKey: rawDataEntry.key,
              attribute: reference.key,
              model: container.model
            });
          }
          continue;
        }
        if (reference.type === 'singleValued') {
          if ((typeof referenceValue === 'object' || typeof referenceValue === 'string') && !Array.isArray(referenceValue)) {
            this.processSingleValuedReference(reference, referenceValue, dataEntry);
          } else {
            this.setError({
              title: 'Wrong Data Type',
              type: 'model',
              errorGroup: 'ReferenceDataError',
              errorType: 'WrongDataType',
              description: `Expect ${reference.type} but got ${typeof referenceValue} with value ${referenceValue}`,
              itemKey: rawDataEntry.key,
              attribute: reference.key,
              meta: {
                value: referenceValue
              }
            });
          }
        } else if (reference.type === 'multiValued') {
          if (Array.isArray(referenceValue)) {
            this.processMultiValuedReference(reference, referenceValue, dataEntry);
          } else {
            this.setError({
              title: 'Wrong Data Type',
              type: 'model',
              errorGroup: 'ReferenceDataError',
              errorType: 'WrongDataType',
              description: `Expect ${reference.type} but got ${typeof referenceValue} with value ${referenceValue}`,
              itemKey: rawDataEntry.key,
              attribute: reference.key,
              meta: {
                value: referenceValue
              }
            });
          }
        }
      }
      this.data.set(dataEntry.key, dataEntry);
    }
  }
  isValidValueMapping(valueMapping) {
    if (!valueMapping || !Array.isArray(valueMapping.attributes) || !Array.isArray(valueMapping.values)) {
      return false;
    }
    const invalidFromValues = valueMapping.values.find(value => !Array.isArray(value.from));
    const invalidToValues = valueMapping.values.find(value => !value.to);
    if (invalidFromValues || invalidToValues) {
      return false;
    }
    return true;
  }
  mapRawValueUsingValueMapping(rawEntry, attribute, valueMappingConfig) {
    for (const mappingValue of valueMappingConfig.values) {
      if (mappingValue.from.includes('$dcupl_falsy')) {
        if (typeof rawEntry[attribute] === 'undefined' || rawEntry[attribute] === null || rawEntry[attribute] === '') {
          rawEntry[attribute] = mappingValue.to;
          continue;
        }
        continue;
      }
      if (mappingValue.from.includes(rawEntry[attribute])) {
        rawEntry[attribute] = mappingValue.to;
        continue;
      }
    }
    return rawEntry;
  }
  handleAutoCreationAndReturnKey(remoteModel, referenceValue) {
    if (Array.isArray(referenceValue)) {
      return [];
    }
    if (referenceValue === null) {
      return {
        key: 'null'
      };
    }
    if (typeof referenceValue === 'object') {
      const keyProperty = remoteModel.keyProperty;
      const key = remoteModel.autoGenerateKey ? generateKey() : String(referenceValue[keyProperty]);
      remoteModel.data.set(key, {
        key: key
      });
      referenceValue.key = key;
      const container = {
        model: remoteModel.key,
        data: [referenceValue]
      };
      setTimeout(() => {
        remoteModel.addContainer(container);
      }, 0);
      return {
        key: key
      };
    }
    if (typeof referenceValue === 'string') {
      remoteModel.data.set(referenceValue, {
        key: referenceValue
      });
      const container = {
        model: remoteModel.key,
        data: [{
          key: referenceValue
        }]
      };
      setTimeout(() => {
        remoteModel.addContainer(container);
      }, 0);
    }
    return {
      key: String(referenceValue)
    };
  }
  addContainer(container) {
    this.cdRef.analyticsController.mark({
      name: 'model:autogenerate_data:start',
      context: container.model
    });
    this.modelParser.handleAlternativeKey([container]);
    if (!Array.isArray(container.data)) {
      this.setError({
        title: 'Invalid Data',
        type: 'model',
        errorGroup: 'DataContainerError',
        errorType: 'WrongDataType',
        description: `Expect the data to be an Array but got ${typeof container.data}`,
        model: container.model
      });
      return;
    }
    if (this.autoGenerateProperties) {
      this.generatePropertiesFromContainer(container);
    }
    this.addBasicPropertiesAndReferences(container);
    this.addResolvedReferences(container);
    this.addDerivedReferencesGroupedReferencesDerivedPropertiesAndExpressionProperties(container);
    if (this.hasDerivedPropertiesBasedOnResolvedReferences() || this?.hasDerivedReferencesBasedOnResolvedReferences()) {
      this.reRunDerivedPropertiesBasedOnResolves(container);
    }
    this.cdRef.analyticsController.mark({
      name: 'model:autogenerate_data:end',
      context: container.model
    });
    this.cdRef.analyticsController.measure({
      name: 'model:autogenerate_data',
      start: 'model:autogenerate_data:start',
      end: 'model:autogenerate_data:end',
      sum: true,
      context: container.model,
      detail: {
        model: container.model,
        origin: this.key
      }
    });
  }
  addResolvedReferences(container) {
    const references = this.getResolvedReferences();
    const resolvedReferencesHelper = references.map(reference => {
      const remoteModel = this.modelParser.models.get(reference.resolve.model);
      if (!remoteModel) {
        return;
      }
      const remoteData = remoteModel.getDataAsArray();
      const inverseKeys = remoteModel.indicesController.getIndex(reference.resolve.reference);
      const obj = {
        reference,
        model: remoteModel,
        remoteData,
        inverseKeys
      };
      return obj;
    }).filter(entry => !!entry);
    for (const rawDataEntry of container.data) {
      const localDataEntryKey = String(rawDataEntry.key);
      const dataEntry = this.data.get(localDataEntryKey);
      for (const object of resolvedReferencesHelper) {
        const localReferenceKey = object.reference.key;
        let value;
        if (object.inverseKeys) {
          if (object.reference.type === 'multiValued') {
            const v = object.inverseKeys.get(dataEntry.key) || [];
            value = v.map(key => {
              return {
                key: key
              };
            });
          } else {
            const v = object.inverseKeys.get(dataEntry.key)?.at(0) || null;
            if (v) {
              value = {
                key: v
              };
            } else {
              value = null;
            }
          }
          dataEntry[localReferenceKey] = value;
          this.indicesController.fillInverseIndex(localReferenceKey, localDataEntryKey, dataEntry[localReferenceKey]);
        } else {}
      }
      this.data.set(dataEntry.key, dataEntry);
    }
  }
  addDerivedReferencesGroupedReferencesDerivedPropertiesAndExpressionProperties(container) {
    const derivedProperties = this.getDerivedPropertiesForBasicAndDerivedReferences();
    const expressionProperties = this.getExpressionProperties();
    const derivedReferences = this.getDerivedReferences();
    const groupedReferences = this.getGroupedReferences();
    for (const rawDataEntry of container.data) {
      const dataEntry = this.data.get(String(rawDataEntry.key));
      /**
       * 1) Derived References
       */
      derivedReferences.forEach(reference => {
        this.handleDerivedReferences(reference, dataEntry);
      });
      /**
       * 2) Grouped References
       */
      groupedReferences.forEach(reference => {
        this.handleGroupedReferences(reference, dataEntry);
      });
      /**
       * 3) Derived Properties
       */
      derivedProperties.forEach(prop => {
        this.handleDerivedProperties(prop, dataEntry);
      });
      /**
       * 4) Expression Properties
       */
      expressionProperties.forEach(prop => {
        const value = evaluateTemplate(prop.expression, {
          ...dataEntry
        });
        dataEntry[prop.key] = value;
      });
      this.data.set(dataEntry.key, dataEntry);
    }
  }
  handleDerivedProperties(prop, dataEntry) {
    const reference = this.references.get(prop.derive.localReference);
    if (reference) {
      const remoteModel = this.modelParser.models.get(reference.model);
      if (remoteModel) {
        if (reference.type === 'singleValued') {
          this.handleSingleValuedDerivedProperties(remoteModel, dataEntry, reference, prop);
        } else if (reference.type === 'multiValued') {
          this.handleMultiValuedDerivedProperties(remoteModel, dataEntry, reference, prop);
        }
      }
    }
  }
  reRunDerivedPropertiesBasedOnResolves(container) {
    const derivedProperties = this.getDerivedProperties();
    const derivedReferences = this.getDerivedReferences();
    for (const rawDataEntry of container.data) {
      const dataEntry = this.data.get(String(rawDataEntry.key));
      derivedReferences.forEach(reference => {
        this.handleDerivedReferences(reference, dataEntry);
      });
      derivedProperties.forEach(prop => {
        const reference = this.references.get(prop.derive.localReference);
        if (reference) {
          const remoteModel = this.modelParser.models.get(reference.model);
          if (remoteModel) {
            if (reference.type === 'singleValued') {
              this.handleSingleValuedDerivedProperties(remoteModel, dataEntry, reference, prop);
            } else if (reference.type === 'multiValued') {
              this.handleMultiValuedDerivedProperties(remoteModel, dataEntry, reference, prop);
            }
          }
        }
      });
      this.data.set(dataEntry.key, dataEntry);
    }
  }
  handleSingleValuedDerivedProperties(remoteModel, dataEntry, reference, property) {
    const localReferenceValue = dataEntry[reference.key];
    if (!localReferenceValue) {
      return;
    }
    const remoteEntry = remoteModel?.getData().get(localReferenceValue.key);
    if (remoteEntry) {
      const remoteValue = remoteEntry[property.derive.remoteProperty];
      if (typeof remoteValue !== 'undefined') {
        dataEntry[property.key] = remoteValue;
      }
      if (property.index) {
        this.indicesController.fillInverseIndex(property.key, dataEntry.key, [remoteValue]);
      }
    }
  }
  handleMultiValuedDerivedProperties(remoteModel, dataEntry, reference, prop) {
    const entryValue = dataEntry[reference.key];
    let entries;
    if (!entryValue) {
      entries = [];
    } else if (Array.isArray(entryValue)) {
      entries = [...entryValue];
    } else if (entryValue && typeof entryValue.key === 'string') {
      entries = [{
        key: entryValue.key
      }];
    } else {
      entries = [];
    }
    const separator = prop.derive?.separator || ', ';
    const remoteValues = entries.map(item => {
      const remoteEntry = remoteModel?.getData().get(item.key);
      if (remoteEntry) {
        return remoteEntry[prop.derive.remoteProperty];
      }
    }).filter(Boolean);
    let value;
    if (prop.type.includes('Array<')) {
      value = remoteValues;
    } else if (prop.type === 'string' || prop.type === 'int' || prop.type === 'float' || prop.type === 'date') {
      value = remoteValues.join(separator);
    } else if (prop.type === 'json') {
      value = remoteValues;
    } else {
      value = remoteValues;
    }
    dataEntry[prop.key] = value;
  }
  handleDerivedReferences(reference, dataEntry) {
    const localReferenceToDeriveFrom = this.references.get(reference.derive.localReference);
    if (!localReferenceToDeriveFrom) return;
    const remoteModelToDeriveFrom = this.modelParser.models.get(localReferenceToDeriveFrom.model);
    if (!remoteModelToDeriveFrom) return;
    const localKeyToDeriveFrom = dataEntry[localReferenceToDeriveFrom.key];
    if (!localKeyToDeriveFrom) return;
    let value = [];
    if (Array.isArray(localKeyToDeriveFrom)) {
      const resultSet = new Set();
      localKeyToDeriveFrom.forEach(item => {
        const dataEntryToDeriveFrom = remoteModelToDeriveFrom?.data.get(item.key);
        if (dataEntryToDeriveFrom) {
          const valueToDerive = dataEntryToDeriveFrom[reference.derive.remoteReference];
          if (valueToDerive) {
            if (Array.isArray(valueToDerive)) {
              valueToDerive.forEach(entry => {
                resultSet.add(entry.key);
              });
            } else {
              resultSet.add(valueToDerive.key);
            }
            value = Array.from(resultSet.values()).flat();
          }
        }
      });
    } else {
      const dataEntryToDeriveFrom = remoteModelToDeriveFrom?.data.get(localKeyToDeriveFrom.key);
      if (dataEntryToDeriveFrom) {
        const remoteValue = dataEntryToDeriveFrom[reference.derive.remoteReference];
        if (!remoteValue) {
          value = [];
        } else if (Array.isArray(remoteValue)) {
          value = remoteValue.map(val => val.key);
        } else if ('key' in remoteValue) {
          value = [remoteValue.key];
        }
      }
    }
    let valueToAssign;
    if (reference.type === 'singleValued') {
      valueToAssign = {
        key: value[0]
      };
    } else {
      valueToAssign = value.map(v => {
        return {
          key: v
        };
      });
    }
    this.indicesController.fillInverseIndex(reference.key, dataEntry.key, value);
    dataEntry[reference.key] = valueToAssign;
  }
  asStringArray(input) {
    if (!input) {
      return [];
    }
    if (Array.isArray(input)) {
      return input.map(i => i.key);
    } else {
      return [input.key];
    }
  }
  handleGroupedReferences(reference, dataEntry) {
    const valueToAssign = [];
    const referenceToGroupOn = this.references.get(reference.groupBy.reference);
    if (referenceToGroupOn) {
      const valueToGroupOn = this.asStringArray(dataEntry[referenceToGroupOn.key]);
      if (!valueToGroupOn.length) {
        return;
      }
      this.data.forEach(entry => {
        const entryValue = entry[referenceToGroupOn.key];
        if (!entryValue) {
          return;
        }
        const relevantValues = this.asStringArray(entryValue);
        if (relevantValues.find(val => valueToGroupOn.includes(val))) {
          if (reference.groupBy.includeSelfInGroup) {
            valueToAssign.push({
              key: entry.key
            });
          } else {
            if (dataEntry.key !== entry.key) {
              valueToAssign.push({
                key: entry.key
              });
            }
          }
        }
      });
    }
    dataEntry[reference.key] = valueToAssign;
  }
  resetData() {
    this.data.clear();
  }
  resetIndexMap() {
    this.indicesController.reset();
  }
  deleteDataEntries(data) {
    for (const rawDataEntry of data.data) {
      this.data.delete(String(rawDataEntry.key));
    }
  }
  getAttributes() {
    return [...this.references.values(), ...this.properties.values()];
  }
  getAttribute(key) {
    if (key === 'key') {
      const keyProperty = {
        key: 'key',
        type: 'string'
      };
      return keyProperty;
    }
    return this.references.get(key) || this.properties.get(key);
  }
  getBasicReferences() {
    return Array.from(this.references.values()).filter(reference => !('derive' in reference) && !('resolve' in reference));
  }
  getResolvedReferences() {
    return Array.from(this.references.values()).filter(reference => 'resolve' in reference);
  }
  getGroupedReferences() {
    return Array.from(this.references.values()).filter(reference => 'groupBy' in reference);
  }
  getDerivedReferences() {
    return Array.from(this.references.values()).filter(reference => 'derive' in reference);
  }
  getAggregatedReferences() {
    return Array.from(this.references.values()).filter(reference => 'aggregate' in reference);
  }
  getBasicProperties() {
    return Array.from(this.properties.values()).filter(property => !('derive' in property) && !('expression' in property));
  }
  getDerivedProperties() {
    return Array.from(this.properties.values()).filter(property => 'derive' in property);
  }
  getDerivedPropertiesForBasicAndDerivedReferences() {
    const basicReferences = this.getBasicReferences();
    const derivedReferences = this.getDerivedReferences();
    const derivedProperties = this.getDerivedProperties();
    const references = [...basicReferences, ...derivedReferences];
    const derivedPropertiesBasedOnBasicReferences = derivedProperties.filter(property => references.find(reference => reference.key === property.derive?.localReference));
    return derivedPropertiesBasedOnBasicReferences;
  }
  getDerivedPropertiesForResolvedReferences() {
    const resolvedReferences = this.getResolvedReferences();
    const derivedProperties = this.getDerivedProperties();
    const derivedPropertiesBasedOnResolvedReferences = derivedProperties.filter(property => resolvedReferences.find(reference => reference.key === property.derive?.localReference));
    return derivedPropertiesBasedOnResolvedReferences;
  }
  getDerivedReferencesForResolvedReferences() {
    const resolvedReferences = this.getResolvedReferences();
    const derivedReferences = this.getDerivedReferences();
    const derivedPropertiesBasedOnResolvedReferences = derivedReferences.filter(ref => resolvedReferences.find(reference => reference.key === ref.derive.localReference));
    return derivedPropertiesBasedOnResolvedReferences;
  }
  getExpressionProperties() {
    return Array.from(this.properties.values()).filter(property => 'expression' in property);
  }
  getAggregatedProperties() {
    return Array.from(this.properties.values()).filter(property => 'aggregate' in property);
  }
  hasBasicReferences() {
    return this.getBasicReferences().length > 0;
  }
  hasResolvedReferences() {
    return this.getResolvedReferences().length > 0;
  }
  hasGroupedReferences() {
    return this.getGroupedReferences().length > 0;
  }
  hasDerivedReferences() {
    return this.getDerivedReferences().length > 0;
  }
  hasBasicProperties() {
    return this.getBasicProperties().length > 0;
  }
  hasDerivedProperties() {
    return this.getDerivedProperties().length > 0;
  }
  hasDerivedPropertiesForBasicReferences() {
    return this.getDerivedPropertiesForBasicAndDerivedReferences().length > 0;
  }
  hasDerivedPropertiesBasedOnResolvedReferences() {
    return this.getDerivedPropertiesForResolvedReferences().length > 0;
  }
  hasDerivedReferencesBasedOnResolvedReferences() {
    return this.getDerivedReferencesForResolvedReferences().length > 0;
  }
  hasExpressionProperties() {
    return this.getExpressionProperties().length > 0;
  }
  hasAggregatedProperties() {
    return this.getAggregatedProperties().length > 0;
  }
  getData(options) {
    if (options?.itemKeys) {
      const filteredData = new Map();
      options.itemKeys.forEach(key => {
        const entry = this.data.get(key);
        if (entry) {
          filteredData.set(entry.key, entry);
        }
      });
      return filteredData;
    }
    return this.data;
  }
  getDataAsArray() {
    return Array.from(this.data.values());
  }
  getEntry(key) {
    return this.data.get(key);
  }
  setRefValue(item, value) {
    if (this.modelParser.initOptions.referenceMetadata?.enabled) {
      Object.defineProperty(item, EntryMetadataValues.REF_VALUE, {
        value: value,
        enumerable: true,
        writable: false,
        configurable: false
      });
    }
  }
}
