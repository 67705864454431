import { DcuplList } from '../list';
export class ListsController {
  dcuplInstance;
  lists = [];
  constructor(dcuplInstance) {
    this.dcuplInstance = dcuplInstance;
  }
  get(listKey) {
    if (listKey) {
      return this.lists.find(list => list.key === listKey);
    }
    return undefined;
  }
  getAll(options) {
    if (!options) return this.lists;
    return this.lists.filter(list => list.modelKey === options?.modelKey);
  }
  destroy(options) {
    if (!options) {
      this.lists.forEach(list => list.destroy());
      this.lists = [];
    } else if (options.listKey) {
      const idx = this.lists.findIndex(list => list.key === options.listKey);
      if (idx > -1) {
        this.lists[idx].destroy();
        this.lists.splice(idx, 1);
      }
    } else if (options.modelKey) {
      this.lists.forEach((list, idx) => {
        if (list.modelKey === options.modelKey) {
          list.destroy();
          this.lists.splice(idx, 1);
        }
      });
    }
  }
  create(options) {
    const list = new DcuplList(this.dcuplInstance);
    list['init'](options);
    this.lists.push(list);
    return list;
  }
}
