import { ChangeDetector, IndicesController, generateKey } from '@dcupl/common';
import { Catalog } from '../catalog';
import { cloneDeep } from 'lodash-es';
export class DcuplList {
  dcuplApi;
  options;
  key;
  modelKey;
  catalog;
  cdRef;
  constructor(dcuplApi) {
    this.dcuplApi = dcuplApi;
  }
  setup(options) {
    /**
     * 1) Get the corresponding model
     */
    const model = this.dcuplApi['modelParser'].models.get(options.modelKey);
    if (!model) {
      throw new Error(`Model with key ${options.modelKey} not found`);
    }
    /**
     * 2) Set data based on listItemKeys or query
     */
    const listItemKeys = this.getListItemKeys(options);
    const data = this.getRelevantData(model, listItemKeys);
    /**
     * 3) Create indicesController depdending on listItemKys
     */
    const indicesController = this.initIndicesController(listItemKeys, model, data);
    /**
     * 4) Create catalog
     */
    if (!this.catalog) {
      this.catalog = new Catalog(this.dcuplApi, this.cdRef, indicesController);
      this.catalog['init'](this.modelKey, model, data);
    } else {
      this.catalog['filter'].update(model, data, indicesController);
    }
  }
  initIndicesController(listItemKeys, model, data) {
    let indicesController;
    if (listItemKeys) {
      indicesController = new IndicesController();
      for (const key of model.indicesController['indexMap'].keys()) {
        indicesController.getOrCreateIndex(key, data);
      }
    } else {
      indicesController = model.indicesController;
    }
    return indicesController;
  }
  getListItemKeys(options) {
    let listItemKeys;
    if (options.listItemKeys) {
      listItemKeys = options.listItemKeys;
    } else if (options.query) {
      const items = this.dcuplApi.query.execute(options.query);
      listItemKeys = items.map(item => item.key);
    }
    return listItemKeys;
  }
  getRelevantData(model, listItemKeys) {
    const modelData = model.getData();
    if (listItemKeys) {
      return this.initFromItemKeys(modelData, listItemKeys);
    } else {
      return modelData;
    }
  }
  initCdRef(options) {
    const rootCdRef = this.dcuplApi['cdRef'];
    this.cdRef = new ChangeDetector();
    this.cdRef._init({
      cdRefKey: options.listKey,
      parentCd: rootCdRef,
      dcuplInitOptions: this.dcuplApi.initOptions,
      analyticsController: this.dcuplApi['analyticsController']
    });
  }
  init(_initialOptions) {
    /**
     * Initialize default options
     */
    _initialOptions.listKey = _initialOptions.listKey || generateKey();
    this.options = cloneDeep(_initialOptions);
    this.key = this.options.listKey;
    this.modelKey = this.options.modelKey;
    this.initCdRef(this.options);
    this.setup(this.options);
  }
  initFromItemKeys(modelData, itemKeys) {
    const data = new Map();
    itemKeys.forEach(key => {
      if (modelData.has(key)) {
        const item = modelData.get(key);
        if (item) {
          data.set(key, item);
        }
      }
    });
    return data;
  }
  destroy() {
    try {
      this.catalog['filter'].cdRef?.destroy();
      this.dcuplApi.lists.destroy({
        listKey: this.key
      });
    } catch (err) {}
  }
  update(options) {
    if (options && options.reprocessData || !options) {
      this.setup(this.options);
    }
    this.cdRef.trigger({
      action: 'update',
      scope: 'list',
      modelKey: this.modelKey
    });
  }
  on(cb) {
    return this.cdRef.on(cb);
  }
}
