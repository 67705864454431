import { cloneDeep } from 'lodash-es';
import { perf } from './performance';
import { generateKey } from './helper';
export class ChangeDetector {
  key;
  parentCd;
  dcuplInitOptions;
  analyticsController;
  off;
  updateListener = new Set();
  constructor() {}
  _init(options) {
    this.key = options.cdRefKey;
    this.analyticsController = options.analyticsController;
    this.parentCd = options.parentCd;
    this.dcuplInitOptions = options.dcuplInitOptions;
    if (this.parentCd) {
      this.off = this.parentCd.on(msg => {
        if (msg.action === 'update' && msg.originCdRefKey === this.parentCd?.key) {
          this.trigger(msg);
        }
      });
    }
  }
  destroy() {
    if (this.off) {
      this.off();
    }
  }
  trigger(msg) {
    if (!msg.originCdRefKey) {
      msg.originCdRefKey = this.key;
    }
    this.updateListener.forEach(cb => {
      cb(msg);
    });
  }
  on(cb) {
    this.updateListener.add(cb);
    return () => {
      this.updateListener.delete(cb);
    };
  }
}
export const clone = (obj, shouldClone) => {
  if (!shouldClone) {
    return obj;
  }
  if (typeof structuredClone !== 'undefined') {
    return structuredClone(obj);
  } else {
    return cloneDeep(obj);
  }
};
const finish = (cdRef, msg, start, context, result) => {
  try {
    const shouldClone = !!cdRef.dcuplInitOptions.performance?.clone?.enabled && !!msg.cloneResult;
    const valueToReturn = clone(result, shouldClone);
    const end = perf.now();
    msg.duration = end - start;
    cdRef?.trigger(msg);
    const value = {
      duration: msg.duration
    };
    if (msg.modelKey) {
      value.model = msg.modelKey;
    }
    if (msg.args) {
      value.args = msg.args;
    }
    cdRef.analyticsController.mark({
      name: `${msg.name}:end`,
      context: context
    });
    cdRef.analyticsController.measure({
      name: `${msg.name}`,
      start: `${msg.name}:start`,
      end: `${msg.name}:end`,
      context: context,
      detail: value
    });
    return valueToReturn;
  } catch (err) {
    return result;
  }
};
export const trigger = msg => {
  const log = (_target, _propertyKey, descriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = function (...args) {
      const cdRef = this.cdRef;
      const start = perf.now();
      let context = '';
      if (msg.mergeAnalytics) {} else {
        context = generateKey();
      }
      cdRef.analyticsController.mark({
        name: `${msg.name}:start`,
        context
      });
      const modelKey = this.model?.key;
      if (!msg.originCdRefKey && cdRef.key) {
        msg.originCdRefKey = cdRef.key;
      }
      if (modelKey) {
        msg.modelKey = modelKey;
      }
      try {
        const result = originalMethod.apply(this, args);
        msg.method = _propertyKey;
        msg.args = args;
        if (result && 'then' in result) {
          return result.then(result => {
            return finish(cdRef, msg, start, context, result);
          });
        } else {
          return finish(cdRef, msg, start, context, result);
        }
      } catch (err) {
        throw err;
      }
    };
    return descriptor;
  };
  return log;
};
