import { DcuplQueryBuilder } from './query-builder';
import { cloneDeep } from 'lodash-es';
const queryBuilder = new DcuplQueryBuilder();
export function getFacets(modelData, remoteModelData, options, query, attribute, modelIndexMap, queryManager) {
  const filterItemEntries = [];
  const queryWithoutCurrentFilter = queryBuilder.removeQuery({
    groupKey: attribute
  }, cloneDeep(query));
  const currentFilteredData = queryManager.queryData(modelData, queryWithoutCurrentFilter, false, modelIndexMap);
  const remoteModelDataKeys = remoteModelData ? [...remoteModelData?.keys()] : [];
  const validFacetKeys = new Set(remoteModelDataKeys);
  const indexMap = modelIndexMap.get(attribute);
  if (!options.excludeUnresolved) {
    for (const key of indexMap.keys()) {
      validFacetKeys.add(key);
    }
  }
  if (options.excludeUndefineds) {
    validFacetKeys.delete(undefined);
  } else {
    validFacetKeys.add(undefined);
  }
  for (const validFacetKey of validFacetKeys.values()) {
    const result = new Map();
    const indexedValueKeys = indexMap.get(validFacetKey) || [];
    for (const key of indexedValueKeys) {
      const value = currentFilteredData.get(key);
      if (value) {
        result.set(key, value);
        if (!options?.calculateFacets) {
          break;
        }
      }
    }
    const facetEntry = {
      key: String(validFacetKey),
      value: validFacetKey,
      selected: isFilterItemEntrySelected(attribute, validFacetKey, query),
      enabled: result.size > 0,
      size: result.size,
      entry: remoteModelData.get(validFacetKey),
      resultKeys: Array.from(result.keys())
    };
    if (options?.excludeZeros && facetEntry.size === 0) {
      continue;
    }
    if (options?.count && filterItemEntries.length === options?.count) {
      return filterItemEntries;
    }
    filterItemEntries.push(facetEntry);
  }
  return filterItemEntries;
}
const isFilterItemEntrySelected = (groupKey, facetKey, query) => {
  const idx = query.queries.findIndex(query => queryBuilder.isQueryGroup(query) && query.groupKey === groupKey);
  if (idx > -1) {
    const queryGroup = query.queries[idx];
    return !!queryGroup.queries.find(query => {
      if (queryBuilder.isQuery(query)) {
        const key = query.value?.key || query.value;
        return query.attribute === groupKey && key === facetKey;
      }
      return false;
    });
  }
  return false;
};
