export function generateObjectHash(input) {
  const stringifiedInput = fastStringify(input);
  return simpleHash(stringifiedInput);
}
function fastStringify(input) {
  if (typeof input !== 'object' || input === null) {
    return String(input);
  }
  if (Array.isArray(input)) {
    return '[' + input.map(fastStringify).join(',') + ']';
  }
  const keys = Object.keys(input).sort();
  let result = '{';
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    result += key + ':' + fastStringify(input[key]);
    if (i < keys.length - 1) {
      result += ',';
    }
  }
  result += '}';
  return result;
}
function simpleHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString(16);
}
