import { Injectable } from '@angular/core';
import { NET_LANGUAGES } from '@net-types';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translate: TranslateService) {}

  public init(): void {
    const browserLanguage = this.translate.getBrowserLang();
    const defLanguage =
      browserLanguage &&
      NET_LANGUAGES.find((language) => language.key == browserLanguage)
        ? browserLanguage
        : 'de';

    this.translate.setDefaultLang(defLanguage);
    this.translate.use(defLanguage);
  }
}
