export class DependencyGraph {
  nodes;
  edges;
  constructor() {
    this.nodes = new Set();
    this.edges = new Map();
  }
  addNode(name) {
    if (!this.nodes.has(name)) {
      this.nodes.add(name);
      this.edges.set(name, new Set());
    }
  }
  addDependency(from, to) {
    if (!this.nodes.has(from) || !this.nodes.has(to)) {
      throw new Error('Both nodes must exist before adding a dependency.');
    }
    this.edges.get(from).add(to);
  }
  removeNode(name) {
    if (!this.nodes.has(name)) {
      throw new Error('Node does not exist.');
    }
    this.nodes.delete(name);
    this.edges.delete(name);
    for (let deps of this.edges.values()) {
      deps.delete(name);
    }
  }
  removeDependency(from, to) {
    if (!this.edges.has(from)) {
      throw new Error('From node does not exist.');
    }
    this.edges.get(from).delete(to);
  }
  hasNode(name) {
    return this.nodes.has(name);
  }
  hasDependency(from, to) {
    if (!this.edges.has(from)) {
      return false;
    }
    return this.edges.get(from).has(to);
  }
  getDependencies(name) {
    if (!this.edges.has(name)) {
      throw new Error('Node does not exist.');
    }
    return Array.from(this.edges.get(name));
  }
  getDependents(name) {
    const dependents = [];
    for (let [node, deps] of this.edges) {
      if (deps.has(name)) {
        dependents.push(node);
      }
    }
    return dependents;
  }
  isCyclic() {
    const visited = new Set();
    const visiting = new Set();
    const visit = node => {
      if (visited.has(node)) {
        return false;
      }
      if (visiting.has(node)) {
        return true;
      }
      visiting.add(node);
      const dependencies = this.edges.get(node);
      if (dependencies) {
        for (let dep of dependencies) {
          if (visit(dep)) {
            return true;
          }
        }
      }
      visiting.delete(node);
      visited.add(node);
      return false;
    };
    for (let node of this.nodes) {
      if (visit(node)) {
        return true;
      }
    }
    return false;
  }
  order() {
    const visited = new Set();
    const visiting = new Set();
    const result = [];
    const visit = node => {
      if (visited.has(node)) {
        return;
      }
      if (visiting.has(node)) {
        throw new Error('Graph has a cycle.');
      }
      visiting.add(node);
      const dependencies = this.edges.get(node);
      if (dependencies) {
        for (let dep of dependencies) {
          visit(dep);
        }
      }
      visiting.delete(node);
      visited.add(node);
      result.push(node);
    };
    for (let node of this.nodes) {
      if (!visited.has(node)) {
        visit(node);
      }
    }
    return result;
  }
  clear() {
    this.nodes.clear();
    this.edges.clear();
  }
  getNodes() {
    return Array.from(this.nodes);
  }
  getAllEdges() {
    const edges = [];
    for (let [from, deps] of this.edges) {
      for (let to of deps) {
        edges.push([from, to]);
      }
    }
    return edges;
  }
}
