import { CatalogApi } from './catalog-api';
export class Catalog extends CatalogApi {
  dcuplApi;
  cdRef;
  indicesController;
  listKey;
  constructor(dcuplApi, cdRef, indicesController) {
    super(dcuplApi, cdRef, indicesController);
    this.dcuplApi = dcuplApi;
    this.cdRef = cdRef;
    this.indicesController = indicesController;
  }
  init(listKey, model, data) {
    this.listKey = listKey;
    this.model = model;
    this.filter.init(model, data);
    super.initListeners();
  }
  update(model, data, indicesController) {
    this.model = model;
    this.filter.update(model, data, indicesController);
  }
}
