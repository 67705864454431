export class ScriptController {
  scripts = new Map();
  scriptFunctions = new Map();
  constructor() {}
  keys() {
    return Array.from(this.scripts.keys());
  }
  setScript(key, script) {
    this.scripts.set(key, script);
  }
  removeScript(key) {
    this.scripts.delete(key);
  }
  getScript(key) {
    return this.scripts.get(key);
  }
  hasScript(key) {
    return this.scripts.has(key);
  }
  getScriptAsFn(key, argsAsKeys) {
    if (this.scriptFunctions.has(key)) {
      return this.scriptFunctions.get(key);
    }
    const script = this.getScript(key);
    if (!script) {
      throw new Error(`Script ${key} not found`);
    }
    const wrapperScript = this.getWrapperScript(script);
    const fn = new Function('script_type', ...argsAsKeys, wrapperScript);
    this.scriptFunctions.set(key, fn);
    return fn;
  }
  getWrapperScript(script) {
    const wrapperScript = `
    "use strict";
      ${script}
      `;
    return wrapperScript;
  }
  executeScript(key, type, args) {
    const script = this.getScript(key);
    if (!script) {
      throw new Error(`Script ${key} not found`);
    }
    if (!args.context) {
      args.context = args.options || {};
    }
    const argsAsKeys = Object.keys(args);
    const argsAsArray = Object.values(args);
    const fn = this.getScriptAsFn(key, argsAsKeys);
    return fn(type, ...argsAsArray);
  }
}
