import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { NetIcon, NetIconSize } from '@net-types';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[netButton], a[netButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() icon?: NetIcon;
  @Input() iconSize: NetIconSize = 24;
  @Input() iconOpacity?: 25 | 50 | 75 | 100 = 100;
  @Input() variant: 'flat' | 'outline' | 'link' | 'circle' = 'flat';
  @Input() size: 'x-small' | 'small' | 'basic' = 'basic';
  @Input() color: 'default' | 'hover-orange' | 'red' | 'dark-gray' = 'default';
  @Input() isDisabled?: boolean;
  @Input() isFull = true;

  constructor() {}

  protected _buttonClasses = [
    'flex',
    'justify-center',
    'px-4',
    'py-2',
    'border',
    'rounded-md',
    'focus:outline-none',
    'transition-colors',
    'duration-200',
  ];

  @HostBinding('class')
  get ButtonClasses(): string[] {
    let classes: string[] = [];

    if (this.isDisabled) {
      classes.push('opacity-50', 'cursor-not-allowed');
    } else {
      classes.push('opacity-100');
    }

    if (this.isFull === true) {
      classes.push('w-full');
    }

    if (this.variant === 'flat') {
      classes = [...classes, ...this._buttonClasses];
      classes.push(
        'bg-black',
        'text-white',
        'hover:bg-gray-700',
        'border-black',
        'shadow-sm'
      );
    }

    if (this.variant === 'outline') {
      classes = [...classes, ...this._buttonClasses];
      classes.push(
        'bg-white',
        'text-black',
        'border-current',
        'hover:text-gray-700',
        'shadow-sm'
      );
    }

    if (this.variant === 'link') {
      classes.push(
        'underline',
        'text-black',
        'border-none',
        'focus:border-none',
        'focus:ring-0',
        'px-1'
      );
    }

    if (this.variant === 'circle') {
      classes.push('rounded-full', 'flex', 'items-center', 'justify-center');
      if (this.size === 'x-small') {
        classes.push('h-5', 'w-5');
      }
      if (this.size === 'small') {
        classes.push('h-6', 'w-6');
      }
      if (this.size === 'basic') {
        classes.push('h-16', 'w-16');
      }
      if (this.color === 'default') {
        classes.push('bg-gray-100', 'hover:bg-gray-200');
      }
      if (this.color === 'hover-orange') {
        classes.push('bg-white', 'hover:bg-orange');
      }
      if (this.color === 'red') {
        classes.push('bg-red-500', 'hover:bg-red-600');
      }
      if (this.color === 'dark-gray') {
        classes.push('bg-gray-200', 'hover:bg-gray-500');
      }
    }

    return classes;
  }

  public getIconClasses(): string[] {
    const iconClasses: string[] = [];

    if (this.variant === 'flat') {
      iconClasses.push('fill-white');
    }

    if (this.iconOpacity === 25) {
      iconClasses.push('opacity-25');
    }
    if (this.iconOpacity === 50) {
      iconClasses.push('opacity-50');
    }
    if (this.iconOpacity === 75) {
      iconClasses.push('opacity-75');
    }

    return iconClasses;
  }
}
