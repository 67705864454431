import { difference, orderBy } from 'lodash-es';
export class ItemService {
  modelParser;
  scriptController;
  constructor(modelParser, scriptController) {
    this.modelParser = modelParser;
    this.scriptController = scriptController;
  }
  getItem(itemId, dataAsMap, model, options) {
    const projection = options?.projection || {
      $: true
    };
    const dataEntry = dataAsMap.get(itemId);
    if (!dataEntry) {
      return null;
    }
    const objToReturn = this.getProjectedData(projection, model, dataEntry);
    return objToReturn;
  }
  getManyItems(itemKeys, dataAsMap, model, options) {
    const items = [];
    for (const key of itemKeys) {
      const projection = options?.projection || {
        $: true
      };
      const dataEntry = dataAsMap.get(key);
      if (!dataEntry) {
        continue;
      }
      const objToReturn = this.getProjectedData(projection, model, dataEntry);
      items.push(objToReturn);
    }
    return items;
  }
  getItems(dataAsMap, model, options) {
    const result = this.getItemsAsMap(dataAsMap, model, options);
    return Array.from(result.values());
  }
  getItemsAsMap(dataAsMap, model, options) {
    const start = options?.start || 0;
    const count = options?.count || dataAsMap.size;
    const projection = options?.projection || {
      $: true
    };
    if (this.isValidSortProjection(options?.sort)) {
      dataAsMap = this.getSortedItems(dataAsMap, options, model);
    }
    if (this.isValidScriptSorting(options?.sort)) {
      dataAsMap = this.getSortedItemsBasedOnScript(dataAsMap, options);
    }
    const result = this.getPagedData(dataAsMap, start, count, projection, model);
    return result;
  }
  getSortedItemsBasedOnScript(data, options) {
    const dataAsArray = Array.from(data.values());
    const scriptName = options?.sort?.script?.key || '';
    const sortOptions = options?.sort?.script?.options || undefined;
    if (!this.scriptController.hasScript(scriptName)) {
      return data;
    }
    const sortedArray = this.scriptController.executeScript(scriptName, 'sort', {
      options: sortOptions,
      items: dataAsArray
    });
    return new Map(sortedArray.map(object => {
      return [object.key, object];
    }));
  }
  getSortedItems(data, options, model) {
    const attributes = [...options.sort.attributes];
    const dataAsArray = Array.from(data.values());
    const dataTransformer = (listItem, attr, undefinedValue) => {
      const value = listItem[attr];
      if (typeof value === 'undefined' || value === null) {
        return undefinedValue;
      }
      if (typeof value === 'string') {
        return value.toLowerCase();
      }
      if (typeof value === 'object') {
        if (typeof value?.key === 'string') {
          return value.key.toLowerCase();
        }
      }
      return value;
    };
    const transformerFn = attributes.map((attribute, index) => entry => {
      const relevantOrder = options.sort.order.at(index);
      const undefinedValue = this.getUndefinedValueForSorting(attribute, relevantOrder, model);
      return dataTransformer(entry, attribute, undefinedValue);
    });
    const order = [...options.sort.order].map(o => o.toLowerCase());
    const sortedArray = orderBy(dataAsArray, [...transformerFn], order);
    return new Map(sortedArray.map(object => {
      return [object.key, object];
    }));
  }
  getUndefinedValueForSorting(attribute, direction, model) {
    const property = model?.properties.get(attribute);
    if (property) {
      if (property.type === 'string') {
        if (direction == 'ASC') {
          return undefined;
        }
        if (direction == 'DESC') {
          return '';
        }
      }
      if (property.type === 'int' || property.type === 'float' || property.type === 'date' || property.type === 'boolean') {
        if (direction == 'ASC') {
          return Infinity;
        }
        if (direction == 'DESC') {
          return -Infinity;
        }
      }
    }
  }
  isValidProjection(projection) {
    if (!projection) {
      return false;
    }
    if (projection.$ === true && Object.keys(projection).length === 1) {
      return false;
    }
    return true;
  }
  getPagedData(data, start, count, projection, model) {
    const it = data.entries();
    const pagedData = new Map();
    // fast return - if we want to return the full default-projected dataset
    if (start === 0 && count === data.size && projection?.$ === true && Object.keys(projection).length === 1) {
      return data;
    }
    for (let i = 0; i < start + count; i++) {
      if (i < start) {
        it.next();
        continue;
      }
      if (i > start + count) {
        break;
      }
      const item = it.next().value;
      if (item) {
        if (model && projection && this.isValidProjection(projection)) {
          const objToReturn = this.getProjectedData(projection, model, item[1]);
          pagedData.set(item[0], objToReturn);
        } else {
          pagedData.set(item[0], item[1]);
        }
      }
    }
    return pagedData;
  }
  isValidScriptSorting(sort) {
    if (!sort) {
      return false;
    }
    if (!sort.script) {
      return false;
    }
    return true;
  }
  isValidSortProjection(sort) {
    const validOrderValues = ['ASC', 'DESC'];
    if (!sort || !Array.isArray(sort.attributes) || sort.attributes.length === 0 || !Array.isArray(sort.order) || sort.attributes.length !== sort.order.length) {
      return false;
    }
    if (difference(sort.order, validOrderValues).length > 0) {
      return false;
    }
    return true;
  }
  getProjectedData(projection, model, dataEntry) {
    let objToReturn = {
      key: dataEntry.key
    };
    if (projection.$ === true) {
      objToReturn = Object.assign({}, dataEntry);
    }
    if (projection.$$ === true) {
      objToReturn = Object.assign({}, dataEntry);
      model.references.forEach(ref => {
        if (!projection[ref.key]) {
          projection[ref.key] = {
            $: true
          };
        }
      });
    }
    const keys = Object.keys(projection);
    keys.filter(key => key !== '$' && key !== '$$').forEach(key => {
      const projectionValue = projection[key];
      if (projectionValue === false) {
        delete objToReturn[key];
        return;
      }
      if (projectionValue === true) {
        objToReturn[key] = dataEntry[key];
        return;
      }
      if (typeof projectionValue === 'object') {
        const reference = model.references.get(key);
        if (reference) {
          const remoteModel = this.modelParser.models.get(reference.model);
          if (remoteModel) {
            if (reference.type === 'multiValued') {
              const value = dataEntry[key] || [];
              objToReturn[key] = value.map(entryKey => {
                const entry = remoteModel.getEntry(entryKey.key);
                if (entry) {
                  return this.getProjectedData(projectionValue, remoteModel, entry);
                }
                return null;
              }).filter(Boolean);
            } else if (reference.type === 'singleValued') {
              const value = dataEntry[key];
              if (value) {
                const entry = remoteModel?.getEntry(value.key);
                if (entry) {
                  objToReturn[key] = this.getProjectedData(projectionValue, remoteModel, entry);
                }
              }
            }
          }
        }
        // todo: if a property is projected using $:true it should be returned...
      }
    });
    return objToReturn;
  }
}
