import { cloneDeep, findIndex } from 'lodash-es';
export class DcuplQueryBuilder {
  _defaultQueryOptions = {
    modelKey: '',
    groupKey: 'root',
    groupType: 'and',
    queries: []
  };
  _initOptions;
  currentQuery = cloneDeep(this._defaultQueryOptions);
  constructor() {}
  init(options) {
    this._initOptions = cloneDeep(options);
    this.currentQuery = cloneDeep(this._defaultQueryOptions);
    this.currentQuery.modelKey = options.modelKey;
  }
  reset() {
    this.init(this._initOptions);
    return this.getQuery();
  }
  isQuery(query) {
    return 'attribute' in query;
  }
  isQueryGroup(query) {
    return 'groupKey' in query;
  }
  isRootQueryGroup(groupOrQuery) {
    return this.isQueryGroup(groupOrQuery) && groupOrQuery.groupKey === 'root';
  }
  getQueryGroup(groupKey) {
    return this.currentQuery.queries.find(q => this.isQueryGroup(q) && q.groupKey === groupKey);
  }
  getQuery() {
    return cloneDeep(this.currentQuery);
  }
  hasQueryGroup(groupKey) {
    return this.currentQuery.queries.some(query => this.isQueryGroup(query) && query.groupKey === groupKey);
  }
  has(options) {
    if (options.groupKey && options.queryKey) {
      const group = this.getQueryGroup(options.groupKey);
      if (group) {
        return group.queries.some(q => this.isQuery(q) && q.queryKey === options.queryKey);
      }
    } else if (options.groupKey) {
      return this.hasQueryGroup(options.groupKey);
    }
    return false;
  }
  applyOptions(options, mode = 'update') {
    if (mode === 'set') {
      this.currentQuery.count = options.count;
      this.currentQuery.start = options.start;
      this.currentQuery.sort = options.sort;
      this.currentQuery.projection = options.projection;
    } else {
      if (typeof options?.sort !== 'undefined') {
        this.currentQuery.sort = options.sort;
      }
      if (typeof options?.count !== 'undefined') {
        this.currentQuery.count = options.count;
      }
      if (typeof options?.start !== 'undefined') {
        this.currentQuery.start = options?.start;
      }
      if (typeof options?.projection !== 'undefined') {
        this.currentQuery.projection = options?.projection;
      }
    }
    return this.getQuery();
  }
  applyQuery(queryToApply, options, relevantQuery) {
    if (!relevantQuery) relevantQuery = this.currentQuery;
    if (Array.isArray(queryToApply)) {
      for (const query of queryToApply) {
        this.applyQuery(query, options, relevantQuery);
      }
    } else if (this.isRootQueryGroup(queryToApply)) {
      if (queryToApply.groupType) relevantQuery.groupType = queryToApply.groupType;
      // if (queryToApply.options) relevantQuery.options = queryToApply.options;
      if (options?.mode === 'set') {
        relevantQuery.queries = queryToApply.queries;
      } else {
        relevantQuery.queries = [...relevantQuery.queries, ...queryToApply.queries];
      }
      this.applyOptions(queryToApply, 'set');
    } else if (this.isQuery(queryToApply)) {
      const idx = relevantQuery.queries.findIndex(query => this.isQueryGroup(query) && query.groupKey === queryToApply.attribute);
      if (idx > -1) {
        const queryGroup = relevantQuery.queries[idx];
        if (options?.mode === 'set') {
          queryGroup.queries = [queryToApply];
        } else {
          queryGroup.queries.push(queryToApply);
        }
      } else {
        relevantQuery.queries.push({
          groupKey: queryToApply.attribute,
          groupType: 'or',
          queries: [queryToApply]
        });
      }
      // handle query group
    } else if (this.isQueryGroup(queryToApply)) {
      const idx = relevantQuery.queries.findIndex(query => this.isQueryGroup(query) && query.groupKey === queryToApply.groupKey);
      if (idx > -1) {
        const queryGroup = relevantQuery.queries[idx];
        if (queryToApply.groupType) queryGroup.groupType = queryToApply.groupType;
        // if (queryToApply.options) queryGroup.options = queryToApply.options;
        if (options?.mode === 'set') {
          relevantQuery.queries[idx] = queryToApply;
        } else {
          queryGroup.queries = [...queryGroup.queries, ...queryToApply.queries];
        }
      } else {
        relevantQuery.queries.push({
          groupKey: queryToApply.groupKey,
          groupType: queryToApply.groupType || 'or',
          queries: queryToApply.queries
          // options: queryToApply.options,
        });
      }
    }
    return relevantQuery;
  }
  removeAllQueries() {
    this.currentQuery.queries = [];
    return this.getQuery();
  }
  removeQuery(queryToRemove, relevantQuery) {
    // handle default query
    if (!relevantQuery) relevantQuery = this.currentQuery;
    if (this.isQuery(queryToRemove)) {
      const idx = relevantQuery.queries.findIndex(query => this.isQueryGroup(query) && query.groupKey === queryToRemove.attribute);
      if (idx > -1) {
        const queryGroup = relevantQuery.queries[idx];
        const idxOfQueryToRemove = findIndex(queryGroup.queries, queryToRemove);
        if (idxOfQueryToRemove > -1) {
          queryGroup.queries.splice(idxOfQueryToRemove, 1);
        }
        if (queryGroup.queries.length === 0) {
          relevantQuery.queries.splice(idx, 1);
        }
      }
    } else if (this.isQueryGroup(queryToRemove)) {
      const idx = relevantQuery.queries.findIndex(query => this.isQueryGroup(query) && query.groupKey === queryToRemove.groupKey);
      if (idx > -1) {
        if (!queryToRemove.queries) {
          relevantQuery.queries.splice(idx, 1);
        } else {
          const queryGroup = relevantQuery.queries[idx];
          for (const q of queryToRemove.queries) {
            const idxOfQueryToRemove = findIndex(queryGroup.queries, q);
            if (idxOfQueryToRemove > -1) {
              queryGroup.queries.splice(idxOfQueryToRemove, 1);
            }
          }
          if (queryGroup.queries.length === 0) {
            relevantQuery.queries.splice(idx, 1);
          }
        }
      }
    }
    return relevantQuery;
  }
}
