export type NetLanguage = {
  key: NetLanguagesAvailable;
  label: string;
};

export const NET_LANGUAGES: NetLanguage[] = [
  { key: 'de', label: 'DE' },
  { key: 'en', label: 'EN' },
];

export type NetLanguagesAvailable = 'de' | 'en';
