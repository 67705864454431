// import { DepGraph, DepGraphCycleError } from 'dependency-graph';
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { generateKey, trigger, DependencyGraph } from '@dcupl/common';
import { DcuplModel } from './model/model';
import { orderBy, merge, groupBy } from 'lodash-es';
import { validateBasicModelStructure, validateModels } from './model/model.helper';
export class ModelParser {
  cdRef;
  qualityController;
  unprocessedModelDescriptions = new Map();
  unprocessedData = [];
  dependencyGraph;
  views = new Map();
  models = new Map();
  initOptions;
  calculationTimings = [];
  constructor(cdRef, qualityController) {
    this.cdRef = cdRef;
    this.qualityController = qualityController;
    this.qualityController.modelParser = this;
  }
  async init() {
    if (this.qualityController.enabled) {
      if (!this.models.get('$DcuplErrorTrackingErrors')) {
        this.qualityController.initErrorTrackingModels();
      }
    }
    this.prepareModels();
    if (this.qualityController.enabled) {
      this.validateModels();
    }
    this.initDependencyGraph();
    const relevantData = this.getMergedContainers(this.unprocessedData);
    await this.connectDataWithModels(relevantData);
    if (this.qualityController.enabled) {
      await this.qualityController.initData();
    }
  }
  prepareModels() {
    this.unprocessedModelDescriptions.forEach(modelDescription => {
      if (!this.models.has(modelDescription.key)) {
        const model = new DcuplModel(this);
        model.init(modelDescription);
        model.applyPropertiesAndReferences(modelDescription);
        this.models.set(model.key, model);
      } else {
        this.models.get(modelDescription.key).resetData();
        this.models.get(modelDescription.key).resetIndexMap();
        this.models.get(modelDescription.key).applyPropertiesAndReferences(modelDescription);
      }
    });
  }
  validateModels() {
    this.models.forEach(model => {
      if (model.qualityConfig.enabled === true) {
        const errors = validateModels(model, this.models);
        errors.forEach(error => {
          this.setError(error, model);
        });
      }
    });
  }
  /**
   * initDependencyGraph
   * The dependencies are only affected by references. Derived properties/references do not count since they
   * are only possible if references are already present to derive from. Same goes for resolved references.
   */
  initDependencyGraph() {
    this.dependencyGraph = new DependencyGraph();
    this.models.forEach(model => {
      this.dependencyGraph.addNode(model.key);
    });
    this.models.forEach(model => {
      model.getBasicReferences().forEach(reference => {
        if (model.key === reference.model) {
          return;
        }
        this.dependencyGraph.addNode(reference.model);
        this.dependencyGraph.addDependency(model.key, reference.model);
      });
    });
  }
  async update() {
    await this.init();
  }
  async reprocessData() {
    if (this.qualityController.enabled) {
      this.qualityController.resetModelErrors();
    }
    const relevantData = this.getMergedContainers(this.unprocessedData);
    await this.connectDataWithModels(relevantData);
  }
  getSortedCalculationTimings() {
    return orderBy(this.calculationTimings, 'value');
  }
  addModel(model) {
    if (model?.quality?.enabled !== false) {
      try {
        validateBasicModelStructure(model);
      } catch (err) {
        if (this.qualityController.enabled) {
          this.qualityController.setError({
            title: 'Invalid Model Definition',
            type: 'model',
            errorGroup: 'ModelDefinitionError',
            errorType: 'InvalidModelDefinition',
            model: model.key,
            description: err.message
          });
        }
        return;
      }
    }
    if (this.unprocessedModelDescriptions.has(model.key)) {
      const existingModel = this.unprocessedModelDescriptions.get(model.key);
      const newModel = this.mergeModels(existingModel, model);
      this.unprocessedModelDescriptions.set(model.key, newModel);
    } else {
      this.unprocessedModelDescriptions.set(model.key, model);
    }
    if (model.data) {
      this.addData({
        model: model.key,
        data: model.data,
        autoGenerateProperties: model.autoGenerateProperties,
        autoGenerateKey: model.autoGenerateKey,
        keyProperty: model.keyProperty
      });
    }
  }
  mergeModels(modelA, modelB) {
    const newModel = Object.assign({}, modelA);
    if (Array.isArray(modelA.properties)) {
      newModel.properties = [...modelA.properties];
    }
    if (Array.isArray(modelA.references)) {
      newModel.references = [...modelA.references];
    }
    if (Array.isArray(modelB.properties)) {
      newModel.properties?.push(...modelB.properties);
    }
    if (Array.isArray(modelB.references)) {
      newModel.references?.push(...modelB.references);
    }
    return newModel;
  }
  addData(container) {
    if (!container || !container.model || !container.data) {
      throw new Error('Invalid Data Container');
    }
    if (!Array.isArray(container.data)) {
      throw new Error('Invalid Data - Data must be an Array');
    }
    if (!container.type) {
      container.type = 'set';
    }
    if (container.autoGenerateProperties) {
      this.addModel({
        key: container.model,
        autoGenerateProperties: true,
        autoGenerateKey: container.autoGenerateKey,
        keyProperty: container.keyProperty || 'key'
      });
    }
    this.unprocessedData.push(container);
  }
  addView(view) {
    this.views.set(view.key, view);
  }
  setError(error, model) {
    if (this.qualityController.enabled) {
      if (model.qualityConfig.enabled === true) {
        this.qualityController.setError(error);
      }
    }
  }
  getMergedContainers(containers) {
    const newContainers = this.handleAlternativeKey(containers);
    const relevantContainer = newContainers.filter(container => !container.placeholderUid);
    const mergedContainers = [];
    const groupedContainers = groupBy(relevantContainer, 'model');
    for (const key in groupedContainers) {
      const containerGroup = groupedContainers[key];
      if (containerGroup.length > 1) {
        const container = this.mergeContainers(containerGroup);
        mergedContainers.push(container);
      } else {
        mergedContainers.push(containerGroup[0]);
      }
    }
    return mergedContainers;
  }
  mergeContainers(containers) {
    let dataset;
    containers?.forEach(newContainer => {
      if (!dataset) {
        if (newContainer.type === 'remove') {
          return;
        }
        dataset = new Map(newContainer.data.map(object => {
          return [object.key, object];
        }));
        return;
      }
      switch (newContainer.type) {
        case 'upsert':
          newContainer.data.forEach(entry => {
            const itemExists = dataset.has(entry.key);
            if (itemExists) {
              let item = dataset.get(entry.key);
              item = merge(item, entry);
              dataset.set(entry.key, item);
            } else {
              dataset.set(entry.key, entry);
            }
          });
          break;
        case 'update':
          newContainer.data.forEach(entry => {
            const itemExists = dataset.has(entry.key);
            if (itemExists) {
              let item = dataset.get(entry.key);
              item = merge(item, entry);
              dataset.set(entry.key, item);
            }
          });
          break;
        case 'remove':
          newContainer.data.forEach(entry => {
            dataset.delete(entry.key);
          });
          break;
        case 'set':
          dataset = new Map(newContainer.data.map(object => {
            return [object.key, object];
          }));
          break;
        default:
          dataset = new Map(newContainer.data.map(object => {
            return [object.key, object];
          }));
          break;
      }
    });
    const container = {
      model: containers[0].model,
      data: Array.from(dataset.values()),
      type: 'set'
    };
    return container;
  }
  async connectDataWithModels(containers) {
    try {
      this.dependencyGraph['circular'] = false;
      this.dependencyGraph.order();
    } catch (error) {
      console.warn('Your models have circular dependencies which might lead to errors. Please consider changing your models');
      console.error(error);
      console.log(error);
    }
    this.dependencyGraph['circular'] = true;
    const orderedModelKeys = this.dependencyGraph.order();
    containers.sort((a, b) => {
      return orderedModelKeys.indexOf(a.model) - orderedModelKeys.indexOf(b.model);
    });
    await this.handleDataContainer(containers);
  }
  handleAlternativeKey(containers) {
    containers.forEach(container => {
      const model = this.models.get(container.model);
      const keyProperty = container.keyProperty || model?.keyProperty;
      const autoGenerateKey = container.autoGenerateKey || model?.autoGenerateKey;
      if (keyProperty && keyProperty !== 'key') {
        container.data.forEach(item => {
          item.key = String(item[keyProperty]);
        });
      } else if (autoGenerateKey) {
        container.data.forEach(item => {
          if (!item.key) {
            item.key = generateKey();
          }
        });
      }
    });
    return containers;
  }
  async handleDataContainer(containers) {
    for (const dataContainer of containers) {
      const model = this.models.get(dataContainer.model);
      if (model?.autoGenerateProperties) {
        model.generatePropertiesFromContainer(dataContainer);
      }
    }
    for (const dataContainer of containers) {
      await new Promise(resolve => {
        setTimeout(() => {
          const model = this.models.get(dataContainer.model);
          const context = generateKey();
          this.cdRef.analyticsController.mark({
            name: `dcupl:process_data:step_1:start`,
            context
          });
          if (model) {
            model.addBasicPropertiesAndReferences(dataContainer);
            if (this.qualityController.enabled) {
              if (model.qualityConfig.enabled === true) {
                this.qualityController.cleanUpErrors(model, dataContainer, 'UndefinedValue');
                // this.qualityController.cleanUpErrors(model, dataContainer, 'RemoteReferenceKeyNotFound');
              }
            }
          }
          this.cdRef.analyticsController.mark({
            name: `dcupl:process_data:step_1:end`,
            context
          });
          this.cdRef.analyticsController.measure({
            name: `dcupl:process_data:step_1`,
            start: `dcupl:process_data:step_1:start`,
            end: `dcupl:process_data:step_1:end`,
            context,
            detail: {
              model: dataContainer.model
            }
          });
          return resolve(true);
        }, 0);
      });
    }
    for (const dataContainer of containers) {
      await new Promise(resolve => {
        setTimeout(() => {
          const model = this.models.get(dataContainer.model);
          const context = generateKey();
          this.cdRef.analyticsController.mark({
            name: `dcupl:process_data:step_2:start`,
            context
          });
          if (model && model.hasResolvedReferences()) {
            model.addResolvedReferences(dataContainer);
          }
          this.cdRef.analyticsController.mark({
            name: `dcupl:process_data:step_2:end`,
            context
          });
          this.cdRef.analyticsController.measure({
            name: `dcupl:process_data:step_2`,
            start: `dcupl:process_data:step_2:start`,
            end: `dcupl:process_data:step_2:end`,
            context,
            detail: {
              model: dataContainer.model
            }
          });
          return resolve(true);
        }, 0);
      });
    }
    for (const dataContainer of containers) {
      await new Promise(resolve => {
        setTimeout(() => {
          const model = this.models.get(dataContainer.model);
          const context = generateKey();
          this.cdRef.analyticsController.mark({
            name: `dcupl:process_data:step_3:start`,
            context
          });
          if (model && (model.hasDerivedPropertiesForBasicReferences() || model?.hasDerivedReferences() || model?.hasGroupedReferences() || model?.hasExpressionProperties())) {
            model.addDerivedReferencesGroupedReferencesDerivedPropertiesAndExpressionProperties(dataContainer);
          }
          this.cdRef.analyticsController.mark({
            name: `dcupl:process_data:step_3:end`,
            context
          });
          this.cdRef.analyticsController.measure({
            name: `dcupl:process_data:step_3`,
            start: `dcupl:process_data:step_3:start`,
            end: `dcupl:process_data:step_3:end`,
            context,
            detail: {
              model: dataContainer.model
            }
          });
          return resolve(true);
        }, 0);
      });
    }
    /**
     * In theory... if there is a derivedProperty based on a resolved reference we have to reprocess all Properties of this model.
     */
    for (const dataContainer of containers) {
      await new Promise(resolve => {
        setTimeout(() => {
          const model = this.models.get(dataContainer.model);
          const context = generateKey();
          this.cdRef.analyticsController.mark({
            name: `dcupl:process_data:step_4:start`,
            context
          });
          if (model && (model.hasDerivedPropertiesBasedOnResolvedReferences() || model?.hasDerivedReferencesBasedOnResolvedReferences())) {
            model.reRunDerivedPropertiesBasedOnResolves(dataContainer);
          }
          this.cdRef.analyticsController.mark({
            name: `dcupl:process_data:step_4:end`,
            context
          });
          this.cdRef.analyticsController.measure({
            name: `dcupl:process_data:step_4`,
            start: `dcupl:process_data:step_4:start`,
            end: `dcupl:process_data:step_4:end`,
            context,
            detail: {
              model: dataContainer.model
            }
          });
          return resolve(true);
        }, 0);
      });
      const model = this.models.get(dataContainer.model);
      const errors = this.qualityController.validateAttributesAfterInitialization(model);
      errors.forEach(error => {
        this.setError(error, model);
      });
    }
  }
  _getModelKeys() {
    return Array.from(this.models.values()).map(model => model.key).filter(key => key && !key.startsWith('$Dcupl'));
  }
  _getViewKeys(options) {
    const modelKey = options?.modelKey;
    return Array.from(this.views.values()).filter(view => {
      if (modelKey) {
        return view.model === modelKey;
      }
      return true;
    }).map(view => view.key);
  }
}
__decorate([trigger({
  scope: 'global',
  name: 'dcupl:process_data'
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Array]), __metadata("design:returntype", Promise)], ModelParser.prototype, "handleDataContainer", null);
