export class DcuplCsvParser {
  parse(csv, options = {}) {
    const {
      quoteChar = '"',
      escapeChar = quoteChar,
      comments = '#',
      dynamicTyping = false,
      header = true,
      skipEmptyLines = true,
      transformHeader,
      transform
    } = options;
    const rows = []; // Array to store parsed rows
    const headers = header ? [] : null; // Array for headers if header option is true
    let csvLength = csv.length;
    let currentRow = []; // Array to store individual fields of a row
    // let currentField = []; // String to accumulate characters for a single field
    let currentField = [];
    let inQuote = false; // Flag to track if we are inside a quoted field
    let isFirstRow = true; // Flag to track the first row for header parsing
    let fieldIndex = 0; // Index to track the current field position for header alignment
    let i = 0; // Initialize index to start of csv
    // Skip leading empty lines if skipEmptyLines is enabled
    if (skipEmptyLines) {
      while (i < csvLength && (csv[i] === '\n' || csv[i] === '\r' || csv[i] === '' || csv[i] === "'" || csv[i] === '"')) {
        i++;
      }
      csv = csv.slice(i); // Remove leading empty lines
      csvLength = csv.length; // Update csv length after removing empty lines
      i = 0; // Reset index after removing empty lines
    }
    const delimiter = options.delimiter || this.detectDelimiter(csv); // Automatically detect delimiter if not provided
    for (; i < csvLength; i++) {
      const char = csv[i];
      const nextChar = csv[i + 1];
      // Handle comments (skip the entire line if comment character is detected at start of line)
      if (comments && char === comments && !inQuote && currentField.length === 0) {
        while (i < csvLength && csv[i] !== '\n') i++;
        continue;
      }
      // Handle line breaks
      if (char === '\n' || char === '\r' && nextChar === '\n') {
        // if (isFirstRow && !currentRow.length) {
        //   inQuote = false;
        //   fieldIndex = 0;
        //   i += char === '\r' && nextChar === '\n' ? 1 : 0; // Handle \r\n line endings
        //   continue;
        // }
        if (!inQuote) {
          // Push the current field to the row at line end
          if (currentField.length > 0 || !skipEmptyLines) {
            currentRow.push(currentField.join(''));
          }
          // If this is the first row, populate headers if needed
          if (isFirstRow && headers) {
            currentRow.forEach(h => headers.push(transformHeader ? transformHeader(h) : h));
          } else if (headers) {
            rows.push(this.buildObjectRow(headers, currentRow, dynamicTyping, transform));
          } else {
            rows.push([...currentRow]); // Push as simple array if no headers
          }
          currentRow = []; // Reset current row after line end
          currentField = []; // Reset field
          fieldIndex = 0; // Reset field index
          isFirstRow = false;
          i += char === '\r' && nextChar === '\n' ? 1 : 0; // Handle \r\n line endings
          continue;
        } else {
          currentField.push(char); // Append newline if inside quotes
        }
      }
      // Handle delimiters when not inside quotes
      else if (char === delimiter && !inQuote) {
        currentRow.push(currentField.join(''));
        fieldIndex++; // Move to next field
        currentField = []; // Reset field
      }
      // Handle quote characters
      else if (char === quoteChar) {
        if (inQuote) {
          // If inside quotes, check for escape character
          if (nextChar === escapeChar) {
            currentField.push(quoteChar); // Add a single quote
            i++; // Skip the escape character
          } else {
            inQuote = false; // End quoted section
          }
        } else if (currentField.length === 0) {
          inQuote = true; // Start quoted section if not already inside one
        } else {
          currentField.push(char); // Add quote character as literal if unquoted
        }
      }
      // Detect JSON structures and capture entire JSON block
      else if ((inQuote || currentField.length === 0) && (char === '{' || char === '[')) {
        const jsonField = this.extractJson(csv, i); // Extract JSON if detected
        currentField.push(jsonField.value);
        i += jsonField.length - 1; // Skip over the JSON section
      } else {
        currentField.push(char); // Append character to the current field
      }
    }
    // #####
    // Final push of the last row and field if the CSV ends without a newline
    if (currentField.length > 0 || !skipEmptyLines) {
      currentRow.push(currentField.join(''));
    }
    if (currentRow.length) {
      if (isFirstRow && headers) {
        currentRow.forEach(h => headers.push(transformHeader ? transformHeader(h) : h));
      } else if (headers) {
        rows.push(this.buildObjectRow(headers, currentRow, dynamicTyping, transform));
      } else {
        rows.push([...currentRow]);
      }
    }
    return rows;
  }
  // Builds an object from a row array based on headers
  buildObjectRow(headers, row, dynamicTyping, transform) {
    transform;
    const objectRow = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      let value = row[i];
      if (dynamicTyping) {
        value = this.dynamicType(value); // Apply dynamic typing for JSON, numbers, etc.
      }
      objectRow[header] = transform ? transform(value, header) : value; // Apply transform if provided
      // objectRow[header as any] = value // Apply transform if provided
    }
    return objectRow;
  }
  // Detects the most likely delimiter based on frequency in the sample
  detectDelimiter(csv) {
    const sample = csv.slice(0, csv.indexOf('\n'));
    const delimiters = [',', ';', '\t', '|'];
    const delimiterCounts = delimiters.map(delimiter => {
      const regex = new RegExp(`\\${delimiter}`, 'g');
      return (sample.match(regex) || []).length;
    });
    const bestDelimiter = delimiters[delimiterCounts.indexOf(Math.max(...delimiterCounts))];
    return bestDelimiter || ','; // Default to ',' if no delimiter found
  }
  // Apply transformation if a transform function is provided
  // private applyTransform(
  //   value: string,
  //   header?: string,
  //   transform?: (value: string, header?: string) => string,
  // ): string {
  //   return transform ? transform(value, header) : value;
  // }
  // Apply dynamic typing to convert JSON, numbers, and booleans from strings
  dynamicType(value) {
    if (value === 'true') return true;
    if (value === 'false') return false;
    if (!isNaN(Number(value))) return Number(value);
    try {
      return JSON.parse(value); // Attempt to parse JSON if structure matches
    } catch {
      return value;
    }
  }
  // Extracts JSON strings by tracking matching braces and brackets
  extractJson(csv, startIndex) {
    const stack = [];
    let endIndex = startIndex;
    while (endIndex < csv.length) {
      const char = csv[endIndex];
      if (char === '\n' || char === '\r') {
        console.error('JSON block spans multiple lines');
        break; // Stop at end of line
      }
      if (char === '{' || char === '[') {
        stack.push(char); // Track opening braces/brackets
      } else if (char === '}' && stack[stack.length - 1] === '{' || char === ']' && stack[stack.length - 1] === '[') {
        stack.pop(); // Pop from stack when a matching closing brace/bracket is found
      }
      endIndex++;
      if (stack.length === 0) break; // Stop if JSON structure is complete
    }
    let value = csv.slice(startIndex, endIndex);
    // value = value.replace(/\//g, ''); // Remove quotes from JSON string
    return {
      value,
      length: endIndex - startIndex
    };
  }
}
