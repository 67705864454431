var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { trigger, computeSuggestions, pivot, IndicesController, DcuplQueryBuilder } from '@dcupl/common';
import { Filter } from './filter/filter';
import { ItemService } from './item/item';
export class CatalogApi {
  dcuplApi;
  cdRef;
  indicesController;
  metadata;
  model;
  filter;
  itemService;
  constructor(dcuplApi, cdRef, indicesController) {
    this.dcuplApi = dcuplApi;
    this.cdRef = cdRef;
    this.indicesController = indicesController;
    this.itemService = new ItemService(this.dcuplApi['modelParser'], this.dcuplApi['scriptController']);
    this.filter = new Filter(this.dcuplApi['modelParser'], this.cdRef, this.itemService, this.indicesController, this.dcuplApi['queryManager']);
  }
  initListeners() {
    this.initListMetadata();
  }
  initListMetadata() {
    this.metadata = {
      key: this.model.key,
      currentSize: this.filter.filteredData.size,
      initialSize: this.filter.initialData.size,
      attributes: this.getModelAttributes(),
      views: this.getViews(),
      appliedQuery: this.filter.queryBuilder.getQuery()
    };
  }
  getModelAttributes() {
    const attributes = [...this.model.references.values(), ...this.model.properties.values()];
    return attributes;
  }
  query = {
    apply: (query, options) => {
      return this.applyQuery(query, options);
    },
    applyOptions: (itemOptions, options) => {
      return this.applyItemOptions(itemOptions, options);
    },
    remove: options => {
      return this.removeQuery(options);
    },
    reset: (options = {
      skipProcessing: false
    }) => {
      return this.resetQuery(options);
    },
    execute: query => {
      return this.executeQuery(query);
    },
    one: options => {
      return this._getItem(options);
    },
    many: options => {
      return this._getManyItems(options);
    },
    get: () => {
      return this.getQuery();
    },
    has: options => {
      return this.hasGroupOrQueryKey(options);
    }
  };
  filters = {
    get: options => {
      return this.getFilter(options);
    },
    getAll: options => {
      return this.getFilters(options);
    }
  };
  fn = {
    pivot: options => {
      return this.getPivot(options);
    },
    aggregate: options => {
      return this.getAggregate(options);
    },
    suggest: options => {
      return this._getSuggestions(options);
    },
    groupBy: options => {
      return this._getSections(options);
    },
    facets: options => {
      return this.getFacets(options);
    },
    metadata: () => {
      return this.getMetadata();
    }
  };
  getViews() {
    const views = [];
    this.filter.modelParser.views.forEach(view => {
      if (view.model === this.model.key) {
        views.push(view);
      }
    });
    return views;
  }
  getMetadata() {
    return this._getMetadata();
  }
  _getMetadata() {
    this.initListMetadata();
    return this.metadata;
  }
  getFilters(options) {
    return this._getFilters(options);
  }
  _getFilters(options) {
    const result = this.filter.getFilterItems(options);
    if (options?.filterKeys) {
      return options.filterKeys.map(key => {
        const filter = result.find(filter => filter.key === key);
        if (!filter) {
          console.log(`Filter ${key} not found in model ${this.model.key}`);
        }
        return filter;
      }).filter(Boolean);
    }
    return result;
  }
  getFilter(options) {
    return this._getFilter(options);
  }
  _getFilter(options) {
    const result = this.filter.getFilterItem(options.filterKey, options);
    return result;
  }
  getQuery() {
    return this.filter.queryBuilder.getQuery();
  }
  hasGroupOrQueryKey(options) {
    return this.filter.queryBuilder.has(options);
  }
  applyQuery(queryToApply, options) {
    const query = this.filter.queryBuilder.applyQuery(queryToApply, options);
    this.filter.updateFilteredData({
      skipProcessing: options?.skipProcessing
    });
    return query;
  }
  applyItemOptions(itemOptions, options) {
    const query = this.filter.queryBuilder.applyOptions(itemOptions, options?.mode);
    this.filter.updateFilteredData({
      skipProcessing: options?.skipProcessing
    });
    return query;
  }
  removeQuery(queryToRemove, options) {
    let query;
    if (queryToRemove) {
      query = this.filter.queryBuilder.removeQuery(queryToRemove);
    } else {
      query = this.filter.queryBuilder.removeAllQueries();
    }
    this.filter.updateFilteredData({
      skipProcessing: options?.skipProcessing
    });
    return query;
  }
  resetQuery(options) {
    const query = this.filter.queryBuilder.reset();
    this.filter.updateFilteredData({
      skipProcessing: options?.skipProcessing
    });
    return query;
  }
  executeQuery(options) {
    const cacheContext = this.filter.cacheCtrl.generateContext({
      options
    });
    const hasCache = this.filter.cacheCtrl.has('pivot', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('pivot', cacheContext);
    }
    if (!options) {
      options = this.filter.queryBuilder.getQuery();
    }
    const fullOptions = Object.assign({}, options, {
      modelKey: this.model.key
    });
    const queriedData = this.dcuplApi['queryManager'].queryData(this.filter.filteredData, fullOptions, false, this.filter.indicesController['indexMap']);
    const items = this.itemService.getItems(queriedData, this.model, options);
    this.filter.cacheCtrl.set('query', cacheContext, items);
    return items;
  }
  _getSections(options) {
    const cacheContext = this.filter.cacheCtrl.generateContext({
      options,
      query: this.filter.queryBuilder.getQuery()
    });
    const hasCache = this.filter.cacheCtrl.has('sections', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('sections', cacheContext);
    }
    const response = this.filter.getSections(options);
    this.filter.cacheCtrl.set('sections', cacheContext, response);
    return response;
  }
  _getItem(options) {
    const item = this.itemService.getItem(options.itemKey, this.filter.filteredData, this.model, options);
    return item;
  }
  _getManyItems(options) {
    const items = this.itemService.getManyItems(options.itemKeys, this.filter.filteredData, this.model, options);
    return items;
  }
  _getSuggestions(options) {
    // set defaults
    if (typeof options.excludeUndefineds === 'undefined') {
      options.excludeUndefineds = true;
    }
    if (typeof options.excludeNulls === 'undefined') {
      options.excludeNulls = true;
    }
    // check cache
    const cacheContext = this.filter.cacheCtrl.generateContext({
      options,
      query: this.filter.queryBuilder.getQuery()
    });
    const hasCache = this.filter.cacheCtrl.has('suggestions', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('suggestions', cacheContext);
    }
    // check if attribute exists
    const attribute = this.model.getAttribute(options.attribute);
    if (!attribute) {
      throw new Error(`Attribute ${options.attribute} does not exist`);
    }
    if (typeof options.value === 'string' && options.value.startsWith('/') && options.value.endsWith('/')) {
      try {
        new RegExp(options.value.slice(1, -1));
      } catch (err) {
        return [];
      }
    }
    let relevantData;
    let relevantQuery = undefined;
    if (options.relevantData === 'all') {
      relevantData = this.filter.initialData;
      relevantQuery = {
        modelKey: this.model.key,
        queries: []
      };
    } else if (options.relevantData === 'filtered') {
      relevantData = this.filter.filteredData;
      relevantQuery = this.filter.queryBuilder.getQuery();
    } else if (options.relevantData === 'excludeQuery' && options.excludeQuery) {
      const qb = new DcuplQueryBuilder();
      qb.init(this.filter.queryBuilder.getQuery());
      qb.applyQuery(this.filter.queryBuilder.getQuery());
      qb.removeQuery(options.excludeQuery);
      relevantQuery = qb.getQuery();
      const filteredDataWithExcludedQuery = this.dcuplApi['queryManager'].queryData(this.filter.initialData, relevantQuery, false, this.filter.indicesController['indexMap']);
      relevantData = filteredDataWithExcludedQuery;
    } else {
      // default - use filtered data
      relevantData = this.filter.filteredData;
      relevantQuery = this.filter.queryBuilder.getQuery();
    }
    const indexMap = this.filter.indicesController.getOrCreateIndex(options.attribute, relevantData, {
      query: relevantQuery
    });
    const suggestions = computeSuggestions(relevantData, indexMap, options, this.dcuplApi['queryManager']);
    this.filter.cacheCtrl.set('suggestions', cacheContext, suggestions);
    return suggestions;
  }
  getFacets(options) {
    return this._getFacets(options);
  }
  _getFacets(options) {
    const cacheContext = this.filter.cacheCtrl.generateContext({
      options,
      query: this.filter.queryBuilder.getQuery()
    });
    const hasCache = this.filter.cacheCtrl.has('facets', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('facets', cacheContext);
    }
    const reference = this.model.references.get(options.attribute);
    let remoteModel;
    if (reference) {
      remoteModel = this.filter.modelParser.models.get(reference.model);
    }
    if (typeof options.excludeZeros === 'undefined') {
      options.excludeZeros = true;
    }
    if (typeof options.excludeUndefineds === 'undefined') {
      options.excludeUndefineds = true;
    }
    if (typeof options.excludeUnresolved === 'undefined') {
      options.excludeUnresolved = true;
    }
    if (typeof options.calculateResults === 'undefined') {
      options.calculateResults = true;
    }
    const entries = this.filter.getFacets(options.attribute, remoteModel, {
      calculateFacets: options.calculateResults,
      count: options.count,
      excludeZeros: options.excludeZeros,
      excludeUndefineds: options.excludeUndefineds,
      excludeUnresolved: options.excludeUnresolved,
      skipProcessing: false
    });
    this.filter.cacheCtrl.set('facets', cacheContext, entries);
    return entries;
  }
  getAggregate(options) {
    const cacheContext = this.filter.cacheCtrl.generateContext({
      options,
      query: this.filter.queryBuilder.getQuery()
    });
    const hasCache = this.filter.cacheCtrl.has('aggregation', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('aggregation', cacheContext);
    }
    const aggregate = this.filter.getAggregate(options, this.filter.filteredData, this.filter.indicesController.getOrCreateIndex(options.attribute, this.filter.filteredData, this.getQuery()));
    this.filter.cacheCtrl.set('aggregation', cacheContext, aggregate);
    return aggregate;
  }
  getPivot(options) {
    if (!options || !Array.isArray(options.columns) || !Array.isArray(options.rows) || !options.values || !options.values.length) {
      console.log(`Invalid pivot options`);
      return {
        key: 'invalid_options'
      };
    }
    const cacheContext = this.filter.cacheCtrl.generateContext({
      options,
      query: this.filter.queryBuilder.getQuery()
    });
    const hasCache = this.filter.cacheCtrl.has('pivot', cacheContext);
    if (hasCache) {
      return this.filter.cacheCtrl.get('pivot', cacheContext);
    }
    /**
     * TODO: Check for possible Improvement
     * We are passing a new indicesController every time we call pivot
     * We should probably pass a valid context depending on the current pivoting operation
     * After the last pivoting operation there are more fine tuned indices that can be reused
     * Check if those child indices (current query + current pivot depth) can be reused...
     */
    let indicesCtrl = new IndicesController();
    if (this.filter.filteredData.size === this.filter.initialData.size) {
      indicesCtrl = this.filter.indicesController;
    }
    const result = pivot(this.filter.filteredData, options, {
      key: 'root'
    }, 'root', indicesCtrl);
    this.filter.cacheCtrl.set('pivot', cacheContext, result);
    return result;
  }
}
__decorate([trigger({
  scope: 'list',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", Object)], CatalogApi.prototype, "_getMetadata", null);
__decorate([trigger({
  scope: 'list',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "_getFilters", null);
__decorate([trigger({
  scope: 'list',
  name: 'catalog:getFilter',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], CatalogApi.prototype, "_getFilter", null);
__decorate([trigger({
  scope: 'list',
  name: 'catalog:execute_query',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "executeQuery", null);
__decorate([trigger({
  scope: 'list',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], CatalogApi.prototype, "_getSections", null);
__decorate([trigger({
  scope: 'list',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], CatalogApi.prototype, "_getItem", null);
__decorate([trigger({
  scope: 'list',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "_getManyItems", null);
__decorate([trigger({
  scope: 'list',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "_getSuggestions", null);
__decorate([trigger({
  scope: 'list',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], CatalogApi.prototype, "_getFacets", null);
__decorate([trigger({
  scope: 'list',
  cloneResult: false
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], CatalogApi.prototype, "getPivot", null);
