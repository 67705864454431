var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetector, trigger, generateKey, perf, evaluateTemplate } from '@dcupl/common';
import { CsvParser } from '../../csv-parser/csv-parser';
import { AppLoaderProgressHandler } from './app-loader-progress';
import { cloneDeep, intersection } from 'lodash-es';
const defaultOptions = {
  missingModelHandling: {
    autoGenerateProperties: true
  },
  defaultDataContainerUpdateType: 'set',
  csvParserOptions: {
    delimiter: '',
    quoteChar: '"',
    escapeChar: '"'
  }
};
export class DcuplAppLoader {
  key;
  core;
  cdRef = new ChangeDetector();
  registeredTransformers = [];
  options = defaultOptions;
  csvParser = new CsvParser(this);
  currentConfig;
  globalVariables = new Map();
  defaultVariables = new Map();
  progressListeners = new Set();
  currentProcessOptions;
  currentContext = '';
  progressHandler = new AppLoaderProgressHandler();
  constructor() {
    this.initDefaultVariables();
  }
  destroy() {
    this.core = null;
    this.cdRef = null;
    this.csvParser = null;
    this.progressListeners = null;
    this.progressHandler = null;
  }
  initDefaultVariables() {
    this.defaultVariables.set('projectId', {
      key: 'projectId',
      value: undefined,
      description: 'Your dcupl console project id'
    });
    this.defaultVariables.set('apiKey', {
      key: 'apiKey',
      value: undefined,
      description: 'Your dcupl console project API key'
    });
    this.defaultVariables.set('version', {
      key: 'version',
      value: 'draft',
      description: 'The version of uploaded files to the dcupl CDN - draft/published/...'
    });
    this.defaultVariables.set('cdnBaseUrl', {
      key: 'cdnBaseUrl',
      value: 'https://cdn.dcupl.com/files/projects',
      description: 'The url to your dcupl folder - might be the dcupl CDN or any other '
    });
    this.defaultVariables.set('baseUrl', {
      key: 'baseUrl',
      value: '${cdnBaseUrl}/${projectId}/${version}',
      description: 'The base url to your hosted models and data files'
    });
    this.defaultVariables.set('modelUrl', {
      key: 'modelUrl',
      value: '${baseUrl}/models',
      description: 'The url to the your model files'
    });
    this.defaultVariables.set('dataUrl', {
      key: 'dataUrl',
      value: '${baseUrl}/data',
      description: 'The url to the your data files'
    });
    this.defaultVariables.set('transformerUrl', {
      key: 'transformerUrl',
      value: '${baseUrl}/transformers',
      description: 'The url to the your data files'
    });
    this.defaultVariables.set('loaderFileName', {
      key: 'loaderFileName',
      value: 'dcupl.lc.json',
      description: 'The file name of the loader json'
    });
    this.defaultVariables.set('cdnVersion', {
      key: 'cdnVersion',
      value: undefined,
      description: 'The file-version of the CDN data'
    });
    this.defaultVariables.set('consoleApiUrl', {
      key: 'consoleApiUrl',
      value: 'https://api.dcupl.com',
      description: 'The dcupl console API Url'
    });
  }
  resources = {
    get: key => {
      return this.currentConfig?.resources.find(r => r.key === key);
    },
    getAll: () => {
      return this.currentConfig?.resources || [];
    },
    getWithTag: tag => {
      return this.currentConfig?.resources.filter(r => r.tags?.includes(tag)) || [];
    },
    set: resources => {
      this.currentConfig.resources = resources;
    },
    add: resource => {
      this.currentConfig.resources.push(resource);
    },
    delete: key => {
      const foundIndex = this.currentConfig.resources.findIndex(r => r.key === key);
      if (foundIndex > -1) {
        this.currentConfig.resources.splice(foundIndex, 1);
      }
    }
  };
  variables = {
    get: (key, env) => {
      const relevantEnv = this.currentConfig?.environments?.filter(e => e.key === env);
      const variables = this.evaluateVariables(relevantEnv, this.currentProcessOptions);
      return variables.find(v => v.key === key);
    },
    getAll: env => {
      const relevantEnv = this.currentConfig?.environments?.filter(e => e.key === env);
      const variables = this.evaluateVariables(relevantEnv, this.currentProcessOptions);
      return variables;
    },
    getAllAsObject: env => {
      const relevantEnv = this.currentConfig?.environments?.filter(e => e.key === env);
      const variables = this.getEvaluatedVariablesAsRecord(relevantEnv, this.currentProcessOptions);
      return variables;
    },
    default: {
      get: key => {
        return this.defaultVariables.get(key);
      },
      getAll: () => {
        return Array.from(this.defaultVariables.values());
      }
    },
    environment: {
      get: (env, key) => {
        if (!env) {
          return;
        }
        const relevantEnv = this.currentConfig?.environments?.find(e => e.key === env);
        if (relevantEnv) {
          return relevantEnv.variables?.find(v => v.key === key);
        }
        return;
      },
      getAll: env => {
        const relevantEnv = this.currentConfig?.environments?.find(e => e.key === env);
        return relevantEnv?.variables || [];
      }
    },
    process: {
      get: key => {
        return this.currentProcessOptions?.variables?.find(v => v.key === key);
      },
      getAll: () => {
        return this.currentProcessOptions?.variables || [];
      }
    },
    global: {
      get: key => {
        return this.globalVariables.get(key);
      },
      getAll: () => {
        return Array.from(this.globalVariables.values());
      },
      set: (key, value) => {
        this.globalVariables.set(key, {
          key,
          value
        });
      },
      delete: key => {
        this.globalVariables.delete(key);
      }
    }
  };
  transformers = {
    get: options => {
      return this.registeredTransformers.filter(t => intersection(t.applyTo, options.appyTo).length > 0);
    },
    getAll: () => {
      return this.registeredTransformers;
    },
    set: transformer => {
      this.transformers.remove(transformer.key);
      this.registeredTransformers.push(transformer);
    },
    remove: key => {
      const foundIndex = this.registeredTransformers.findIndex(t => t.key === key);
      if (foundIndex > -1) {
        this.registeredTransformers.splice(foundIndex, 1);
      }
    }
  };
  config = {
    get: () => {
      return cloneDeep(this.currentConfig);
    },
    set: config => {
      this.currentConfig = cloneDeep(config);
    },
    fetch: async options => {
      return this.fetchConfig(options);
    }
  };
  async fetchConfig(options) {
    if (!this.core) {
      console.error('dcupl not connected. Did you add your loader to dcupl? -> dcupl.addRunner(loader)');
      throw new Error();
    }
    let config;
    if (options?.baseUrl) {
      this.defaultVariables.set('baseUrl', {
        key: 'baseUrl',
        value: String(options.baseUrl)
      });
    }
    if (options?.loaderFileName) {
      this.defaultVariables.set('loaderFileName', {
        key: 'loaderFileName',
        value: String(options.loaderFileName)
      });
    }
    const baseUrl = this.variables.get('baseUrl')?.value;
    if (baseUrl && this.isDcuplCdn(baseUrl)) {
      await this.setCdnVersion();
      config = await this.loadConfigFromApi();
    } else {
      const loaderFileName = this.variables.get('loaderFileName')?.value;
      const url = `${baseUrl}/${loaderFileName}`;
      config = await this.loadConfigFromUrl(url, options?.headers);
    }
    if (this.isValidConfig(config)) {
      if (!options?.skipApply) {
        this.config.set(config);
      }
      return config;
    } else {
      throw new Error('Invalid Loader Configuration');
    }
  }
  isValidConfig(config) {
    if (config) {
      return true;
    }
    return false;
  }
  async loadConfigFromUrl(url, headers) {
    try {
      const options = {};
      if (headers) {
        options.headers = headers.reduce((acc, header) => {
          acc[header.key] = header.value;
          return acc;
        }, {});
      }
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`Failed to fetch loader configuration from ${url}`);
      }
      return response.json();
    } catch (err) {
      console.error('Could not fetch loader configuration');
      console.log(err);
      return;
    }
  }
  async loadConfigFromApi() {
    try {
      const baseUrl = this.variables.get('baseUrl')?.value;
      const loaderFileName = this.variables.get('loaderFileName')?.value;
      const cdnVersion = this.variables.get('cdnVersion')?.value;
      const apiKey = this.variables.get('apiKey')?.value;
      let url = `${baseUrl}/${loaderFileName}`;
      if (this.isDcuplCdn(url)) {
        if (cdnVersion) {
          url = this.appendSearchParamToUrl(url, 'v', String(cdnVersion));
        }
        if (apiKey) {
          url = this.appendSearchParamToUrl(url, 'api-key', apiKey);
        }
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch loader configuration from ${url}`);
      }
      return response.json();
    } catch (err) {
      console.error('Could not fetch loader configuration');
      console.error('Are you sure your projectId + apiKey are correct?');
      console.log(err);
      return;
    }
  }
  appendSearchParamToUrl(url, key, value) {
    // check if the url already has search params
    const hasSearchParam = url.includes('?');
    const separator = hasSearchParam ? '&' : '?';
    return `${url}${separator}${key}=${value}`;
  }
  async setCdnVersion() {
    try {
      const consoleApiUrl = this.variables.get('consoleApiUrl')?.value;
      const projectId = this.variables.get('projectId')?.value;
      const version = this.variables.get('version')?.value;
      const url = `${consoleApiUrl}/projects/${projectId}/files/versions/${version}/status`;
      const response = await fetch(url);
      const status = await response.json();
      if (status && status.changedAt) {
        this.defaultVariables.set('cdnVersion', {
          key: 'cdnVersion',
          value: String(status.changedAt)
        });
      }
    } catch (err) {
      console.error('Could not fetch version status');
    }
  }
  async process(_options) {
    if (!this.config) {
      throw new Error('There is no config present. Make sure to call loader.setConfig(..) or loader.fetchConfig(...)');
    }
    this.currentContext = generateKey();
    const options = cloneDeep(_options);
    const config = cloneDeep(this.currentConfig);
    if (options?.applicationKey) {
      const appPreset = this.getAppPreset(config, options);
      if (!appPreset) {
        throw new Error(`The applicationKey ${options.applicationKey} does not exist`);
      }
      options.resourceTags = options.resourceTags || appPreset?.resourceTags;
      options.variables = options.variables || appPreset?.variables;
    }
    const environments = this.getEnvironments(config, options);
    const variables = this.getEvaluatedVariablesAsRecord(environments, options);
    const headerConfigs = this.getHeaderConfigs(environments);
    const queryParamConfigs = this.getQueryParamConfigs(environments);
    const resources = this.getResourcesForConfigOptions(config, variables, options);
    const loaderOptions = this.getLoaderOptions(config, options);
    const result = await this.fetchResources(resources, variables, loaderOptions, headerConfigs, queryParamConfigs);
    this.currentProcessOptions = options;
    return result;
  }
  getHeaderConfigs(environments) {
    if (!environments) {
      return [];
    }
    const relevantHttpHeaderconfigs = [];
    environments.forEach(env => {
      if (env.headers) {
        relevantHttpHeaderconfigs.push(...env.headers);
      }
    });
    return relevantHttpHeaderconfigs;
  }
  getQueryParamConfigs(environments) {
    if (!environments) {
      return [];
    }
    const relevantQueryParamConfigs = [];
    environments.forEach(env => {
      if (env.queryParams) {
        relevantQueryParamConfigs.push(...env.queryParams);
      }
    });
    return relevantQueryParamConfigs;
  }
  getLoaderOptions(config, processOptions) {
    if (processOptions?.options) {
      return Object.assign({}, this.options, processOptions.options);
    }
    if (config?.options) {
      return Object.assign({}, this.options, config.options);
    }
    return Object.assign({}, this.options);
  }
  getResourcesForConfigOptions(config, variables, options) {
    const resources = [];
    // if the config does not contain resources, return empty array
    if (!config.resources) {
      return [];
    }
    // if no resourceTags are provided, return all resources
    if (!Array.isArray(options?.resourceTags) || !options?.resourceTags.length) {
      resources.push(...config.resources);
    } else {
      // if resourceTags are provided, filter the resources based on the options.resourceTags
      for (const resource of config.resources) {
        // if the resource has no tags, skip it
        if (!Array.isArray(resource.tags) || !resource.tags.length) {
          continue;
        }
        // if the resource has tags, check if the resourceTags are included in the resource.tags
        if (this.isValidResourceForTags(resource.tags, options.resourceTags)) {
          resources.push(resource);
        }
      }
    }
    return resources.map(resource => {
      if (variables) {
        const value = evaluateTemplate(resource.url, {
          ...variables
        });
        resource.url = value;
        return resource;
      } else {
        return resource;
      }
    });
  }
  isValidResourceForTags(resourceTags, optionTags) {
    for (const optionTag of optionTags) {
      if (Array.isArray(optionTag)) {
        if (optionTag.every(tag => resourceTags.includes(tag))) {
          return true;
        }
      } else {
        if (resourceTags.includes(optionTag)) {
          return true;
        }
      }
    }
    return false;
  }
  evaluateVariables(environments, options) {
    const variableMap = new Map();
    if (this.defaultVariables) {
      this.defaultVariables.forEach(variable => {
        variableMap.set(variable.key, variable);
      });
    }
    environments?.forEach(environment => {
      if (environment?.variables) {
        environment.variables.forEach(variable => {
          variableMap.set(variable.key, variable);
        });
      }
    });
    if (options?.variables) {
      options.variables.forEach(variable => {
        variableMap.set(variable.key, variable);
      });
    }
    if (this.globalVariables) {
      this.globalVariables.forEach(variable => {
        variableMap.set(variable.key, variable);
      });
    }
    const variablesAsArray = Array.from(variableMap.values());
    const variableAsMap = {};
    for (const variable of variablesAsArray) {
      variableAsMap[variable.key] = variable.value || '';
    }
    // evaluate variables in variables
    for (const variable of variablesAsArray) {
      variable.value = evaluateTemplate(variable.value, variableAsMap);
    }
    return variablesAsArray;
  }
  getEvaluatedVariablesAsRecord(environments, options) {
    const variables = this.evaluateVariables(environments, options);
    const variableAsMap = {};
    for (const variable of variables) {
      variableAsMap[variable.key] = variable.value || '';
    }
    return variableAsMap;
  }
  getEnvironments(config, options) {
    if (!config.environments || !config.environments[0]) {
      return;
    }
    if (options && options.environmentKeys && options.environmentKeys.length > 0) {
      return config.environments.filter(env => options.environmentKeys.indexOf(env.key) > -1);
    }
    return;
  }
  getAppPreset(config, options) {
    if (!config.applications || !config.applications[0]) {
      return;
    }
    if (options?.applicationKey) {
      return config.applications?.find(application => application.key === options.applicationKey);
    }
    return;
  }
  getHeaderForUrl(resource, variables, headerConfigs) {
    if (!headerConfigs || !headerConfigs.length) {
      return;
    }
    if (!resource.tags?.length) {
      return;
    }
    const relevantHeader = [];
    headerConfigs?.forEach(header => {
      if (!header.tags?.length) {
        if (this.isValidCondition(header, variables)) {
          relevantHeader.push(header);
        }
        return;
      }
      const foundHeader = header.tags.find(headerTag => resource.tags?.includes(headerTag));
      if (foundHeader) {
        if (this.isValidCondition(header, variables)) {
          relevantHeader.push(header);
        }
      }
    });
    const headerAsRecord = {};
    relevantHeader.forEach(header => {
      const value = evaluateTemplate(header.value, {
        ...variables
      });
      headerAsRecord[header.key] = value;
    });
    if (Object.keys(headerAsRecord).length === 0) {
      return;
    }
    return headerAsRecord;
  }
  getQueryParamsForUrl(resource, variables, queryParamConfig) {
    const relevantQueryParams = [];
    queryParamConfig?.forEach(queryParam => {
      if (!queryParam.tags?.length) {
        if (this.isValidCondition(queryParam, variables)) {
          relevantQueryParams.push(queryParam);
        }
        return;
      }
      const foundQueryParam = queryParam.tags.find(headerTag => resource.tags?.includes(headerTag));
      if (foundQueryParam) {
        if (this.isValidCondition(queryParam, variables)) {
          relevantQueryParams.push(queryParam);
        }
      }
    });
    if (this.isDcuplCdn(resource.url)) {
      const v = variables.cdnVersion;
      if (v) {
        relevantQueryParams.push({
          key: 'v',
          value: v
        });
      }
      if (variables.apiKey) {
        relevantQueryParams?.push({
          key: 'api-key',
          value: variables.apiKey
        });
      }
    }
    return relevantQueryParams.map(param => {
      param.value = evaluateTemplate(param.value, {
        ...variables
      });
      return param;
    });
  }
  isDcuplCdn(url) {
    return url.includes('cdn.dcupl.com');
  }
  isValidCondition(header, variables) {
    if (header.condition?.applyIfVariableHasValue) {
      if (typeof variables[header.condition?.applyIfVariableHasValue] !== 'undefined' && variables[header.condition?.applyIfVariableHasValue] !== '') {
        return true;
      }
      return false;
    }
    return true;
  }
  async handleScriptResources(resources, variables, headerConfigs, queryParamConfigs) {
    await Promise.all(resources.map(async resource => {
      try {
        const key = resource.scriptKey;
        if (this.core['scriptController'].hasScript(key)) {
          const progress = this.progressHandler.update(resource, true, 'script');
          this.updateProgressListeners(progress);
          return null;
        }
        const scriptContent = await this.fetchScript(resource, variables, headerConfigs, queryParamConfigs);
        this.core['scriptController'].setScript(key, scriptContent);
        const progress = this.progressHandler.update(resource, true, 'script');
        this.updateProgressListeners(progress);
        return;
      } catch (err) {
        const error = {
          title: resource.url,
          type: 'loader',
          errorGroup: 'ResourceError',
          errorType: 'InvalidResource',
          meta: resource,
          description: err?.message
        };
        this.setError(error);
        const progress = this.progressHandler.update(resource, false, 'script');
        this.updateProgressListeners(progress);
        return;
      }
    }));
    return true;
  }
  async handleOperatorResources(resources, variables, headerConfigs, queryParamConfigs) {
    await Promise.all(resources.map(async resource => {
      try {
        const key = resource.operator;
        if (this.core['queryManager']['registeredCustomOperator'].has(key)) {
          const progress = this.progressHandler.update(resource, true, 'operator');
          this.updateProgressListeners(progress);
          return null;
        }
        const operatorFn = await this.fetchJavascriptFile(resource, variables, headerConfigs, queryParamConfigs);
        this.core.query.registerCustomOperator(key, operatorFn);
        const progress = this.progressHandler.update(resource, true, 'operator');
        this.updateProgressListeners(progress);
        return;
      } catch (err) {
        const error = {
          title: resource.url,
          type: 'loader',
          errorGroup: 'ResourceError',
          errorType: 'InvalidResource',
          meta: resource,
          description: err?.message
        };
        this.setError(error);
        const progress = this.progressHandler.update(resource, false, 'operator');
        this.updateProgressListeners(progress);
        return;
      }
    }));
    return true;
  }
  async handleTransformerResources(resources, variables, headerConfigs, queryParamConfigs) {
    const relevantTransformers = [];
    await Promise.all(resources.map(async resource => {
      try {
        const alreadyLoadedTransformer = this.registeredTransformers.find(t => t.key === resource.url);
        if (alreadyLoadedTransformer) {
          relevantTransformers.push(alreadyLoadedTransformer);
          const progress = this.progressHandler.update(resource, true, 'transformer');
          this.updateProgressListeners(progress);
          return;
        }
        const transformerFn = await this.fetchJavascriptFile(resource, variables, headerConfigs, queryParamConfigs);
        const transformerConfigObject = {
          key: resource.url,
          applyTo: resource.applyTo || [],
          type: resource.transformerType || 'parsedFileTransformer',
          transformer: transformerFn
        };
        this.registeredTransformers.push(transformerConfigObject);
        relevantTransformers.push(transformerConfigObject);
        const progress = this.progressHandler.update(resource, true, 'transformer');
        this.updateProgressListeners(progress);
      } catch (err) {
        const error = {
          title: resource.url,
          type: 'loader',
          errorGroup: 'ResourceError',
          errorType: 'InvalidResource',
          meta: resource,
          description: err?.message
        };
        this.setError(error);
        const progress = this.progressHandler.update(resource, false, 'transformer');
        this.updateProgressListeners(progress);
      }
    }));
    return relevantTransformers;
  }
  getRequestOptions(resource, variables, headerConfigs, queryParamConfigs) {
    const headers = this.getHeaderForUrl(resource, variables, headerConfigs);
    const fetchQueryParams = this.getQueryParamsForUrl(resource, variables, queryParamConfigs);
    const url = this.appendQueryParamsToUrl(resource.url, fetchQueryParams);
    return {
      url,
      headers
    };
  }
  async fetchScript(resource, variables, headerConfigs, queryParamConfigs) {
    const {
      url,
      headers
    } = this.getRequestOptions(resource, variables, headerConfigs, queryParamConfigs);
    const response = await fetch(url, {
      headers
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${url}`);
    }
    const text = await response.text();
    return text;
  }
  async fetchJavascriptFile(resource, variables, headerConfigs, queryParamConfigs) {
    const {
      url,
      headers
    } = this.getRequestOptions(resource, variables, headerConfigs, queryParamConfigs);
    const response = await fetch(url, {
      headers
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch data from ${url}`);
    }
    const text = await response.text();
    const fn = new Function(text)();
    return fn;
  }
  async handleModelResources(resources, variables, headerConfigs, queryParamConfigs) {
    const modelDownloads = resources.map(async resource => {
      const start = new Date().getTime();
      try {
        const {
          url,
          headers
        } = this.getRequestOptions(resource, variables, headerConfigs, queryParamConfigs);
        const response = await fetch(url, {
          headers
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch data from ${url}`);
        }
        const startTimeParse = perf.now();
        const jsonResponse = await response.json();
        const endTimeParse = perf.now();
        let modelName = '';
        if (Array.isArray(jsonResponse)) {
          modelName = jsonResponse.map(definition => definition?.key).join('__');
        } else {
          modelName = jsonResponse.key;
        }
        const performanceEntry = perf.getEntriesByName(url.toString());
        if (Array.isArray(performanceEntry) && performanceEntry[0]) {
          const perfEntry = performanceEntry[0];
          const entry = {
            name: resource.url,
            type: 'resource',
            createdAt: new Date().getTime(),
            value: {
              model: modelName,
              type: 'model',
              start: start,
              startTime: perfEntry.startTime,
              requestStart: perfEntry.requestStart,
              responseEnd: perfEntry.responseEnd,
              responseStart: perfEntry.responseStart,
              duration: perfEntry.duration,
              parseEnd: endTimeParse,
              parseStart: startTimeParse,
              decodedBodySize: perfEntry.decodedBodySize,
              encodedBodySize: perfEntry.encodedBodySize,
              end: endTimeParse
            }
          };
          this.core['analyticsController'].set({
            value: entry,
            context: this.currentContext
          });
        }
        if (Array.isArray(jsonResponse)) {
          jsonResponse.map(responseEntry => this.addModelOrView(resource, responseEntry));
        } else {
          this.addModelOrView(resource, jsonResponse);
        }
        const progress = this.progressHandler.update(resource, true, 'model');
        this.updateProgressListeners(progress);
        return Promise.resolve(jsonResponse);
      } catch (err) {
        const error = {
          title: resource.url,
          type: 'loader',
          errorGroup: 'ResourceError',
          errorType: 'InvalidResource',
          meta: resource,
          description: err?.message
        };
        this.setError(error);
        const progress = this.progressHandler.update(resource, false, 'model');
        this.updateProgressListeners(progress);
        return Promise.resolve(null);
      }
    });
    await Promise.all(modelDownloads);
    return true;
  }
  appendQueryParamsToUrl(resourceUrl, fetchQueryParams) {
    // Check if the URL already has query parameters
    const hasQueryParams = resourceUrl.includes('?');
    let newUrl = resourceUrl;
    if (fetchQueryParams) {
      fetchQueryParams.forEach((param, index) => {
        // For the first parameter or if the URL already has query parameters, use '?', otherwise use '&'
        const separator = index === 0 && !hasQueryParams ? '?' : '&';
        newUrl += `${separator}${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`;
      });
    }
    return newUrl;
  }
  async handleDataResources(resources, variables, options, headerConfigs, queryParamConfigs, transformerObjects) {
    const dataDownloads = resources.map(async resource => {
      const containerUid = generateKey();
      const placeHolderContainer = {
        placeholderUid: containerUid,
        data: [],
        model: resource.model
      };
      this.core['registerContainer'](placeHolderContainer);
      const start = new Date().getTime();
      let httpStatus = undefined;
      try {
        const {
          url,
          headers
        } = this.getRequestOptions(resource, variables, headerConfigs, queryParamConfigs);
        const response = await fetch(url, {
          headers
        });
        httpStatus = response.status;
        if (!response.ok) {
          throw new Error(`Failed to fetch data from ${url}`);
        }
        const contentTypeResponseHeader = response.headers.get('content-type');
        const startTimeParse = perf.now();
        let data;
        if (resource.url.endsWith('.csv') || contentTypeResponseHeader?.includes('text/csv')) {
          data = await response.text();
        } else {
          data = await response.json();
        }
        const endTimeParse = perf.now();
        const startRawTransform = perf.now();
        const transformedData = this.handleTransformerLogic(data, resource, 'rawFileTransformer', transformerObjects || []);
        const endRawTransform = perf.now();
        let items = [];
        let modelDef = this.core['modelParser'].unprocessedModelDescriptions.get(resource.model);
        if (!modelDef && (options?.missingModelHandling?.autoGenerateProperties || resource.options?.autoGenerateProperties)) {
          this.addModelOrView(resource, {
            key: resource.model,
            autoGenerateProperties: true,
            autoGenerateKey: resource.options?.autoGenerateKey || false,
            keyProperty: resource?.options?.keyProperty || 'key'
          });
          modelDef = this.core['modelParser'].unprocessedModelDescriptions.get(resource.model);
        }
        const startCsvToJson = perf.now();
        if (resource.url.endsWith('.csv') || contentTypeResponseHeader?.includes('text/csv')) {
          if (modelDef) {
            items = this.csvParser.getJsonFromCSVString(transformedData, modelDef, resource);
          }
        } else {
          items = transformedData;
        }
        const endCsvToJson = perf.now();
        const startParsedTransform = perf.now();
        try {
          items = this.handleTransformerLogic(items, resource, 'parsedFileTransformer', transformerObjects || []);
        } catch (err) {
          console.log('Transformer Error');
          console.log(err);
        }
        const endParsedTransform = perf.now();
        const container = {
          placeholderUid: containerUid,
          model: resource.model,
          data: items,
          type: resource.options?.updateType || options.defaultDataContainerUpdateType
        };
        const performanceEntry = perf.getEntriesByName(url.toString());
        if (Array.isArray(performanceEntry) && performanceEntry[0]) {
          const perfEntry = performanceEntry[0];
          const entry = {
            name: resource.url,
            type: 'resource',
            createdAt: new Date().getTime(),
            value: {
              model: resource.model,
              type: 'data',
              start: start,
              startTime: perfEntry.startTime,
              requestStart: perfEntry.requestStart,
              responseEnd: perfEntry.responseEnd,
              responseStart: perfEntry.responseStart,
              duration: perfEntry.duration,
              parseEnd: endTimeParse,
              rawTransformStart: startRawTransform,
              rawTransformEnd: endRawTransform,
              parseStart: startTimeParse,
              csvToJsonStart: startCsvToJson,
              csvToJsonEnd: endCsvToJson,
              parsedTransformStart: startParsedTransform,
              parsedTransformEnd: endParsedTransform,
              decodedBodySize: perfEntry.decodedBodySize,
              encodedBodySize: perfEntry.encodedBodySize,
              end: endTimeParse
            }
          };
          this.core['analyticsController'].set({
            value: entry,
            context: this.currentContext
          });
        }
        this.core['updateRegisteredContainer'](container);
        const progress = this.progressHandler.update(resource, true, 'data', httpStatus);
        this.updateProgressListeners(progress);
        return Promise.resolve(container);
      } catch (err) {
        const error = {
          title: resource.url,
          type: 'loader',
          errorGroup: 'ResourceError',
          errorType: 'InvalidResource',
          meta: resource,
          model: resource.model,
          description: err?.message
        };
        this.setError(error);
        this.core['removeRegisteredContainer'](placeHolderContainer);
        const progress = this.progressHandler.update(resource, false, 'data', httpStatus);
        this.updateProgressListeners(progress);
        return Promise.resolve();
      }
    });
    await Promise.all(dataDownloads);
    return true;
  }
  async fetchResources(resources, variables, options, headerConfigs, queryParamConfigs) {
    this.progressHandler = new AppLoaderProgressHandler();
    this.progressHandler.init(resources);
    this.updateProgressListeners(this.progressHandler.getProgress());
    try {
      if (!this.core) {
        console.error('dcupl not connected. Did you add your loader to dcupl? -> dcupl.addRunner(loader)');
        throw new Error();
      }
      /**
       * 1) Fetch all scripts
       */
      const scripts = resources.filter(resource => resource.type === 'script');
      await this.handleScriptResources(scripts, variables, headerConfigs, queryParamConfigs);
      /**
       * 2) Fetch all operators
       */
      const operators = resources.filter(resource => resource.type === 'operator');
      await this.handleOperatorResources(operators, variables, headerConfigs, queryParamConfigs);
      /**
       * 3) Fetch all transformers
       */
      const transformers = resources.filter(resource => resource.type === 'transformer');
      const relevantTransformers = await this.handleTransformerResources(transformers, variables, headerConfigs, queryParamConfigs);
      /**
       * 4) Fetch all models
       */
      const models = resources.filter(resource => resource.type === 'model');
      await this.handleModelResources(models, variables, headerConfigs, queryParamConfigs);
      /**
       * 5) Fetch all data
       */
      const dataResources = resources.filter(resource => resource.type === 'data');
      await this.handleDataResources(dataResources, variables, options, headerConfigs, queryParamConfigs, relevantTransformers);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  addModelOrView(resource, modelOrView) {
    try {
      if ('model' in modelOrView) {
        this.core.views.set(modelOrView);
      } else {
        this.core.models.set(modelOrView);
      }
    } catch (err) {
      const error = {
        title: resource.url,
        type: 'model',
        errorGroup: 'ModelDefinitionError',
        errorType: 'InvalidModelDefinition',
        model: modelOrView.key,
        meta: resource,
        description: err?.message
      };
      this.setError(error);
    }
  }
  handleTransformerLogic(data, resource, type, transformers) {
    for (const transformerConfig of transformers) {
      if (transformerConfig.type !== type) {
        continue;
      }
      if (!transformerConfig.transformer) {
        continue;
      }
      if (!transformerConfig.applyTo.length && !resource.tags?.length) {
        data = transformerConfig.transformer(resource, data);
      }
      if (intersection(transformerConfig.applyTo, resource.tags || []).length) {
        data = transformerConfig.transformer(resource, data);
      }
    }
    return data;
  }
  updateProgressListeners(progress) {
    this.progressListeners.forEach(cb => cb(progress));
  }
  on(cb) {
    this.progressListeners.add(cb);
    return () => {
      this.progressListeners.delete(cb);
    };
  }
  setError(error) {
    if (this.core['modelParser'].qualityController.enabled) {
      this.core['modelParser'].qualityController.setError(error);
    }
  }
}
__decorate([trigger({
  scope: 'global',
  origin: 'loader',
  name: 'loader:process',
  type: 'loader_fetch_finished'
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Promise)], DcuplAppLoader.prototype, "process", null);
