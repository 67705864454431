import { generateObjectHash } from './object-hash';
export class IndicesController {
  indexMap = new Map();
  // Possible performance gain*
  dataMap = new Map();
  constructor() {}
  reset(attributeKey) {
    if (attributeKey) {
      this.indexMap.delete(attributeKey);
      this.dataMap.delete(attributeKey);
    } else {
      this.indexMap.clear();
      this.dataMap.clear();
    }
  }
  fillDataMap(attributeKey, attributeValue) {
    let valueMap = this.dataMap.get(attributeKey);
    if (!valueMap) {
      valueMap = new Map();
      this.dataMap.set(attributeKey, valueMap);
    }
    for (const value of attributeValue) {
      let keyAsString;
      if (typeof value === 'object' && value && value.key) {
        keyAsString = value.key;
      } else if (Array.isArray(value)) {
        for (const item of value) {
          this.fillDataMap(attributeKey, [item]);
        }
        return;
      } else {
        keyAsString = value;
      }
      let listItem = valueMap.get(keyAsString);
      if (!listItem) {
        listItem = {
          key: keyAsString
        };
        this.dataMap.get(attributeKey)?.set(keyAsString, listItem);
      }
    }
  }
  fillInverseIndex(attributeKey, originKey, foreignKeys) {
    if (!attributeKey || !originKey || !foreignKeys || !Array.isArray(foreignKeys)) {
      return;
    }
    const valueMap = this.indexMap.get(attributeKey) || new Map();
    for (const value of foreignKeys) {
      let keyAsString;
      if (typeof value === 'object' && value && value.key) {
        keyAsString = value.key;
      } else if (Array.isArray(value)) {
        // keyAsString = JSON.stringify(value);
        for (const item of value) {
          this.fillInverseIndex(attributeKey, originKey, [item]);
        }
        return;
      } else {
        keyAsString = value;
      }
      const valueArray = valueMap.get(keyAsString) || [];
      valueArray.push(originKey);
      valueMap.set(keyAsString, valueArray);
    }
    this.indexMap.set(attributeKey, valueMap);
  }
  getIndex(attributeKey, context) {
    const indexName = this.getIndexName(attributeKey, context);
    return this.indexMap.get(indexName);
  }
  getData(attributeKey, context) {
    const indexName = this.getIndexName(attributeKey, context);
    return this.dataMap.get(indexName);
  }
  getOrCreateIndex(attributeKey, relevantData, context) {
    const indexName = this.getIndexName(attributeKey, context);
    let index = this.getIndex(indexName);
    if (!index) {
      this.reset(indexName);
      this.indexMap.set(indexName, new Map());
      for (const entry of relevantData.values()) {
        const attributeValue = entry[attributeKey];
        this.fillInverseIndex(indexName, entry.key, [attributeValue]);
        this.fillDataMap(indexName, [attributeValue]);
      }
      index = this.getIndex(indexName) || new Map();
    }
    return index;
  }
  getIndexName(attributeKey, context) {
    return context ? attributeKey + '_' + generateObjectHash(context) : attributeKey;
  }
}
