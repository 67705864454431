export function computeSuggestions(data, index, options, queryManager) {
  if (!options.max) {
    options.max = 20;
  }
  const suggestions = [];
  const relevantSearchTerm = options.transform ? queryManager.transformValue(options.value, options.transform) : options.value;
  for (const [key, value] of index.entries()) {
    const isRelevantAttributeIndex = value.some(item => data.has(item));
    const relevantKey = options.transform ? queryManager.transformValue(key, options.transform) : key;
    if (options.excludeUndefineds && typeof key === 'undefined') {
      continue;
    }
    if (options.excludeNulls && key === null) {
      continue;
    }
    if (isRelevantAttributeIndex && (relevantSearchTerm === '' || relevantSearchTerm === '//')) {
      const suggestion = {
        value: key,
        keys: options.calculateResultKeys ? value : []
      };
      suggestions.push(suggestion);
    } else if (isRelevantAttributeIndex && queryManager.queryFind(relevantKey, relevantSearchTerm, 'some')) {
      const suggestion = {
        value: key,
        keys: options.calculateResultKeys ? value : []
      };
      suggestions.push(suggestion);
    }
    if (suggestions.length === options.max) {
      break;
    }
  }
  return suggestions;
}
