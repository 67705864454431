import { generateKey } from '@dcupl/common';
import { endsWith, groupBy, includes } from 'lodash-es';
export class QualityController {
  scriptsController;
  enabled = false;
  errors = new Map();
  values = {
    loading: 0,
    processing: 0,
    startup: 0,
    counts: {
      resources: 0,
      // todo
      modelFiles: 0,
      dataFiles: 0,
      models: 0,
      rows: 0,
      attributes: 0,
      dataEntries: 0
    },
    unusedAttributes: [],
    errors: {
      size: 0,
      UndefinedValue: 0,
      UndefinedAttribute: 0,
      WrongDataType: 0,
      MissingModel: 0,
      InvalidModelDescription: 0,
      RemoteReferenceKeyNotFound: 0
    },
    sizes: {
      totalEncoded: 0,
      totalDecoded: 0,
      unusedDecoded: 0,
      models: []
    }
  };
  modelParser;
  dcupl;
  constructor(scriptsController) {
    this.scriptsController = scriptsController;
  }
  setError(error) {
    if (!error.key) {
      error.key = generateKey();
    }
    this.errors.set(error.key, error);
  }
  removeError(key) {
    this.errors.delete(key);
  }
  getError(key) {
    return this.errors.get(key);
  }
  getErrors() {
    return Array.from(this.errors.values());
  }
  resetErrors() {
    this.errors.clear();
  }
  resetModelErrors() {
    this.getErrors().forEach(e => {
      if (e.type === 'model') {
        this.removeError(e.key);
      }
    });
  }
  cleanUpErrors(model, dataContainer, detail) {
    const modelErrors = this.getModelErrors(model.key);
    const relevantErrorsByType = modelErrors.filter(error => error.errorType === detail);
    if (relevantErrorsByType.length > 0) {
      const groupedByAttributes = groupBy(relevantErrorsByType, 'attribute');
      for (const attribute in groupedByAttributes) {
        const erroryByAttribute = groupedByAttributes[attribute];
        if (erroryByAttribute?.length === dataContainer.data.length) {
          for (const error of erroryByAttribute) {
            this.removeError(error.key);
          }
          const error = {
            title: 'Missing Data',
            type: 'model',
            errorGroup: 'DataContainerError',
            errorType: 'UndefinedAttribute',
            model: dataContainer.model,
            attribute: attribute,
            description: `The Attribute "${attribute}" is not present in the dataset for model "${dataContainer.model}"`
          };
          this.setError(error);
        }
      }
    }
  }
  getModelErrors(modelKey) {
    return this.getErrors().filter(e => e.type === 'model' && e.model === modelKey);
  }
  async initData() {
    this.modelParser?.addData({
      data: this.getErrors(),
      model: '$DcuplErrorTrackingErrors',
      type: 'set'
    });
    const container = this.modelParser['unprocessedData'].find(d => d.model === '$DcuplErrorTrackingErrors');
    await this.modelParser['handleDataContainer']([container]);
  }
  initErrorTrackingModels() {
    this.modelParser?.addModel({
      key: '$DcuplErrorTrackingErrors',
      autoGenerateKey: true,
      supportsAutoCreation: true,
      references: [{
        key: 'model',
        type: 'singleValued',
        model: '$DcuplErrorTrackingModel',
        filter: {
          calculateFacets: true
        }
      }, {
        key: 'errorGroup',
        type: 'singleValued',
        model: '$DcuplErrorTrackingGroup',
        filter: {
          calculateFacets: true
        }
      }, {
        key: 'errorType',
        type: 'singleValued',
        model: '$DcuplErrorTrackingType',
        filter: {
          calculateFacets: true
        }
      }],
      properties: [{
        key: 'title',
        type: 'string',
        filter: true
      }, {
        key: 'itemKey',
        type: 'string',
        filter: true
      }, {
        key: 'attribute',
        type: 'string',
        filter: true
      }, {
        key: 'description',
        type: 'string'
      }, {
        key: 'meta',
        type: 'json'
      }],
      quality: {
        enabled: false
      }
    });
    this.modelParser?.addModel({
      key: '$DcuplErrorTrackingType',
      data: [{
        key: 'UndefinedAttribute'
      }, {
        key: 'InvalidValidator'
      }, {
        key: 'UndefinedValue'
      }, {
        key: 'NullValue'
      }, {
        key: 'WrongDataType'
      }, {
        key: 'MissingModel'
      }, {
        key: 'RemoteReferenceKeyNotFound'
      }, {
        key: 'InvalidModelDescription'
      }, {
        key: 'InvalidResource'
      }, {
        key: 'InvalidModelDefinition'
      }],
      quality: {
        enabled: false
      }
    });
    this.modelParser?.addModel({
      key: '$DcuplErrorTrackingGroup',
      data: [{
        key: 'ReferenceDataError'
      }, {
        key: 'PropertyDataError'
      }, {
        key: 'DataContainerError'
      }, {
        key: 'ModelDescriptionError'
      }, {
        key: 'LoaderError'
      }, {
        key: 'ResourceError'
      }, {
        key: 'ModelDefinitionError'
      }],
      quality: {
        enabled: false
      }
    });
    this.modelParser?.addModel({
      key: '$DcuplErrorTrackingModel',
      supportsAutoCreation: true,
      quality: {
        enabled: false
      }
    });
  }
  initAnalyticsValues() {
    this.calculateTiming();
    this.calculateSizes();
    this.calculateErrorTrackingFacet();
    this.calculateCounts();
    this.calculateUnusedAttributes();
  }
  calculateUnusedAttributes() {
    this.values.unusedAttributes = [];
    const models = this.dcupl.models.keys();
    for (const modelKey of models) {
      const unusedAttributesForModel = this.getUnusedAttributesFromModel(this.dcupl, modelKey);
      for (const attr of unusedAttributesForModel) {
        this.values.unusedAttributes?.push({
          model: modelKey,
          attribute: attr
        });
      }
    }
  }
  calculateCounts() {
    this.values.counts = {};
    // const progress = this.progress.getValue();
    // this.values.counts.resources = progress?.total;
    this.values.counts.modelFiles = this.dcupl['modelParser'].unprocessedModelDescriptions.size;
    this.values.counts.dataFiles = this.dcupl['modelParser']['unprocessedData'].length;
    this.values.counts.models = this.dcupl.models.keys().length;
    let rows = 0;
    let attributes = 0;
    let values = 0;
    this.dcupl.models.keys().forEach(modelKey => {
      const model = this.dcupl['modelParser'].models.get(modelKey);
      if (!model) {
        return;
      }
      const properties = model?.getBasicProperties() || [];
      const references = model?.getBasicReferences() || [];
      const modelAttributes = properties.length + references.length;
      attributes += modelAttributes;
      const data = model['data'];
      const modelRows = data?.size || 0;
      rows += modelRows;
      values += modelAttributes * modelRows;
    });
    this.values.counts.rows = rows;
    this.values.counts.attributes = attributes;
    this.values.counts.dataEntries = values;
  }
  calculateTiming() {
    const loadingValue = this.dcupl['analyticsController'].getByName('loader:process');
    if (loadingValue && loadingValue[0]) {
      this.values.loading = loadingValue[0].value;
    }
    const processValue = this.dcupl['analyticsController'].getByName('dcupl:init');
    if (processValue && processValue[0]) {
      this.values.processing = processValue[0].value;
    }
    this.values.startup = this.values.loading + this.values.processing;
  }
  calculateSizes() {
    const sizes = this.calculateSizesForModels();
    this.values.sizes = {};
    this.values.sizes.totalEncoded = sizes.reduce((acc, item) => acc + item.encodedBodySize, 0);
    this.values.sizes.totalDecoded = sizes.reduce((acc, item) => acc + item.decodedBodySize, 0);
    this.values.sizes.unusedDecoded = this.calculateUnusedData();
    this.values.sizes.models = sizes.map(item => {
      return {
        model: item.model,
        decodedBodySize: item.decodedBodySize,
        encodedBodySize: item.encodedBodySize
      };
    });
    this.values.sizes.models?.sort((a, b) => b.encodedBodySize - a.encodedBodySize);
  }
  calculateErrorTrackingFacet() {
    try {
      const list = this.dcupl.lists.create({
        modelKey: '$DcuplErrorTrackingErrors'
      });
      const facet = list.catalog.fn.facets({
        attribute: 'errorType',
        calculateResults: true,
        excludeUndefineds: false,
        excludeUnresolved: false,
        excludeZeros: false
      });
      this.values.errors = {};
      if (facet) {
        this.values.errors.size = list.catalog.fn.metadata().currentSize;
        this.values.errors.UndefinedValue = facet.find(f => f.key === 'UndefinedValue')?.size;
        this.values.errors.UndefinedAttribute = facet.find(f => f.key === 'UndefinedAttribute')?.size;
        this.values.errors.WrongDataType = facet.find(f => f.key === 'WrongDataType')?.size;
        this.values.errors.MissingModel = facet.find(f => f.key === 'MissingModel')?.size;
        this.values.errors.InvalidModelDescription = facet.find(f => f.key === 'InvalidModelDescription')?.size;
        this.values.errors.RemoteReferenceKeyNotFound = facet.find(f => f.key === 'RemoteReferenceKeyNotFound')?.size;
      }
    } catch (err) {}
  }
  calculateSizesForModels() {
    const allProcessingData = this.dcupl['analyticsController'].getByType('resource');
    const onlyDataResources = allProcessingData.filter(item => item.value?.type === 'data');
    const groupedByModel = groupBy(onlyDataResources, item => item.value?.model);
    const summedUpByModel = Object.keys(groupedByModel).map(key => {
      const encodedBodySize = groupedByModel[key]?.reduce((acc, item) => {
        const size = item.value.encodedBodySize;
        return acc + size;
      }, 0);
      const decodedBodySize = groupedByModel[key]?.reduce((acc, item) => {
        const size = item.value.decodedBodySize;
        return acc + size;
      }, 0);
      return {
        model: key,
        decodedBodySize: decodedBodySize,
        encodedBodySize: encodedBodySize
      };
    });
    return summedUpByModel;
  }
  calculateUnusedData() {
    const models = this.dcupl.models.keys();
    // let totalLength = 0;
    let unusedDataLength = 0;
    models.forEach(modelKey => {
      const relevantData = this.dcupl['modelParser']['unprocessedData'].find(container => container.model === modelKey)?.data || [];
      const unusedAttributesForModel = this.getUnusedAttributesFromModel(this.dcupl, modelKey);
      // totalLength += JSON.stringify(relevantData).length;
      if (!unusedAttributesForModel.length) {
        return;
      }
      const unusedData = this.getDataWithAttributes(relevantData, unusedAttributesForModel);
      unusedDataLength += JSON.stringify(unusedData).length;
    });
    return unusedDataLength;
  }
  getDataWithAttributes(data, attribute) {
    return data.map(item => {
      const newItem = {};
      attribute.forEach(attr => {
        newItem[attr] = item[attr];
      });
      return newItem;
    });
  }
  getUnusedAttributesFromModel(dcupl, modelKey) {
    const model = dcupl['modelParser'].models.get(modelKey);
    const properties = model?.getBasicProperties() || [];
    const references = model?.getBasicReferences() || [];
    const keyProperty = model?.keyProperty || 'key';
    const attributes = [...properties, ...references].map(a => a.key);
    attributes.push(keyProperty);
    attributes.push('key');
    const relevantData = dcupl['modelParser']['unprocessedData'].find(container => container.model === modelKey)?.data || [];
    return this.getUnusedAttributes(relevantData, attributes);
  }
  getUnusedAttributes(data, attributes) {
    const unusedAttributes = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (!attributes.includes(key)) {
          unusedAttributes.add(key);
        }
      });
    });
    return Array.from(unusedAttributes.values());
  }
  validateAttribute(value, validators, attribute) {
    const validationErrors = [];
    // if (validators.custom) {
    //   const error = this.validateCustom(value, validators.custom, attribute);
    //   if (error) {
    //     validationErrors.push(error);
    //   }
    // }
    if (validators.email) {
      const error = this.validateEmail(value, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.endsWith) {
      const error = this.validateEndsWith(value, validators.endsWith, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.enum) {
      const error = this.validateEnum(value, validators.enum, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.includes) {
      const error = this.validateIncludes(value, validators.includes, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.max) {
      const error = this.validateMax(value, validators.max, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.maxLength) {
      const error = this.validateMaxLength(value, validators.maxLength, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.min) {
      const error = this.validateMin(value, validators.min, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.minLength) {
      const error = this.validateMinLength(value, validators.minLength, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.pattern) {
      const error = this.validatePattern(value, validators.pattern, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    if (validators.startsWith) {
      const error = this.validateStartsWith(value, validators.startsWith, attribute);
      if (error) {
        validationErrors.push(error);
      }
    }
    return validationErrors;
  }
  validateAttributesAfterInitialization(model) {
    const attributes = model.getAttributes();
    const data = model.getDataAsArray();
    const errors = [];
    for (const attribute of attributes) {
      const validators = attribute.quality?.validators;
      if (validators) {
        if (validators.unique) {
          const uniqueErrors = this.validateUnique(data, attribute.key, model);
          errors.push(...uniqueErrors);
        }
      }
    }
    return errors;
  }
  validateUnique(data, attribute, model) {
    const uniqueErrors = [];
    const uniqueValues = new Set();
    for (const item of data) {
      const value = item[attribute];
      if (uniqueValues.has(value)) {
        delete item[attribute];
        const error = {
          title: 'Validator Error - Unique',
          type: 'model',
          errorGroup: 'PropertyDataError',
          model: model.key,
          errorType: 'InvalidValidator',
          attribute: attribute,
          description: `The value "${value}" is not unique`
        };
        uniqueErrors.push(error);
      } else {
        uniqueValues.add(value);
      }
    }
    return uniqueErrors;
  }
  // private validateCustom(
  //   value: string,
  //   config: CustomValidatorConfig,
  //   attribute: string,
  // ): QualityAnalyzer.InternalModelError | undefined {
  //   const customScript = this.scriptsController.getScript(config.key);
  //   if (!customScript) {
  //     console.log('Custom Validator script not found');
  //   }
  //   const result = this.scriptsController.executeScript(config.key, 'validator', { value, config, attribute });
  //   if (result !== true) {
  //     return {
  //       title: 'Validator Error - Custom Script',
  //       type: 'model',
  //       errorGroup: 'PropertyDataError',
  //       errorType: 'InvalidValidator',
  //       attribute: attribute,
  //       description: `The value "${value}" does not pass the custom validation`,
  //       meta: result,
  //     };
  //   }
  //   return;
  // }
  validateEmail(value, attribute) {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(value)) {
      return {
        title: 'Validator Error - Invalid Email',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" is not a valid email address`
      };
    }
    return undefined;
  }
  validateMin(value, config, attribute) {
    if (value < config.value) {
      return {
        title: 'Validator Error - Min Value',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" is smaller than the minimum value of "${config.value}"`
      };
    }
    return undefined;
  }
  validateMax(value, config, attribute) {
    if (value > config.value) {
      return {
        title: 'Validator Error - Max Value',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" is larger than the maximum value of "${config.value}"`
      };
    }
    return undefined;
  }
  validateStartsWith(value, config, attribute) {
    if (!value.startsWith(config.value)) {
      return {
        title: 'Validator Error - StartsWith',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" does not start with "${config.value}"`
      };
    }
    return undefined;
  }
  validateEndsWith(value, config, attribute) {
    if (!endsWith(value, config.value)) {
      return {
        title: 'Validator Error - EndsWith',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" does not end with "${config.value}"`
      };
    }
    return undefined;
  }
  validateIncludes(value, config, attribute) {
    if (!includes(value, config.value)) {
      return {
        title: 'Validator Error - Includes',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" does not include "${config.value}"`
      };
    }
    return undefined;
  }
  validateMinLength(value, config, attribute) {
    if (value.length < config.value) {
      return {
        title: 'Validator Error - Min Length',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" is shorter than the minimum length of "${config.value}"`
      };
    }
    return undefined;
  }
  validateMaxLength(value, config, attribute) {
    if (value.length > config.value) {
      return {
        title: 'Validator Error - Max Length',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" is longer than the maximum length of "${config.value}"`
      };
    }
    return undefined;
  }
  validateEnum(value, config, attribute) {
    if (!config.value.includes(value)) {
      return {
        title: 'Validator Error - Enum',
        type: 'model',
        errorGroup: 'PropertyDataError',
        errorType: 'InvalidValidator',
        attribute: attribute,
        description: `The value "${value}" is not in the enum values`
      };
    }
    return undefined;
  }
  validatePattern(value, config, attribute) {
    try {
      const pattern = new RegExp(config.value);
      if (!pattern.test(value)) {
        return {
          title: 'Validator Error - Pattern',
          type: 'model',
          errorGroup: 'PropertyDataError',
          errorType: 'InvalidValidator',
          attribute: attribute,
          description: `The value "${value}" does not match the pattern "${config.value}"`
        };
      }
    } catch (err) {
      console.log('invalid pattern', err);
    }
    return undefined;
  }
}
