import { getAggregation } from './getAggregation';
import { IndicesController } from './indices.controller';
const pivotPathSeparator = '__';
export function pivot(relevantData, options, response = {
  key: 'root'
}, parentPath = 'root', indicesCtrl = new IndicesController()) {
  for (const column of options.columns) {
    if (column.attribute) {
      indicesCtrl.getOrCreateIndex(column.attribute, relevantData);
    }
  }
  for (const row of options.rows) {
    if (row.attribute) {
      indicesCtrl.getOrCreateIndex(row.attribute, relevantData);
    }
  }
  for (const value of options.values) {
    if (value.attribute) {
      indicesCtrl.getOrCreateIndex(value.attribute, relevantData);
    }
  }
  /**
   * Rows
   */
  if (options.rows && options.rows.length > 0) {
    response.rows = response.rows || [];
    const item = getPivotRowItem(relevantData, options.rows[0], options, indicesCtrl, parentPath);
    response.rows.push(item);
  }
  /**
   * Columns
   */
  if (options.columns && options.columns.length > 0) {
    response.columns = response.columns || [];
    const item = getPivotColumnItem(relevantData, options.columns[0], options, indicesCtrl, parentPath);
    response.columns.push(item);
  }
  /**
   * Value Aggregations
   */
  if (response.key === 'root') {
    for (const value of options.values) {
      response.values = response.values || [];
      if (parentPath === 'root' && options.options?.calculateTotals === false) {
        continue;
      }
      const agg = getAggregation(value, relevantData, indicesCtrl.getIndex(value.attribute), value.attribute);
      response.values.push(agg);
    }
  }
  return response;
}
function getPivotRowItem(modelData, options, globalOptions, modelIndexMap, parentPath = '') {
  const item = {
    key: options.attribute
  };
  const indexMap = modelIndexMap.getIndex(options.attribute);
  if (!indexMap) {
    return item;
  }
  for (const [key, itemKeys] of indexMap.entries()) {
    const rowItem = {
      key: key
    };
    const relevantData = new Map();
    for (const key of itemKeys) {
      if (!modelData.has(key)) {
        continue;
      }
      relevantData.set(key, modelData.get(key));
    }
    if (!relevantData.size) continue;
    for (const value of globalOptions.values) {
      rowItem.values = rowItem.values || [];
      if (parentPath === 'root' && options?.calculateTotals === false) {
        continue;
      }
      const agg = getAggregation(value, relevantData, modelIndexMap.getIndex(value.attribute), value.attribute);
      rowItem.values.push(agg);
    }
    item.rows = item.rows || [];
    item.rows.push(rowItem);
    if (globalOptions.rows.length > 1) {
      const newOptions = {
        ...globalOptions,
        rows: globalOptions.rows.slice(1)
      };
      pivot(relevantData, newOptions, rowItem, parentPath + pivotPathSeparator + options.attribute + pivotPathSeparator + key);
    } else {
      if (globalOptions.columns) {
        for (const columnOption of globalOptions.columns) {
          rowItem.columns = rowItem.columns || [];
          const columnItem = getPivotColumnItem(relevantData, columnOption, globalOptions, modelIndexMap, parentPath + pivotPathSeparator + options.attribute + pivotPathSeparator + key);
          rowItem.columns.push(columnItem);
        }
      }
    }
  }
  return item;
}
function getPivotColumnItem(modelData, options, globalOptions, modelIndexMap, parentPath = '') {
  const item = {
    key: options.attribute
  };
  const indexMap = modelIndexMap.getIndex(options.attribute);
  if (!indexMap) {
    return item;
  }
  for (const [key, itemKeys] of indexMap.entries()) {
    const rowItem = {
      key: key
    };
    const relevantData = new Map();
    for (const key of itemKeys) {
      if (!modelData.has(key)) {
        continue;
      }
      relevantData.set(key, modelData.get(key));
    }
    if (!relevantData.size) continue;
    for (const value of globalOptions.values) {
      rowItem.values = rowItem.values || [];
      if (parentPath === 'root' && options?.calculateTotals === false) {
        continue;
      }
      const agg = getAggregation(value, relevantData, modelIndexMap.getIndex(value.attribute), value.attribute);
      rowItem.values.push(agg);
    }
    item.columns = item.columns || [];
    item.columns.push(rowItem);
    if (globalOptions.columns.length > 1) {
      const newOptions = {
        ...globalOptions,
        columns: globalOptions.columns.slice(1)
      };
      pivot(relevantData, newOptions, rowItem, parentPath + pivotPathSeparator + options.attribute + pivotPathSeparator + key);
    }
  }
  return item;
}
