import { generateObjectHash } from './object-hash';
import { perf } from './performance';
export class AnalyticsController {
  data;
  enabled = false;
  constructor() {}
  init(enabled) {
    this.data = new Map();
    this.enabled = enabled;
  }
  set(options) {
    if (!this.enabled) return options.value;
    const uniqueKey = this.getKey(options.value.name, options.context);
    this.data.set(uniqueKey, options.value);
    return this.data.get(uniqueKey);
  }
  mark(options) {
    if (!this.enabled) return undefined;
    const uniqueKey = this.getKey(options.name, options.context);
    const entry = {
      name: options.name,
      type: 'mark',
      value: perf.now(),
      createdAt: Date.now()
    };
    this.data.set(uniqueKey, entry);
    return entry;
  }
  measure(options) {
    if (!this.enabled) return undefined;
    const uniqueKey = this.getKey(options.name, options.context);
    const startMark = this.data.get(this.getKey(options.start, options.context));
    const endMark = this.data.get(this.getKey(options.end, options.context));
    if (!startMark || !endMark) {
      return undefined;
    }
    const duration = endMark.value - startMark.value;
    const roundedDuration = Math.round(duration * 100) / 100;
    const existingEntry = this.data.get(uniqueKey);
    if (existingEntry && options.sum) {
      const newDuration = existingEntry.value + roundedDuration;
      const newEntry = {
        ...existingEntry,
        value: newDuration
      };
      this.data.set(uniqueKey, newEntry);
      return newEntry;
    }
    const entry = {
      name: options.name,
      type: 'measure',
      value: roundedDuration,
      createdAt: Date.now()
    };
    if (options.detail) {
      entry.detail = options.detail;
    }
    this.data.set(uniqueKey, entry);
    return entry;
  }
  update(options) {
    if (!this.enabled) return options.value;
    const uniqueKey = this.getKey(options.value.name, options.context);
    const currentItem = this.data.get(uniqueKey);
    if (currentItem) {
      const newItem = {
        ...currentItem,
        ...options.value
      };
      this.data.set(uniqueKey, newItem);
    } else {
      this.data.set(uniqueKey, options.value);
    }
    return this.data.get(uniqueKey);
  }
  getResourceInfosFromPerformanceAPI() {
    if (typeof performance === 'undefined') return [];
    const entries = perf.getEntriesByType('resource');
    return entries;
  }
  get(options) {
    const uniqueKey = this.getKey(options.name, options.context);
    return this.data.get(uniqueKey);
  }
  getByType(type) {
    return Array.from(this.data.values()).filter(entry => entry.type === type);
  }
  getByName(name) {
    return Array.from(this.data.values()).filter(entry => entry.name === name);
  }
  clear() {
    this.data.clear();
  }
  getKey(key, context) {
    if (!context) {
      return key;
    }
    const hash = generateObjectHash(context);
    return key + '_' + hash;
  }
}
