import { DcuplCsvParser } from './dcupl-csv-parser';
export class CsvParser {
  loader;
  constructor(loader) {
    this.loader = loader;
  }
  getParserConfig(modelDef, resourceOptions) {
    const loaderOptions = this.loader.config.get()?.options;
    const defaultOptions = this.loader['options'];
    const options = {};
    if (typeof resourceOptions?.csvParserOptions?.quoteChar !== 'undefined') {
      options.quoteChar = resourceOptions?.csvParserOptions?.quoteChar;
    } else if (typeof loaderOptions?.csvParserOptions?.quoteChar !== 'undefined') {
      options.quoteChar = loaderOptions?.csvParserOptions?.quoteChar;
    } else {
      options.quoteChar = defaultOptions.csvParserOptions?.quoteChar;
    }
    if (typeof resourceOptions?.csvParserOptions?.delimiter !== 'undefined') {
      options.delimiter = resourceOptions?.csvParserOptions?.delimiter;
    } else if (typeof loaderOptions?.csvParserOptions?.delimiter !== 'undefined') {
      options.delimiter = loaderOptions?.csvParserOptions?.delimiter;
    } else {
      options.delimiter = defaultOptions.csvParserOptions?.delimiter;
    }
    if (typeof resourceOptions?.csvParserOptions?.escapeChar !== 'undefined') {
      options.escapeChar = resourceOptions?.csvParserOptions?.escapeChar;
    } else if (typeof loaderOptions?.csvParserOptions?.escapeChar !== 'undefined') {
      options.escapeChar = loaderOptions?.csvParserOptions?.escapeChar;
    } else {
      options.escapeChar = defaultOptions.csvParserOptions?.escapeChar;
    }
    const config = {
      delimiter: options?.delimiter,
      quoteChar: options?.quoteChar,
      escapeChar: options?.escapeChar,
      comments: '#',
      dynamicTyping: false,
      header: true,
      skipEmptyLines: true,
      transformHeader: h => h?.trim().replace(/"/g, ''),
      transform: function (value, header) {
        if (value === 'null') {
          return null;
        }
        if (value === '') {
          return undefined;
        }
        if (typeof value === 'undefined') {
          return undefined;
        }
        const reference = modelDef.references?.find(reference => reference.key === header || reference.origin === header);
        if (modelDef.keyProperty && modelDef.keyProperty === header) {
          return value;
        }
        if (!modelDef.keyProperty && header === 'key') {
          return value;
        }
        if (reference) {
          if (value[0] === '{' || value[0] === '[') {
            try {
              return JSON.parse(value);
            } catch (err) {
              console.log(err);
              return;
            }
          } else if (reference.type === 'singleValued') {
            return value;
          } else {
            if (value?.length > 0 && reference.type === 'multiValued') {
              return value?.split(',')?.map(val => val?.trim());
            } else {
              return value;
            }
          }
        }
        const property = modelDef.properties?.find(prop => prop.key === header || prop.origin === header);
        if (!modelDef.autoGenerateProperties && !property) {
          return;
        }
        switch (property?.type) {
          case 'string':
            return value;
          case 'int':
            const val = parseInt(value, 10);
            if (isNaN(val)) {
              return;
            }
            return val;
          case 'float':
            const val2 = parseFloat(value);
            if (isNaN(val2)) {
              return;
            }
            return val2;
          case 'boolean':
            if (value === 'true') {
              return true;
            }
            if (value === 'false') {
              return false;
            }
            return;
          case 'json':
            try {
              // escape Bad control character in string literal
              value = value.replace(/[\x00-\x1F\x7F-\x9F]/g, '');
              return JSON.parse(value);
            } catch (err) {
              console.log(err);
              return undefined;
            }
          case 'Array<string>':
          case 'Array<int>':
          case 'Array<float>':
          case 'Array<date>':
            if (value && typeof value === 'string' && value[0] === '[') {
              try {
                return JSON.parse(value);
              } catch (err) {
                return;
              }
            }
            if (value && typeof value === 'string') {
              return value?.split(',')?.map(val => val?.trim());
            }
            return;
          default:
            return value;
        }
        return value;
      }
    };
    return config;
  }
  getJsonFromCSVString(csv, modelDef, resource) {
    const config = this.getParserConfig(modelDef, resource.options);
    const parser = new DcuplCsvParser();
    const parsedData = parser.parse(csv, config);
    return parsedData;
  }
}
