function getScalingFactor(numbers) {
  let maxDecimalPlaces = 0;
  for (let num of numbers) {
    const decimalPart = num.toString().split('.')[1];
    if (decimalPart) {
      maxDecimalPlaces = Math.max(maxDecimalPlaces, decimalPart.length);
    }
  }
  return Math.pow(10, maxDecimalPlaces);
}
function add(a, b, factor) {
  return (a * factor + b * factor) / factor;
}
function divide(a, b, factor) {
  return a * factor / (b * factor);
}
export const getAggregation = (options, relevantData, indexMap, attribute) => {
  if (!attribute) {
    throw new Error('Attribute not found');
  }
  const aggregation = {
    attribute: attribute,
    types: options.types
  };
  let numberAgg;
  if (aggregation.types.includes('sum') || aggregation.types.includes('avg') || aggregation.types.includes('count')) {
    numberAgg = getNumberAggregation(attribute, relevantData);
  }
  let minMax;
  if (aggregation.types.includes('min') || aggregation.types.includes('max')) {
    minMax = getMinMax(options.attribute, relevantData);
  }
  options.types.forEach(type => {
    if (type === 'sum') {
      aggregation.sum = numberAgg?.sum;
    } else if (type === 'avg') {
      aggregation.avg = numberAgg?.average;
    } else if (type === 'count') {
      aggregation.count = numberAgg?.count;
    } else if (type === 'min') {
      aggregation.min = minMax && minMax[0];
    } else if (type === 'max') {
      aggregation.max = minMax && minMax[1];
    } else if (type === 'distinct') {
      aggregation.distinct = getDistinct(options, relevantData, indexMap);
    } else if (type === 'group') {
      aggregation.group = getGroups(options, relevantData);
    }
  });
  return aggregation;
};
const getMinMax = (attribute, data) => {
  let minV = undefined;
  let maxV = undefined;
  for (const item of data.values()) {
    if (item) {
      const value = item[attribute];
      if (minV === undefined || value < minV) minV = value;
      if (maxV === undefined || value > maxV) maxV = value;
    }
  }
  if (minV === undefined || maxV === undefined) {
    return undefined;
  }
  return [minV, maxV];
};
const getGroups = (options, data) => {
  const values = [];
  options.groupOptions?.groups?.forEach(group => {
    values.push({
      option: group,
      count: 0,
      keys: []
    });
  });
  const notFound = {
    option: {
      value: ['$dcupl_not_found']
    },
    count: 0,
    keys: []
  };
  for (const item of data.values()) {
    const relevantEntry = item[options.attribute];
    for (const group of values) {
      if (typeof group.option.value !== 'undefined' && group.option.value.includes(relevantEntry) || typeof group.option.from !== 'undefined' && group.option.from < relevantEntry && typeof group.option.to !== 'undefined' && group.option.to > relevantEntry) {
        group.count++;
        group.keys.push(item.key);
      } else {
        if (options?.groupOptions?.calculateNotFound) {
          notFound.count++;
          notFound.keys.push(item.key);
        }
      }
    }
  }
  if (options?.groupOptions?.calculateNotFound) {
    values.push(notFound);
  }
  return values;
};
const getNumberAggregation = (attribute, data) => {
  // let sum = new Decimal(0);
  let sum = 0;
  let count = 0;
  for (const item of data.values()) {
    if (!item) continue;
    const value = item[attribute];
    if (Array.isArray(value)) {
      for (const val of value) {
        if (typeof val === 'number') {
          // sum = sum.add(val);
          sum = add(sum, val, getScalingFactor([sum, val]));
          count++;
        }
      }
    } else if (typeof value === 'number') {
      // sum = sum.add(value);
      sum = add(sum, value, getScalingFactor([sum, value]));
      count++;
    }
  }
  if (count === 0) {
    return undefined;
  }
  // const average = sum.dividedBy(count).toNumber();
  const average = divide(sum, count, getScalingFactor([sum, count]));
  const agg = {
    average,
    count: data.size,
    sum: sum
  };
  return agg;
};
export const getDistinct = (options, data, indexMap) => {
  const values = [];
  for (const [attributeValue, itemKeys] of indexMap) {
    if (options.excludeUndefineds && typeof attributeValue === 'undefined') {
      continue;
    }
    if (itemKeys.find(key => data.has(key))) {
      const value = {
        value: attributeValue,
        count: itemKeys.length
      };
      if (!options.distinctOptions?.skipKeys) {
        value.keys = itemKeys;
      }
      values.push(value);
      if (options.distinctOptions?.limit && values.length >= options.distinctOptions.limit) {
        break;
      }
    }
  }
  return values;
};
