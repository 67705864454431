import { parseTemplate } from '@dcupl/common';
const getModelError = (title, attribute, description) => {
  return {
    title: title,
    type: 'model',
    errorGroup: 'ModelDefinitionError',
    errorType: 'InvalidModelDefinition',
    attribute: attribute,
    description: description
  };
};
/**
 * Validates a property
 */
export const isValidProperty = property => {
  const validDatatypes = ['any', 'Array<date>', 'Array<float>', 'Array<int>', 'Array<string>', 'boolean', 'date', 'float', 'int', 'json', 'string'];
  if (!property.key) {
    return getModelError('Invalid Property', property.key, 'Property key is missing');
  }
  if (!property.type || validDatatypes.indexOf(property.type) === -1) {
    return getModelError('Invalid Property', property.key, 'Property type is invalid');
  }
  if ('derive' in property) {
    if (!property.derive?.localReference) {
      return getModelError('Invalid Property', property.key, 'Derived Property localReference is missing');
    }
    if (!property.derive.remoteProperty) {
      return getModelError('Invalid Property', property.key, 'Derived Property remoteProperty is missing');
    }
  }
  if ('expression' in property) {
    if (typeof property.expression !== 'string') {
      return getModelError('Invalid Property', property.key, 'Expression is invalid');
    }
  }
  return;
};
/**
 * Validates a reference
 */
export const isValidReference = reference => {
  const validReferenceTypes = ['multiValued', 'singleValued'];
  if (!reference.key) {
    return getModelError('Invalid Reference', reference.key, 'Reference key is missing');
  }
  if (!reference.type || validReferenceTypes.indexOf(reference.type) === -1) {
    return getModelError('Invalid Reference', reference.key, 'Reference type is invalid');
  }
  if (!reference.model) {
    return getModelError('Invalid Reference', reference.key, 'Reference model is missing');
  }
  if ('derive' in reference) {
    if (!reference.derive.localReference) {
      return getModelError('Invalid Reference', reference.key, 'Derived Reference localReference is missing');
    }
    if (!reference.derive.remoteReference) {
      return getModelError('Invalid Reference', reference.key, 'Derived Reference remoteReference is missing');
    }
  }
  if ('resolve' in reference) {
    if (!reference.resolve.reference) {
      return getModelError('Invalid Reference', reference.key, 'Resolved Reference reference is missing');
    }
    if (!reference.resolve.model) {
      return getModelError('Invalid Reference', reference.key, 'Resolved Reference model is missing');
    }
  }
  if ('groupBy' in reference) {
    if (!reference.groupBy.reference) {
      return getModelError('Invalid Reference', reference.key, 'Grouped Reference reference is missing');
    }
  }
  return;
};
export const validateBasicModelStructure = model => {
  if (!model.key) {
    throw new Error('Invalid Model: key is missing');
  }
  if (typeof model.keyProperty !== 'undefined' && typeof model.keyProperty !== 'string') {
    throw new Error('Invalid Model: keyProperty must be a string');
  }
  if (typeof model.autoGenerateKey !== 'undefined' && typeof model.autoGenerateKey !== 'boolean') {
    throw new Error('Invalid Model: autoGenerateKey must be a boolean');
  }
  if (typeof model.autoGenerateProperties !== 'undefined' && typeof model.autoGenerateProperties !== 'boolean') {
    throw new Error('Invalid Model: autoGenerateProperties must be a boolean');
  }
  if (model.properties && !Array.isArray(model.properties)) {
    throw new Error('Invalid Model: properties must be an array');
  }
  if (model.references && !Array.isArray(model.references)) {
    throw new Error('Invalid Model: references must be an array');
  }
  return true;
};
export const validateModels = (model, allModels) => {
  const errors = [];
  model.references.forEach(ref => {
    if (!allModels.has(ref.model)) {
      errors.push({
        title: 'Missing Model',
        type: 'model',
        errorGroup: 'ModelDefinitionError',
        errorType: 'MissingModel',
        attribute: ref.key,
        model: model.key,
        description: `Referenced model ${ref.model} does not exist`
      });
    }
    if ('derive' in ref) {
      errors.push(...validateDerivedReference(ref, model, allModels));
    }
    if ('resolve' in ref) {
      errors.push(...validateResolvedReference(ref, model, allModels));
    }
    if ('groupBy' in ref) {
      errors.push(...validateGroupedReference(ref, model));
    }
  });
  model.properties.forEach(prop => {
    if ('derive' in prop) {
      errors.push(...validateDerivedProperty(prop, model, allModels));
    }
    if ('expression' in prop) {
      errors.push(...validateExpressionProperty(prop, model));
    }
  });
  return errors;
};
const validateDerivedReference = (ref, model, allModels) => {
  const errors = [];
  const localRef = model.references.get(ref.derive.localReference);
  if (!localRef) {
    errors.push({
      title: 'Missing Reference',
      type: 'model',
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingReference',
      attribute: ref.key,
      model: model.key,
      description: `Local Referenced ${ref.derive.localReference} does not exist in model ${model.key}`
    });
    return errors;
  }
  const remoteModel = allModels.get(localRef.model);
  if (remoteModel) {
    const remoteReferenece = remoteModel.references.get(ref.derive.remoteReference);
    if (!remoteReferenece) {
      errors.push({
        title: 'Missing Reference',
        type: 'model',
        errorGroup: 'ModelDefinitionError',
        errorType: 'MissingReference',
        attribute: ref.key,
        model: model.key,
        description: `Remote Reference ${ref.derive.remoteReference} does not exist in model ${remoteModel.key}`
      });
    }
  }
  return errors;
};
const validateResolvedReference = (ref, model, allModels) => {
  const errors = [];
  const remoteModel = allModels.get(ref.resolve.model);
  if (!remoteModel) {
    errors.push({
      title: 'Missing Model',
      type: 'model',
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingModel',
      attribute: ref.key,
      model: model.key,
      description: `Referenced model ${ref.model} does not exist`
    });
    return errors;
  }
  const remoteReferenece = remoteModel.references.get(ref.resolve.reference);
  if (!remoteReferenece) {
    errors.push({
      title: 'Missing Reference',
      type: 'model',
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingReference',
      attribute: ref.key,
      model: model.key,
      description: `Resolved Reference ${ref.resolve.reference} does not exist in model ${remoteModel.key}`
    });
  }
  return errors;
};
const validateGroupedReference = (ref, model) => {
  const errors = [];
  const remoteReferenece = model.references.get(ref.groupBy.reference);
  if (!remoteReferenece) {
    errors.push({
      title: 'Missing Reference',
      type: 'model',
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingReference',
      attribute: ref.key,
      model: model.key,
      description: `Resolved Reference ${ref.groupBy.reference} does not exist in model ${model.key}`
    });
  }
  return errors;
};
const validateDerivedProperty = (prop, model, allModels) => {
  const errors = [];
  const localRef = model.references.get(prop.derive.localReference);
  if (!localRef) {
    errors.push({
      title: 'Missing Reference',
      type: 'model',
      errorGroup: 'ModelDefinitionError',
      errorType: 'MissingReference',
      attribute: prop.key,
      model: model.key,
      description: `Local Referenced ${prop.derive.localReference} does not exist in model ${model.key}`
    });
    return errors;
  }
  const remoteModel = allModels.get(localRef.model);
  if (remoteModel) {
    if (prop.derive.remoteProperty === 'key') {
      return errors;
    }
    const remoteProperty = remoteModel.properties.get(prop.derive.remoteProperty);
    if (!remoteProperty) {
      errors.push({
        title: 'Missing Property',
        type: 'model',
        errorGroup: 'ModelDefinitionError',
        errorType: 'MissingProperty',
        attribute: prop.key,
        model: model.key,
        description: `Remote Property ${prop.derive.remoteProperty} does not exist in model ${remoteModel.key}`
      });
    }
  }
  return errors;
};
const validateExpressionProperty = (prop, model) => {
  const errors = [];
  const template = parseTemplate(prop.expression);
  template.variables.forEach(templateVar => {
    if (templateVar.name === 'key' || templateVar.name.endsWith('.key')) {
      return;
    }
    if (!model.properties.get(templateVar.name) && !model.references.get(templateVar.name)) {
      errors.push({
        title: 'Unknown Expression Variable',
        type: 'model',
        errorGroup: 'ModelDefinitionError',
        errorType: 'UnknownExpressionVariable',
        attribute: prop.key,
        model: model.key,
        description: `Expression Variable ${templateVar.name} not found in expression ${prop.key} in ${model.key}`
      });
    }
  });
  return errors;
};
