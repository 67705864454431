export const getFilterEntryId = (filterItemId, value = '') => {
  return `${filterItemId}#${value}`;
};
export const getNumberConstraintUid = options => {
  const value = `${options.gt}_${options.gte}_${options.lt}_${options.lte}_${options.eq}_${options.ne}`;
  return value;
};
export const getDateConstraintUid = options => {
  const value = `${options.gt}_${options.gte}_${options.lt}_${options.lte}_${options.eq}_${options.ne}`;
  return value;
};
export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : o => o[predicate];
  return [...arr.reduce((map, item) => {
    const key = item === null || item === undefined ? item : cb(item);
    map.has(key) || map.set(key, item);
    return map;
  }, new Map()).values()];
};
export const groupBy = (arr, criteria) => {
  return arr.reduce(function (obj, item) {
    // Check if the criteria is a function to run on the item or a property of it
    const key = typeof criteria === 'function' ? criteria(item) : item[criteria];
    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }
    // Push the value to the object
    obj[key].push(item);
    // Return the object to the next item in the loop
    return obj;
  }, {});
};
export function generateKey(length = 10) {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}
export const removeTrailingSlash = str => {
  return str.replace(/\/+$/, '');
};
export const escapeRegExp = text => {
  if (typeof text !== 'string') return '';
  return text.replace(/[[\]{}()*+?.,\\^$|#]/g, '\\$&');
  /**
   * original regex
   * remove \s -
   */
  // return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};
export const unescapeRegExp = escapedText => {
  if (typeof escapedText !== 'string') return '';
  return escapedText.replace(/\\([[\]{}()*+?.,\\^$|#])/g, '$1');
};
