import { max as dateMax, min as dateMin } from 'date-fns';
import { min, max } from 'lodash-es';
import { DcuplQueryBuilder, getFacets, getAggregation, CacheController } from '@dcupl/common';
export class Filter {
  modelParser;
  cdRef;
  item;
  indicesController;
  queryManager;
  initialData;
  filteredData;
  model;
  filterItems = new Map();
  queryBuilder;
  cacheCtrl;
  constructor(modelParser, cdRef, item, indicesController, queryManager) {
    this.modelParser = modelParser;
    this.cdRef = cdRef;
    this.item = item;
    this.indicesController = indicesController;
    this.queryManager = queryManager;
    this.cacheCtrl = new CacheController(!!this.cdRef.dcuplInitOptions.performance?.cache?.enabled);
  }
  init(model, data) {
    this.model = model;
    this.initialData = data;
    this.filteredData = this.initialData;
    this.queryBuilder = new DcuplQueryBuilder();
    this.queryBuilder.init({
      modelKey: model.key
    });
    this.initFilterItems({
      skipProcessing: true
    });
  }
  update(model, data, indicesController) {
    this.model = model;
    this.initialData = data;
    this.indicesController = indicesController;
    this.cacheCtrl.clear();
    this.filteredData = this.getFilteredData(this.initialData, false);
    this.initFilterItems({
      skipProcessing: true
    });
  }
  resetCache() {
    this.cacheCtrl.clear();
  }
  initFilterItems(options) {
    const attributes = [...this.model.properties.values(), ...this.model.references.values()];
    attributes.forEach(attribute => {
      if (attribute.filter) {
        if (Array.isArray(options?.filterKeys)) {
          if (options?.filterKeys.includes(attribute.key)) {
            this.updateFilterItem(attribute.key, options);
          }
        } else {
          this.updateFilterItem(attribute.key, options);
        }
      }
    });
  }
  getAggregate(options, relevantData, indexMap) {
    const attribute = this.model.getAttribute(options.attribute);
    if (!attribute) {
      throw new Error('Attribute not found');
    }
    return getAggregation(options, relevantData, indexMap, attribute.key);
  }
  getSections(options) {
    const response = {
      _meta: {
        currentSize: 0,
        initialSize: 0
      },
      items: []
    };
    const attribute = this.model.getAttribute(options.attribute);
    if (!attribute) {
      throw new Error('Attribute not found');
    }
    let sectionItems = new Map();
    let remoteModel;
    if (attribute.type === 'singleValued' || attribute.type === 'multiValued') {
      remoteModel = this.modelParser.models.get(attribute.model);
    }
    const childLevelOptions = {};
    const childLevelSorting = this.getSortingProjectionForModel(options, this.model);
    childLevelOptions.sort = childLevelSorting;
    // 1) PREPARE INITIAL DATA
    // get the filteredData only SORTED
    const relevantData = this.item.getItemsAsMap(this.filteredData, this.model, childLevelOptions);
    const indexMap = this.indicesController.getOrCreateIndex(`${options.attribute}`, relevantData, {
      childLevelSorting,
      query: this.queryBuilder.getQuery()
    });
    // 2) get the distinct values for the attribute
    // todo distinctoptions
    const agg = this.getAggregate({
      attribute: options.attribute,
      types: ['distinct'],
      excludeUndefineds: true
    }, relevantData, indexMap);
    if (agg.distinct) {
      for (const distinctAggValue of agg.distinct) {
        let item = {
          key: distinctAggValue.value
        };
        const values = distinctAggValue.keys.filter(key => relevantData.has(key));
        if (remoteModel) {
          const remoteItem = remoteModel['data'].get(distinctAggValue.value);
          if (remoteItem) {
            item = remoteItem;
          }
        }
        const sectionItem = {
          ...item,
          key: distinctAggValue.value,
          _items: [],
          _aggregates: [],
          _meta: {
            size: values.length
          }
        };
        sectionItems.set(distinctAggValue.value, sectionItem);
      }
    }
    // // 3) Page, Sort and project the section data
    const topLevelOptions = {
      count: options.count,
      start: options.start,
      projection: options.projection
    };
    if (remoteModel) {
      const topLevelSorting = this.getSortingProjectionForModel(options, remoteModel);
      topLevelOptions.sort = topLevelSorting;
    }
    response._meta.currentSize = sectionItems.size;
    response._meta.initialSize = agg.distinct.length;
    sectionItems = this.item.getItemsAsMap(sectionItems, remoteModel, topLevelOptions);
    if (Array.isArray(options?.aggregates)) {
      sectionItems.forEach(sectionItem => {
        const initialAggregate = agg.distinct.find(d => d.value === sectionItem.key);
        const filteredData = this.getDataByKeys(relevantData, initialAggregate.keys);
        sectionItem._aggregates = options.aggregates.map(aggOption => {
          return this.getAggregate(aggOption, filteredData, indexMap);
        });
      });
    }
    if (options?.items) {
      const wildCardItem = options.items?.find(i => i.key === '*');
      if (wildCardItem) {
        sectionItems.forEach(sectionItem => {
          const initialAggregate = agg.distinct.find(d => d.value === sectionItem.key);
          const filteredData = this.getDataByKeys(relevantData, initialAggregate.keys);
          const items = this.item.getItems(filteredData, this.model, wildCardItem);
          sectionItem._items = items;
        });
      } else {
        sectionItems.forEach(sectionItem => {
          const optionItem = options.items?.find(i => i.key === sectionItem.key);
          if (optionItem) {
            const initialAggregate = agg.distinct.find(d => d.value === sectionItem.key);
            const filteredData = this.getDataByKeys(relevantData, initialAggregate.keys);
            const items = this.item.getItems(filteredData, this.model, optionItem);
            sectionItem._items = items;
          }
        });
      }
    }
    response.items = Array.from(sectionItems.values());
    return response;
  }
  getSortingProjectionForModel(options, model) {
    const topLevelSorting = {
      attributes: [],
      order: []
    };
    options?.sort?.attributes.forEach((attribute, idx) => {
      if (model.properties.has(attribute)) {
        topLevelSorting.attributes.push(attribute);
        const orders = options.sort?.order;
        topLevelSorting.order.push(orders[idx]);
      }
    });
    return topLevelSorting;
  }
  getReferenceKeyForFilterKey(filterKey) {
    const filterItem = this.filterItems.get(filterKey);
    return filterItem?.attributeKey || filterItem?.key || filterKey;
  }
  updateFilterItem(filterKey, options = {}) {
    const defaultOptions = {
      calculateFacets: false,
      excludeZeros: true,
      excludeUndefineds: true,
      excludeUnresolved: true,
      skipProcessing: false
    };
    const referenceKey = this.getReferenceKeyForFilterKey(filterKey);
    if (!referenceKey) {
      return;
    }
    const reference = this.model.references.get(referenceKey);
    let relevantOptions = {};
    if (typeof reference?.filter === 'object') {
      relevantOptions = Object.assign(defaultOptions, reference.filter, options);
    } else {
      relevantOptions = Object.assign(defaultOptions, options);
    }
    if (reference) {
      this.updateFilterItemReference(reference.key, reference.key, relevantOptions);
      return;
    }
    const property = this.model.properties.get(referenceKey);
    if (property) {
      if (Array.isArray(property.filter)) {
        property.filter.forEach(filter => {
          const comparisonType = filter?.comparisonType || 'containsIgnoreCase';
          this.updateFilterItemProperty(filter.key, property.key, property.type, comparisonType, relevantOptions);
        });
        return;
      } else {
        const comparisonType = property.filter?.comparisonType || 'containsIgnoreCase';
        this.updateFilterItemProperty(property.key, property.key, property.type, comparisonType, relevantOptions);
        return;
      }
    }
    if (filterKey === 'key') {
      const comparisonType = 'containsIgnoreCase';
      this.updateFilterItemProperty('key', 'key', 'string', comparisonType, relevantOptions);
      return;
    }
  }
  updateFilterItemReference(filterKey, attributeKey, options) {
    let entries = [];
    const reference = this.model.references.get(attributeKey);
    if (reference && !options.skipProcessing) {
      const remoteModel = this.modelParser.models.get(reference.model);
      entries = this.getFacets(reference.key, remoteModel, options);
    }
    const filterItem = {
      key: filterKey,
      attributeKey: attributeKey,
      enabled: !!entries.find(entry => entry.enabled),
      selected: !!entries.find(entry => entry.selected),
      type: 'reference',
      entries: entries
    };
    this.filterItems.set(filterItem.key, filterItem);
  }
  updateFilterItemProperty(filterKey, attributeKey, type, comparisonType, options) {
    const filterType = this.getFilterTypeForPropertyType(type);
    const hasFilterEntry = this.queryBuilder.hasQueryGroup(filterKey);
    const filterItem = {
      key: filterKey,
      attributeKey: attributeKey,
      enabled: true,
      selected: !!hasFilterEntry,
      type: filterType
    };
    if (filterItem.type === 'text') {
      filterItem.comparisonType = comparisonType;
    }
    if (filterItem.type === 'boolean') {
      const entries = this.getFacets(filterItem.attributeKey, undefined, options);
      filterItem.entries = entries;
    }
    if (!options.skipProcessing) {
      if (filterItem.type === 'number') {
        filterItem.range = this.getNumberRangesForFilterItem(filterItem);
      }
      if (filterItem.type === 'date') {
        filterItem.range = this.getDateRangesForFilterItem(filterItem);
      }
    }
    this.filterItems.set(filterItem.key, filterItem);
  }
  getNumberRange(propertyKey, filterKey = propertyKey) {
    const filteredData = Array.from(this.filteredData.values());
    const filteredValues = filteredData.map(item => item[propertyKey]).filter(item => typeof item !== 'undefined' && item !== null);
    const flatFilteredValues = filteredValues.flat();
    const filteredMin = min(flatFilteredValues);
    const filteredMax = max(flatFilteredValues);
    const initialData = Array.from(this.initialData.values());
    const initialValues = initialData.map(item => item[propertyKey]).filter(item => typeof item !== 'undefined' && item !== null);
    const flatInitialValues = initialValues.flat();
    const initialMin = min(flatInitialValues);
    const initialMax = max(flatInitialValues);
    const ranges = {
      available: {
        min: filteredMin,
        max: filteredMax
      },
      initial: {
        min: initialMin,
        max: initialMax
      },
      relatedQuery: this.queryBuilder.getQueryGroup(filterKey)
    };
    return ranges;
  }
  getNumberRangesForFilterItem(filterItem) {
    const ranges = this.getNumberRange(filterItem.attributeKey, filterItem.key);
    return ranges;
  }
  getDateRangesForFilterItem(filterItem) {
    const ranges = this.getDateRange(filterItem.attributeKey, filterItem.key);
    return ranges;
  }
  getDateRange(propertyKey, filterKey = propertyKey) {
    const filteredData = Array.from(this.filteredData.values());
    const filteredValues = filteredData.map(item => item[propertyKey]).filter(item => typeof item !== 'undefined' && item !== null);
    const flatFilteredValues = filteredValues.flat();
    const filteredMin = dateMin(flatFilteredValues);
    const filteredMax = dateMax(flatFilteredValues);
    const initialData = Array.from(this.initialData.values());
    const initialValues = initialData.map(item => item[propertyKey]).filter(item => typeof item !== 'undefined' && item !== null);
    const flatInitialValues = initialValues.flat();
    const initialMin = dateMin(flatInitialValues);
    const initialMax = dateMax(flatInitialValues);
    const ranges = {
      available: {
        min: filteredMin,
        max: filteredMax
      },
      initial: {
        min: initialMin,
        max: initialMax
      },
      relatedQuery: this.queryBuilder.getQueryGroup(filterKey)
    };
    return ranges;
  }
  getFacets(attributeKey, remoteModel, options) {
    let indexMap;
    const cacheContext = this.cacheCtrl.generateContext({
      attributeKey,
      query: this.queryBuilder.getQuery(),
      options
    });
    const cachedValue = this.cacheCtrl.get('facets', cacheContext);
    if (cachedValue) {
      return cachedValue;
    }
    let remoteData = new Map();
    indexMap = this.indicesController.getIndex(attributeKey);
    if (remoteModel) {
      remoteData = remoteModel.getData();
    } else {
      remoteData = this.indicesController.getData(attributeKey);
    }
    // Autogenerate index if not present
    if (!indexMap || !remoteData) {
      this.indicesController.reset(attributeKey);
      this.initialData.forEach(entry => {
        const attributeValue = entry[attributeKey];
        this.indicesController.fillInverseIndex(attributeKey, entry.key, [attributeValue]);
        this.indicesController.fillDataMap(attributeKey, [attributeValue]);
      });
      indexMap = this.indicesController.getIndex(attributeKey);
      remoteData = this.indicesController.getData(attributeKey);
    }
    const relevantModelData = this.initialData;
    const facets = getFacets(relevantModelData, remoteData, options, this.queryBuilder.getQuery(), attributeKey, this.indicesController['indexMap'], this.queryManager);
    this.cacheCtrl.set('facets', cacheContext, facets);
    return facets;
  }
  getDataByKeys(data, itemKeys) {
    if (itemKeys) {
      const filteredData = new Map();
      itemKeys.forEach(key => {
        const entry = data.get(key);
        if (entry) {
          filteredData.set(entry.key, entry);
        }
      });
      return filteredData;
    }
    return data;
  }
  getFilterTypeForPropertyType(type) {
    switch (type) {
      case 'string':
        return 'text';
      case 'int':
        return 'number';
      case 'float':
        return 'number';
      case 'date':
        return 'date';
      case 'boolean':
        return 'boolean';
      case 'json':
        return 'text';
      case 'any':
        return 'text';
      case 'Array<string>':
        return 'text';
      case 'Array<int>':
        return 'number';
      case 'Array<float>':
        return 'number';
      case 'Array<date>':
        return 'date';
    }
  }
  updateFilteredData(options) {
    if (!options?.skipProcessing) {
      this.filteredData = this.getFilteredData(this.initialData, false);
      this.cdRef.trigger({
        scope: 'list',
        name: 'list:result:updated',
        type: 'result_updated',
        action: 'update'
      });
    }
  }
  getFilteredData(initialData, earlyReturn) {
    const cacheContext = this.cacheCtrl.generateContext({
      query: this.queryBuilder.getQuery(),
      earlyReturn
    });
    const cachedValue = this.cacheCtrl.get('data', cacheContext);
    if (cachedValue) {
      return cachedValue;
    }
    const query = this.queryBuilder.getQuery();
    const value = this.queryManager.queryData(initialData, query, earlyReturn, this.indicesController['indexMap']);
    this.cacheCtrl.set('data', cacheContext, value);
    return value;
  }
  getFilterItems(options) {
    if (Array.isArray(options?.filterKeys)) {
      const items = [];
      options?.filterKeys.forEach(filterKey => {
        const item = this.getFilterItem(filterKey, options);
        if (item) {
          items.push(item);
        }
      });
      return items;
    } else {
      if (!options?.skipProcessing) {
        this.initFilterItems(options);
      }
      return Array.from(this.filterItems.values());
    }
  }
  getFilterItem(filterItemId, options) {
    if (this.filterItems.has(filterItemId)) {
      if (!options?.skipProcessing) {
        this.updateFilterItem(filterItemId, options);
      }
    }
    return this.filterItems.get(filterItemId);
  }
}
