var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { ItemService } from '../catalog';
import { ModelParser } from '../model-parser';
import { ChangeDetector, trigger, DcuplQueryBuilder, generateKey, AnalyticsController, QueryManager, CacheController, ScriptController } from '@dcupl/common';
import { ListsController } from './dcupl-list-controller';
import { merge } from 'lodash-es';
import { QualityController } from '../quality/quality.controller';
export class DcuplApi {
  cdRef = new ChangeDetector();
  analyticsController = new AnalyticsController();
  scriptController = new ScriptController();
  qualityController = new QualityController(this.scriptController);
  modelParser = new ModelParser(this.cdRef, this.qualityController);
  globalItemService = new ItemService(this.modelParser, this.scriptController);
  cacheCtrl = new CacheController(false);
  queryManager = new QueryManager(this.scriptController);
  _loaders = new Map();
  rootQueryBuilder = new DcuplQueryBuilder();
  _defaultInitOptions = {
    config: {
      projectId: undefined,
      apiKey: undefined,
      version: 'draft'
    },
    errorTracking: {
      enabled: false
    },
    quality: {
      enabled: true
    },
    analytics: {
      enabled: false
    },
    performance: {
      cache: {
        enabled: true
      },
      clone: {
        enabled: true
      }
    },
    referenceMetadata: {
      enabled: false
    }
  };
  initOptions;
  lists = new ListsController(this);
  constructor(options) {
    this.analyticsController.init(!!options?.analytics?.enabled);
    this.qualityController.dcupl = this;
    this.analyticsController.mark({
      name: 'dcupl:constructor'
    });
    this.initOptions = merge({}, this._defaultInitOptions, options);
    this.qualityController.enabled = this.initOptions.errorTracking?.enabled || this.initOptions.quality?.enabled || false;
    this.modelParser.initOptions = Object.assign({}, this.initOptions);
    this.cdRef._init({
      cdRefKey: 'root',
      dcuplInitOptions: this.initOptions,
      analyticsController: this.analyticsController
    });
    this.cacheCtrl.enabled = !!this.initOptions.performance?.cache?.enabled;
  }
  models = {
    get: modelKey => {
      return this.modelParser.models.get(modelKey)?.getModelDefinition();
    },
    set: model => {
      this.modelParser.models.delete(model.key);
      return this.modelParser.addModel(model);
    },
    update: model => {
      return this.modelParser.addModel(model);
    },
    remove: modelKey => {
      this.modelParser.models.delete(modelKey);
    },
    keys: () => {
      return this.modelParser['_getModelKeys']();
    }
  };
  views = {
    get: viewKey => {
      return this.modelParser.views.get(viewKey);
    },
    keys: options => {
      return this.modelParser['_getViewKeys'](options);
    },
    set: view => {
      return this.modelParser.addView(view);
    }
  };
  data = {
    update: (data, options) => {
      this.modelParser.addData({
        data,
        model: options.model,
        type: 'update',
        keyProperty: options.keyProperty,
        autoGenerateKey: options.autoGenerateKey
      });
    },
    upsert: (data, options) => {
      this.modelParser.addData({
        data,
        model: options.model,
        type: 'upsert',
        keyProperty: options.keyProperty,
        autoGenerateKey: options.autoGenerateKey
      });
    },
    set: (data, options) => {
      this.modelParser.addData({
        data,
        model: options.model,
        type: 'set',
        keyProperty: options.keyProperty,
        autoGenerateKey: options.autoGenerateKey
      });
    },
    remove: (data, options) => {
      this.modelParser.addData({
        data,
        model: options.model,
        type: 'remove',
        keyProperty: options.keyProperty,
        autoGenerateKey: options.autoGenerateKey
      });
    },
    reset: options => {
      if (options.model) {
        this.modelParser.models.get(options.model)?.resetData();
        this.modelParser.models.get(options.model)?.resetIndexMap();
      }
    },
    apply: container => {
      return this.modelParser.addData(container);
    }
  };
  loaders = {
    add: (loader, key) => {
      this.addLoader(loader, key);
    },
    remove: key => {
      this.removeLoader(key);
    },
    get: key => {
      return this._loaders.get(key);
    }
  };
  scripts = {
    set: (key, script) => {
      this.scriptController.setScript(key, script);
    },
    remove: key => {
      this.scriptController.removeScript(key);
    },
    get: key => {
      this.scriptController.getScript(key);
    },
    keys: () => {
      return this.scriptController.keys();
    },
    execute: (key, options) => {
      const args = {
        dcupl: this,
        options
      };
      return this.scriptController.executeScript(key, 'script', args);
    },
    executeAsync: (key, options) => {
      return new Promise((resolve, reject) => {
        const args = {
          dcupl: this,
          options,
          resolve,
          reject
        };
        return this.scriptController.executeScript(key, 'async_script', args);
      });
    }
  };
  fn = {
    pivot: options => {
      const list = this.getFnList(options);
      const result = list.catalog.fn.pivot(options.options);
      return result;
    },
    aggregate: options => {
      const list = this.getFnList(options);
      const result = list.catalog.fn.aggregate(options.options);
      return result;
    },
    suggest: options => {
      const list = this.getFnList(options);
      const result = list.catalog.fn.suggest(options.options);
      return result;
    },
    groupBy: options => {
      const list = this.getFnList(options);
      const result = list.catalog.fn.groupBy(options.options);
      return result;
    },
    facets: options => {
      const list = this.getFnList(options);
      const result = list.catalog.fn.facets(options.options);
      return result;
    },
    metadata: options => {
      const list = this.getFnList(options);
      const result = list.catalog.fn.metadata();
      return result;
    }
  };
  registerContainer(container) {
    this.modelParser.addData(container);
  }
  updateRegisteredContainer(container) {
    const relevantContainer = this.modelParser['unprocessedData'].findIndex(c => c.placeholderUid === container.placeholderUid);
    if (relevantContainer > -1) {
      delete container.placeholderUid;
      this.modelParser['unprocessedData'][relevantContainer] = container;
    }
  }
  removeRegisteredContainer(container) {
    const relevantContainer = this.modelParser['unprocessedData'].findIndex(c => c.placeholderUid === container.placeholderUid);
    if (relevantContainer > -1) {
      this.modelParser['unprocessedData'].splice(relevantContainer, 1);
    }
  }
  getFnList(options) {
    let list = this.lists.get(`__global_list__${options.modelKey}`);
    if (!list) {
      list = this.lists.create({
        modelKey: options.modelKey,
        listKey: `__global_list__${options.modelKey}`
      });
    }
    if (options.query) {
      list.catalog.query.apply(options.query, {
        mode: 'set'
      });
    } else {
      list.catalog.query.reset();
    }
    return list;
  }
  updateFnLists() {
    for (const list of this.lists.getAll()) {
      if (list.key.startsWith('__global_list__')) {
        list.update();
      }
    }
  }
  query = {
    one: options => {
      return this.getItem(options);
    },
    many: options => {
      return this._getManyItems(options);
    },
    execute: options => {
      return this.executeQuery(options);
    },
    generate: (modelKey, query) => {
      this.rootQueryBuilder.init({
        modelKey: modelKey
      });
      return this.rootQueryBuilder.applyQuery(query);
    },
    registerCustomOperator: (operator, fn) => {
      this.queryManager.registerCustomOperator(operator, fn);
    }
  };
  on(cb) {
    return this.cdRef.on(cb);
  }
  async init() {
    try {
      await this.modelParser.init();
      this.cacheCtrl.clear();
      return true;
    } catch (err) {
      return false;
    }
  }
  async update() {
    try {
      await this.modelParser.update();
      this.cacheCtrl.clear();
      this.updateFnLists();
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  destroy() {
    try {
      this.lists.destroy();
      this._loaders.forEach(loader => {
        loader.destroy();
      });
    } catch (err) {}
  }
  getItem(options) {
    const model = this.modelParser.models.get(options.modelKey);
    if (!model) {
      throw new Error();
    }
    return this.globalItemService.getItem(options.itemKey, model['data'], model, options);
  }
  _getManyItems(options) {
    const model = this.modelParser.models.get(options.modelKey);
    if (!model) {
      throw new Error();
    }
    const items = this.globalItemService.getManyItems(options.itemKeys, model['data'], model, options);
    return items;
  }
  executeQuery(options) {
    if (!options?.modelKey) {
      throw new Error(`No modelKey provided`);
    }
    const model = this.modelParser.models.get(options?.modelKey);
    if (!model) {
      throw new Error(`Model not found: ${options.modelKey}`);
    }
    const cacheContext = this.cacheCtrl.generateContext({
      options
    });
    const hasCache = this.cacheCtrl.has('query', cacheContext);
    if (hasCache) {
      return this.cacheCtrl.get('query', cacheContext);
    }
    const queriedData = this.queryManager.queryData(model['data'], options, false, model.indicesController['indexMap']);
    const items = this.globalItemService.getItems(queriedData, model, options);
    this.cacheCtrl.set('query', cacheContext, items);
    return items;
  }
  addLoader(loader, key) {
    const loaderKey = key || generateKey();
    this._loaders.set(loaderKey, loader);
    loader.core = this;
    loader.key = loaderKey;
    const loaderCd = new ChangeDetector();
    loaderCd._init({
      cdRefKey: loaderKey,
      parentCd: this.cdRef,
      dcuplInitOptions: this.initOptions,
      analyticsController: this.analyticsController
    });
    loader.cdRef = loaderCd;
    if (this.initOptions.config?.projectId) {
      loader.defaultVariables.set('projectId', {
        key: 'projectId',
        value: this.initOptions.config?.projectId
      });
    }
    if (this.initOptions.config?.apiKey) {
      loader.defaultVariables.set('apiKey', {
        key: 'apiKey',
        value: this.initOptions.config?.apiKey
      });
    }
    if (this.initOptions.config?.version) {
      loader.defaultVariables.set('version', {
        key: 'version',
        value: this.initOptions.config?.version
      });
    }
  }
  removeLoader(key) {
    const loader = this._loaders.get(key);
    if (loader) {
      loader.destroy();
    }
    this._loaders.delete(key);
  }
}
__decorate([trigger({
  scope: 'global',
  name: 'dcupl:init',
  type: 'dcupl_initialized'
}), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", Promise)], DcuplApi.prototype, "init", null);
__decorate([trigger({
  scope: 'global',
  name: 'dcupl:update',
  type: 'dcupl_updated_manually',
  action: 'update'
}), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", Promise)], DcuplApi.prototype, "update", null);
__decorate([trigger({
  scope: 'global',
  name: 'dcupl:destroy'
}), __metadata("design:type", Function), __metadata("design:paramtypes", []), __metadata("design:returntype", void 0)], DcuplApi.prototype, "destroy", null);
__decorate([trigger({
  scope: 'global',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Object)], DcuplApi.prototype, "getItem", null);
__decorate([trigger({
  scope: 'global',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], DcuplApi.prototype, "_getManyItems", null);
__decorate([trigger({
  scope: 'global',
  cloneResult: true
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object]), __metadata("design:returntype", Array)], DcuplApi.prototype, "executeQuery", null);
__decorate([trigger({
  scope: 'global',
  name: 'dcupl:loaders:add'
}), __metadata("design:type", Function), __metadata("design:paramtypes", [Object, String]), __metadata("design:returntype", void 0)], DcuplApi.prototype, "addLoader", null);
__decorate([trigger({
  scope: 'global',
  name: 'dcupl:loaders:remove'
}), __metadata("design:type", Function), __metadata("design:paramtypes", [String]), __metadata("design:returntype", void 0)], DcuplApi.prototype, "removeLoader", null);
