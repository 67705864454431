import { Injectable } from '@angular/core';
import { NetUploadFile } from '@net-types';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private uploads$$ = new BehaviorSubject<NetUploadFile[]>([]);
  constructor() {}

  public setUploads(file: NetUploadFile): void {
    if (file) {
      const uploads = this.uploads$$.getValue();
      uploads.push(file);
      this.uploads$$.next(uploads);
    }
  }

  public removeUpload(key: string): void {
    const uploads = this.uploads$$.getValue();

    const uploadIndex = uploads.findIndex((upload) => upload.key === key);
    // remove upload with key
    uploads.splice(uploadIndex, 1);

    this.uploads$$.next(uploads);
  }

  get uploads$(): Observable<NetUploadFile[]> {
    return this.uploads$$.asObservable();
  }
}
