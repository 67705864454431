import { get } from 'lodash-es';
export const evaluateTemplate = (template, variables) => {
  if (!template) {
    return '';
  }
  if (!variables) {
    variables = {};
  }
  return template.replace(/\$\{(.*?)\}/g, (_, varName) => {
    return get(variables, varName.trim(), '');
  });
};
export const parseTemplate = templateString => {
  const variableNames = new Set();
  templateString.replace(/\$\{(.*?)\}/g, (_, varName) => {
    variableNames.add(varName.trim());
    return '';
  });
  const variablesArray = Array.from(variableNames).map(name => ({
    name
  }));
  return {
    variables: variablesArray
  };
};
