import { isDate } from 'date-fns';
// returns a list of properties based on the input data
export const getPropertiesForData = (data, deep) => {
  const properties = [];
  for (const item of data) {
    for (const [key, value] of Object.entries(item)) {
      const property = properties.find(p => p.key === key);
      const type = getDcuplPropertyTypeForNativeType(value);
      if (property) {
        if (property.type !== type) {
          property.type = 'any';
        }
      } else {
        const prop = {
          key: key,
          type: type
        };
        properties.push(prop);
      }
    }
    if (!deep) {
      break;
    }
  }
  return properties;
};
export const isInt = n => {
  return Number(n) === n && n % 1 === 0;
};
export const isFloat = n => {
  return Number(n) === n && n % 1 !== 0;
};
export const getDcuplPropertyTypeForNativeType = value => {
  const type = typeof value;
  switch (type) {
    case 'string':
      return 'string';
    case 'number':
      if (isInt(value)) {
        return 'int';
      } else if (isFloat(value)) {
        return 'float';
      } else {
        return 'any';
      }
    case 'boolean':
      return 'boolean';
    case 'object':
      return isDate(value) ? 'date' : 'json';
    default:
      return 'any';
  }
};
